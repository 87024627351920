import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography } from "@mui/material";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import ProductChips from "components/ProductChips";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DialogWidget from "./DialogWidget";
import DisclaimerWidget from "./DisclaimerWidget";
import FlexBetween from "components/FlexBetween";
import { ReactComponent as IconPlus } from "./../../assets/plusHealth.svg";
import HealthConditionsWidget from "./HealthConditionsWidget";
import { useGetProfileQuery } from "app/services/auth/authService";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import HealthImpactsDetailsPage from "scenes/bitewise/healthImpactsDetailsPage";

const HealthConditionPDPWidget = ({ data }) => {
  const { data: profile } = useGetProfileQuery();
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { openHealth, openDisclaimer, openHealthImpact } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );
  const [payload, setPayload] = useState({
    name: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };
  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };
  const [valueDetailHealthImpact, setValueDetailHealthImpact] = useState();

  const handleClick = (data) => {
    setValueDetailHealthImpact(data);
  };

  return (
    <Box>
      <FlexBetweenVertical
        gap="0.5rem"
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {!(profile?.health_conditions?.length && !data.length) && (
          <>
            <Typography
              variant="h20"
              fontWeight="900"
              color={palette.textColor.greyLight}
            >
              {t("productDetail.health_condition_title")}
            </Typography>
            <Typography
              variant="h11"
              fontFamily={"default"}
              fontWeight="400"
              color={palette.textColor.greyLight}
            >
              {profile?.health_conditions?.length > 0
                ? t("productDetail.health_condition_sub")
                : t("productDetail.health_condition_impacts_sub")}

              {profile?.health_conditions?.length > 0 && (
                <IconButton
                  sx={{
                    display: "inline",
                    marginLeft: "0.3rem",
                    fontSize: "10px",
                    padding: "0",
                  }}
                  onClick={() => {
                    handleClickOpen("openDisclaimer");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    color="#7D8899"
                  >
                    <g clip-path="url(#clip0_3828_964)">
                      <path
                        d="M5 4.5C5.27614 4.5 5.5 4.72386 5.5 5L5.5 7C5.5 7.27614 5.27614 7.5 5 7.5C4.72386 7.5 4.5 7.27614 4.5 7L4.5 5C4.5 4.72386 4.72386 4.5 5 4.5Z"
                        fill="#7D8899"
                      />
                      <path
                        d="M5 3.75C5.34518 3.75 5.625 3.47018 5.625 3.125C5.625 2.77982 5.34518 2.5 5 2.5C4.65482 2.5 4.375 2.77982 4.375 3.125C4.375 3.47018 4.65482 3.75 5 3.75Z"
                        fill="#7D8899"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1Z"
                        fill="#7D8899"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3828_964">
                        <rect width="10" height="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </IconButton>
              )}
            </Typography>
          </>
        )}
        {!(profile?.health_conditions?.length > 0) ? (
          <EmptyViewHealthConditions
            t={t}
            palette={palette}
            handleOpen={() => handleClickOpen("openHealth")}
          />
        ) : (
          data && (
            <ProductChips
              chipsList={data.map((item) => ({
                ...item,
                type: "health_condition",
              }))}
              productDetailPage={true}
              handleClickOpen={() => handleClickOpen("openHealthImpact")}
              handleClick={handleClick}
              isHover={false}
            />
          )
        )}
      </FlexBetweenVertical>
      <DialogWidget
        open={openDisclaimer}
        onClose={() => handleClose("openDisclaimer")}
        dialogContent={
          <DisclaimerWidget onClose={() => handleClose("openDisclaimer")} />
        }
      />
      <DialogWidget
        open={openHealth}
        onClose={() => handleClose("openHealth")}
        dialogContent={
          <HealthConditionsWidget onClose={() => handleClose("openHealth")} />
        }
      />
      <DialogWidget
        open={openHealthImpact}
        onClose={() => handleClose("openHealthImpact")}
        dialogContent={
          <HealthImpactsDetailsPage
            healthImpactsDetail={[valueDetailHealthImpact]}
            onClose={() => handleClose("openHealthImpact")}
          />
        }
      />
    </Box>
  );
};

export default HealthConditionPDPWidget;

export function EmptyViewHealthConditions({ t, palette, handleOpen }) {
  return (
    <FlexBetween
      width="100%"
      gap="0.5rem"
      sx={{
        border: 1,
        borderColor: palette.borderColor.grey,
        borderRadius: "0.5rem",
        borderStyle: "dashed",
        padding: "4px 8px",
        height: "32px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        handleOpen();
      }}
    >
      <IconButton>
        <IconPlus />
      </IconButton>
      <Typography
        variant="h13"
        color={palette.textColor.greyLight}
        fontWeight="600"
        lineHeight={"1rem"}
      >
        {t("productDetail.no_set_health_condition")}
      </Typography>
    </FlexBetween>
  );
}
