import { ReactComponent as RatingGood } from "../assets/rating_good.svg";
import { ReactComponent as RatingMeh } from "../assets/rating_meh.svg";
import { ReactComponent as RatingBad } from "../assets/rating_bad.svg";
import { ReactComponent as RatingAvoid } from "../assets/avoid.svg";
import { ReactComponent as RatingBestAvoid } from "../assets/rating_bestavoid.svg";
import { ReactComponent as HealthRiskIcon } from "../assets/healthRiskIcon.svg";
import RatingGoodImage from "../assets/rating_good.svg";
import RatingMehImage from "../assets/rating_meh.svg";
import RatingBadImage from "../assets/rating_bad.svg";
import RatingAvoidImage from "../assets/avoid.svg";
import RatingBestAvoidImage from "../assets/rating_bestavoid.svg";
// import HealthRiskIconImage from "../assets/healthRiskIcon.svg";
import ColdDrinksAndJuicesImage from "../assets/ColdDrinksAndJuices.png";
import InstantAndFrozenFood from "../assets/InstantandFrozenFood.png";
import CerealsAndBreakfast from "../assets/CerealsAndBreakfast.png";
import SaucesAndSpreads from "../assets/SaucesAndSpreads.png";
import DairyBreadAndEggs from "../assets/DairyBreadAndEggs.png";
import BiscuitsAndCakes from "../assets/BiscuitsAndCakes.png";
import Munchies from "../assets/munchies_new.png";
import SweetTooth from "../assets/sweet_tooth.png";
import ViewMore from "../assets/view_more.svg";
import HealthRiskIconImage from "../assets/healthRiskIcon.svg";
import InfoIcon from "@mui/icons-material/Info";
import isEmpty from "lodash/isEmpty";

export function getImageCategory(id) {
  switch (id) {
    case 12:
      return ColdDrinksAndJuicesImage;
    case 13:
      return InstantAndFrozenFood;
    case 17:
      return CerealsAndBreakfast;
    case 19:
      return SaucesAndSpreads;
    case 20:
      return DairyBreadAndEggs;
    case 22:
      return BiscuitsAndCakes;
    case 25:
      return Munchies;
    case 26:
      return SweetTooth;
    default:
      return ViewMore;
  }
}

export function getNutrientRatingColor(rating_code, palette) {
  switch (rating_code) {
    case "GREEN":
      return palette.textColor.ratingGood;
    case "LIME_GREEN":
      return palette.textColor.ratingBest;
    case "ORANGE":
      return palette.textColor.ratingCaution;
    case "RED":
      return palette.textColor.ratingBadLight;
    case "GOOD":
      return palette.textColor.ratingGood;
    case "NEUTRAL":
      return palette.textColor.ratingGood;
    case "CAUTION":
      return palette.textColor.ratingCaution;
    case "HIGH_RISK":
      return palette.textColor.ratingBadLight;
    case "ALLERGIC":
      return palette.textColor.ratingBadLight;
    case "NO_RISK":
      return palette.textColor.ratingBest;
    default:
      return "#000000";
  }
}
export function getBorderColorAdditive(rating, palette) {
  if (rating === "NEU") {
    return palette.borderColor.darkGreen;
  } else if (rating === "CAUT") {
    return palette.borderColor.yellow;
  } else if (rating === "RISK") {
    return palette.borderColor.red;
  } else {
    return palette.borderColor.darkGreen;
  }
}
export function getBorderColorNutrient(rating_code, palette) {
  switch (rating_code) {
    case "GREEN":
      return palette.textColor.ratingBest;
    case "LIME_GREEN":
      return palette.textColor.ratingGood;
    case "ORANGE":
      return palette.borderColor.yellow;
    case "RED":
      return palette.borderColor.red;
    case "GOOD":
      return palette.borderColor.green;
    case "NEUTRAL":
      return palette.borderColor.green;
    case "CAUTION":
      return palette.borderColor.yellow;
    case "HIGH_RISK":
      return palette.borderColor.red;
    case "ALLERGIC":
      return palette.borderColor.red;
    case "NO_RISK":
      return palette.textColor.ratingBest;
    default:
      return "#EBEBF0";
  }
}

export function getNutrientLineChartRatingColor2(rating_code, palette) {
  switch (rating_code) {
    case "GREEN":
      return palette.textColor.ratingBest;
    case "LIME_GREEN":
      return palette.textColor.ratingGood;
    case "ORANGE":
      return palette.textColor.ratingCaution;
    case "RED":
      return palette.textColor.ratingBadLight;
    default:
      return "#000000";
  }
}
export function getNutrientLineChartRatingColor(rating_code, palette) {
  switch (rating_code) {
    case "GREEN":
      return palette.textColor.ratingGood;
    case "LIME_GREEN":
      return palette.textColor.ratingBest;
    case "ORANGE":
      return palette.textColor.ratingCaution;
    case "RED":
      return palette.textColor.ratingBadLight;
    default:
      return "#000000";
  }
}

export function getRatingBackgroundColor(rating, palette) {
  switch (rating) {
    case "GOOD":
      return palette.background.ratingGood;
    case "NEUTRAL":
      return palette.background.ratingGood;
    case "MEH":
      return palette.background.ratingMeh;
    case "BAD":
      return palette.background.ratingBad;
    case "AVOID":
      return palette.background.ratingBad;
    case "ESPECIALLY_BAD":
      return palette.background.ratingBad;
    case "BEST_AVOID":
      return palette.background.ratingBad;
    case "NOT_RATED":
      return palette.background.ratingNotRated;
    case "CAUTION":
      return palette.background.ratingCaution;
    case "HIGH_RISK":
      return palette.background.ratingBad;
    default:
      return "#ffffff";
  }
}
export function getRatingBackgroundColorBiteboard(rating, palette) {
  switch (rating) {
    case "GOOD":
      return "#DCFCE7";
    case "MEH":
      return "#FEF9C3";
    case "BAD":
      return "#FEE2E2";
    default:
      return "#ffffff";
  }
}

export function getBackgroundColorBiteboardItem(rating) {
  switch (rating) {
    case "GOOD":
      return "linear-gradient(95.16deg, #A0D911 0%, #D3F261 100.38%)";
    case "MEH":
      return "#FF8080";
    case "BAD":
      return "linear-gradient(95.16deg, #A0D911 0%, #D3F261 100.38%)";
    default:
      return "#ffffff";
  }
}

export function isOneOfBadRatingOrNotRatedProduct(rating) {
  return (
    "NOT_RATED" === rating ||
    "BAD" === rating ||
    "AVOID" === rating ||
    "ESPECIALLY_BAD" === rating
  );
}

export function getRatingChipBorderColor(rating, palette) {
  if (!isEmpty(rating)) {
    return "#FF424E";
  } else {
    return palette.borderColor.chip;
  }
}
export function getChipBorderColor(rating, palette, type = "") {
  if (!isEmpty(rating) && !type) {
    return "#FF424E";
  } else if (type === "health_condition") {
    return palette.borderColor.red;
  } else {
    return "#EBEBF0";
  }
}
export function getChipEdgeColor(
  rating,
  palette,
  type = "",
  scales = [],
  color = ""
) {
  if (!type) {
    return "transparent";
  } else {
    if (type === "additive") {
      return getBorderColorAdditive(color, palette);
    }
    if (type === "health_condition") {
      return "#FF424E";
    }
    return scales && scales.length > 2
      ? getBorderColorNutrient(rating, palette)
      : getNutrientRatingColor(rating, palette);
  }
}

export function getRatingChipFontWeight(rating, type = "") {
  if (!isEmpty(rating) && !type) {
    return 600;
  } else if (type === "health_condition") {
    return 600;
  } else {
    return 400;
  }
}
export function getRatingChipTextColor(rating, palette, type = "") {
  if (!isEmpty(rating) && !type) {
    return "#FF424E";
  } else if (type === "health_condition") {
    return "#FF424E";
  } else {
    return palette.textColor.mainSecondary;
  }
}

export function getRatingTextColor(rating, palette) {
  switch (rating) {
    case "GOOD":
      return palette.textColor.rateGoodNew;
    case "NEUTRAL":
      return palette.textColor.ratingGood;
    case "MEH":
      return palette.textColor.ratingMeh;
    case "BAD":
      return palette.textColor.ratingBad;
    case "AVOID":
      return palette.textColor.ratingAvoid;
    case "ESPECIALLY_BAD":
      return palette.textColor.ratingBestAvoid;
    case "BEST_AVOID":
      return palette.textColor.ratingBestAvoid;
    case "NOT_RATED":
      return palette.textColor.ratingNotRated;
    default:
      return "#3D3C3D";
  }
}
export function getRatingTextColorBiteboard(rating, palette) {
  switch (rating) {
    case "GOOD":
      return "#16A34A";
    case "MEH":
      return "#CA8A04";
    case "BAD":
      return "#DC2626";
    default:
      return "#3D3C3D";
  }
}

export function getTextColorChip(rating, palette, type = "") {
  if (type) {
    return "#3D3C3D";
  }
  return getRatingChipTextColor(rating, palette);
}

export function getRatingText(rating, t) {
  switch (rating) {
    case "GOOD":
      return t("common.rating_good");
    case "NEUTRAL":
      return t("common.rating_neutral");
    case "MEH":
      return t("common.rating_meh");
    case "BAD":
      return t("common.rating_bad");
    case "AVOID":
      return t("common.rating_avoid");
    case "ESPECIALLY_BAD":
      return t("common.rating_bestavoid");
    case "BEST_AVOID":
      return t("common.rating_bestavoid");
    case "NOT_RATED":
      return t("common.rating_notrated");
    default:
      return "";
  }
}

export function getRatingImage(rating, size = "1.25rem") {
  switch (rating) {
    case "GOOD":
      return <RatingGood style={{ width: size, height: size }} />;
    case "NEUTRAL":
      return <RatingGood style={{ width: size, height: size }} />;
    case "MEH":
      return <RatingMeh style={{ width: size, height: size }} />;
    case "BAD":
      return <RatingBad style={{ width: size, height: size }} />;
    case "AVOID":
      return <RatingAvoid style={{ width: size, height: size }} />;
    case "ESPECIALLY_BAD":
      return <RatingBestAvoid style={{ width: size, height: size }} />;
    case "BEST_AVOID":
      return <RatingBestAvoid style={{ width: size, height: size }} />;
    case "NOT_RATED":
      return <></>;
    default:
      return <></>;
  }
}

export function getRatingImage2(rating, size = "1.25rem") {
  const styles = { width: size, height: size };

  switch (rating) {
    case "GOOD":
      return <img src={RatingGoodImage} alt="Good Rating" style={styles} />;
    case "NEUTRAL":
      return <img src={RatingGoodImage} alt="Neutral Rating" style={styles} />;
    case "MEH":
      return <img src={RatingMehImage} alt="Meh Rating" style={styles} />;
    case "BAD":
      return <img src={RatingBadImage} alt="Bad Rating" style={styles} />;
    case "AVOID":
      return <img src={RatingAvoidImage} alt="Avoid Rating" style={styles} />;
    case "ESPECIALLY_BAD":
      return (
        <img
          src={RatingBestAvoidImage}
          alt="Best Avoid Rating"
          style={styles}
        />
      );
    case "BEST_AVOID":
      return (
        <img
          src={RatingBestAvoidImage}
          alt="Best Avoid Rating"
          style={styles}
        />
      );
    case "NOT_RATED":
      return <></>;
    default:
      return <></>;
  }
}

export function getRatingImageForChip(
  rating,
  size,
  productDetailPage,
  type = ""
) {
  if (type === "health_condition") {
    return <img src={HealthRiskIconImage} alt="danger-icon" />;
  }
  // console.log("jahajhaj: ", productDetailPage);
  switch (rating) {
    case "GOOD":
      return (
        <RatingGood
          style={{ width: size, height: size, minWidth: size, minHeight: size }}
        />
      );
    case "MEH":
      return (
        <RatingMeh
          style={{ width: size, height: size, minWidth: size, minHeight: size }}
        />
      );
    case "BAD":
      return (
        <RatingBad
          style={{ width: size, height: size, minWidth: size, minHeight: size }}
        />
      );
    case "AVOID":
      return (
        <RatingAvoid
          style={{ width: size, height: size, minWidth: size, minHeight: size }}
        />
      );
    case "ESPECIALLY_BAD":
      return (
        <RatingBestAvoid
          style={{ width: size, height: size, minWidth: size, minHeight: size }}
        />
      );
    case "BEST_AVOID":
      // return (<RatingBestAvoid style ={{ width:size, height:size, minWidth: size, minHeight: size }}/>);
      return <HealthRiskIcon />;
    case "NOT_RATED":
      return null;
    default:
      return productDetailPage ? (
        <InfoIcon
          style={{
            color: "#C4C4CF",
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
          }}
        />
      ) : null;
  }
}

export function getIngredientRatingText(rating_code, t) {
  switch (rating_code) {
    case "GOOD":
      return t("common.rating_good");
    case "NEUTRAL":
      return t("common.rating_neutral");
    case "MEH":
      return t("common.rating_meh");
    case "BAD":
      return t("common.rating_bad");
    case "AVOID":
      return t("common.rating_avoid");
    case "ESPECIALLY_BAD":
      return t("common.rating_bestavoid");
    case "BEST_AVOID":
      return t("common.rating_bestavoid");
    case "NOT_RATED":
      return t("common.rating_notrated");
    case "CAUTION":
      return t("common.rating_caution");
    case "HIGH_RISK":
      return t("common.rating_highrisk");
    case "ALLERGIC":
      return t("common.rating_allergic");
    default:
      return rating_code;
  }
}

export function debugLog(msg) {
  if ("production" !== process.env.NODE_ENV) console.log(msg);
}

export function filterRiskItems(data, healthConditions) {
  let result = [];
  if (data?.length && healthConditions?.length) {
    for (let item of data) {
      if (
        healthConditions.findIndex(
          (condition) => condition.name === item.risk_item.name
        ) !== -1
      ) {
        result.push(item);
      }
    }
  }
  return result;
}

export const getTagName = (nutrient) => {
  if (nutrient.name === "Fruits, vegetables, and nuts") {
    return nutrient.tag_name + " " + "FVN";
  }
  if (nutrient.name === "Energy") {
    return nutrient.tag_name + " " + "Calories";
  }
  if (nutrient.name === "Saturated fat") {
    return nutrient.tag_name + " " + "Fat";
  }
  const tagName =
    (nutrient.tag_name ? nutrient.tag_name : "") +
    " " +
    (nutrient.name ? nutrient.name : "");

  return tagName;
};

export const rateCodeOrderBadMeh = [
  "RISK",
  "RED",
  "ORANGE",
  "CAUT",
  "GREEN",
  "NEU",
  "LIME_GREEN",
];

const getRatingIndex = (rating, tagsPriority) => {
  const tagsUppercase = tagsPriority.map((ele) => ele?.toUpperCase());
  return tagsUppercase.indexOf(rating);
};

const mapAndSortNutrients = (nutrients, tagsPriority) => {
  return nutrients
    .map((nutrient) => ({
      name: getTagName(nutrient),
      rating: nutrient.rate_code,
      type: "nutrient",
      icon: nutrient.icon,
      scales: nutrient.scales || [],
    }))
    .sort(
      (a, b) =>
        getRatingIndex(a.rating, tagsPriority) -
        getRatingIndex(b.rating, tagsPriority)
    );
};

export const createNutriTag = (productItem) => {
  const nutrients = productItem?.nutrients?.length
    ? productItem.nutrients
    : productItem?.product_nutrients || [];
  return nutrients.length
    ? mapAndSortNutrients(nutrients, productItem?.tags_priority)
    : [];
};

const getNameAndRatingAdditive = (lengthHighRisk, lengthCaution) => {
  if (lengthHighRisk > 0) {
    return {
      rating: "RISK",
    };
  } else if (lengthCaution > 0) {
    return {
      rating: "CAUT",
    };
  } else {
    return {
      rating: "NEU",
    };
  }
};

export const createChipListData = (productItem, nutriTag) => {
  const ingredients =
    productItem?.ingredients || productItem?.product_ingredients || [];

  const additiveAvoid = ingredients.filter(
    (item) => item.type === "NEGATIVE" && !item.allergic
  );
  const lengthHighRisk = additiveAvoid.filter(
    (item) => item.rate_code === "HIGH_RISK"
  ).length;
  const lengthCaution = additiveAvoid.filter(
    (item) => item.rate_code === "CAUTION"
  ).length;

  const nameRatingAdditive = getNameAndRatingAdditive(
    lengthHighRisk,
    lengthCaution
  );

  const additiveTag = {
    name: productItem?.additive_tag,
    type: "additive",
    rating: "ADDITIVE",
    color: nameRatingAdditive?.rating,
  };

  const healthConditions =
    productItem?.health_conditions?.map((condition) => ({
      ...condition,
      type: "health_condition",
    })) || [];

  let resultData = [];

  if (healthConditions.length) {
    resultData = [...healthConditions];
  }
  if (additiveTag.name) {
    resultData = [...resultData, additiveTag];
  }
  if (nutriTag.length) {
    resultData = [...resultData, ...nutriTag];
  }

  resultData.sort(
    (a, b) =>
      getRatingIndex(a.rating, productItem?.tags_priority) -
      getRatingIndex(b.rating, productItem?.tags_priority)
  );

  return resultData.slice(0, 4);
};

export function getOrderCategory() {
  return [25, 26, 22, 12, 17, 20, 19, 13, -1];
}

export function sortDataByCategory(data) {
  // Get the order category
  const orderCategory = getOrderCategory();

  // Create a mapping of order category values to their indices
  const orderMap = new Map(orderCategory.map((value, index) => [value, index]));

  // Create a copy of the data array to avoid modifying the original array
  const dataCopy = [...data];

  // Sort the copy of the data array based on the custom order
  dataCopy.sort((a, b) => {
    const orderA = orderMap.has(a.id) ? orderMap.get(a.id) : Infinity;
    const orderB = orderMap.has(b.id) ? orderMap.get(b.id) : Infinity;
    return orderA - orderB;
  });

  return dataCopy;
}
