import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Chip, Avatar, Box } from "@mui/material";
import FlexBetween from "./FlexBetween";

const AlternativeProductsList = ({ alternativeImageList, size = "24px" }) => {
  //   const [chipData, setChipData] = useState<readonly ChipData[]>([
  //     { key: 0, label: 'Angular' },
  //     { key: 1, label: 'jQuery' },
  //     { key: 2, label: 'Polymer' },
  //     { key: 3, label: 'React' },
  //     { key: 4, label: 'Vue.js' },
  //   ]);

  if (!alternativeImageList) {
    return null;
  }

  return (
    <FlexBetween gap="0.25rem">
      {alternativeImageList.map((data, index) => {
        return (
            <img
              src={data}
              key={index}
              style={{
                width: size,
                height: size,
                objectFit: "cover",
                borderRadius: "0.75px",
              }}
            />
        );
      })}
    </FlexBetween>
  );
};

export default AlternativeProductsList;
