import { Box } from "@mui/material";
import QuestionInBoxChat from "components/QuestionInBox";
import React from "react";
import AnswerGPTMarkdown from "./AnswerGPTMarkdown";
import ErrorWiseGPT from "./ErrorWiseGPT";

const QuestionAndAnswer = ({
  listQuestionsAndAnswer,
  isLoading = false,
  isAsking = false,
  error = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop : "24px"
      }}
    >
      {listQuestionsAndAnswer.map((queAns, index) => (
        <React.Fragment key={index}>
          <QuestionInBoxChat question={queAns?.question} />
          {(isLoading | queAns?.answer?.length) ? (
            <AnswerGPTMarkdown
              answer={queAns?.answer}
              isLoading={isLoading}
              isAsking={isAsking}
            />
          ) : <></>}
          {error && <ErrorWiseGPT />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default QuestionAndAnswer;
