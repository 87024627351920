import { Avatar, Box } from "@mui/material";

const ProductImageProductList = ({ imageUrl, size = "80px" }) => {
  return (
      <img
        src={imageUrl}
        alt="Image not found"
        width={size}
        height={size}
        style={{
          objectFit: "cover",
          borderRadius: "0.75rem",
          
        }}
      />
  );
};

export default ProductImageProductList;
