import React from "react";
import { useTheme } from "@emotion/react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import { useMediaQuery } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWidget = (props) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const widthBox = isNonMobileScreens ? "433px" : "100vw";
  const { palette } = useTheme();
  const backgroundColorWhite = palette.background.alt;
  const styleBox = {
    position: "absolute",
    width: "100vw",
    maxWidth: widthBox,
    transform: "translateX(-50%)",
    left: "50%",
    bottom: 0,
    zIndex: 10,
    background: backgroundColorWhite,
    boxShadow: "0px -4px 1rem rgba(0, 0, 0, 0.12)",
    borderRadius: "2rem 2rem 0px 0px",
    margin: 0,
    
  };

  const { onClose, open, dialogContent } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: styleBox,
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "0",
        }}
      >
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};

export default DialogWidget;
