import {
  Box,
  Dialog,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState, useRef, forwardRef } from "react";
import { ReactComponent as ScanIcon } from "../../../assets/scan_boundary_icon.svg";
import { ReactComponent as CameraDeniedIcon } from "../../../assets/camera_disabled.svg";

import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import GenericNavBar from "../navbar/GenericNavBar";
import "./VideoDecode.css";

import FlexBetweenVertical from "components/FlexBetweenVertical";
import { Helmet } from "react-helmet";
import {
  useGetScanBarcodeMutMutation,
  useGetScanBarcodeQuery,
} from "app/services/auth/authService";
import NavBarDesktop from "../navbar/NavBarDesktop";

import { configure, BarcodePicker, Barcode, ScanSettings } from "scandit-sdk";
import EmptyScanResultsWidget from "scenes/widgets/EmptyScanResultsWidget";
import { SCANDIT_LICENSE_KEY } from "utils/constants";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ErrorAccessWidget from "scenes/widgets/ErrorAccessWidget";
import PopUpBiteboard from "scenes/widgets/PopUpBiteboard";
import { debugLog } from "utils/commonutils";
import { useSelector } from "react-redux";

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const configurationPromise = configure(SCANDIT_LICENSE_KEY, {
  engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk/build",
}).catch((error) => {
  // alert(error);
  console.log("Error permision", error);
});

export const Transition = forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const productItem = {};

function sendEvent(
  eventName: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  eventValue: string
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    },
  });
}

const ScanditScanPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [showProductWidget, setShowProductWidget] = useState(false);
  const [showErrorAccess, setShowErrorAccess] = useState(false);
  const [showEmptyWidget, setShowEmptyWidget] = useState(false);
  const [data, setData] = useState({});
  const [isScan, setIsScan] = useState(false);
  const pScanner = useRef(null as null | Promise<BarcodePicker>);
  const elRef = useRef<HTMLDivElement>(null);
  const barcodePickerRef = useRef(null as null | BarcodePicker);

  const [getBarcodeDetailsReference] = useGetScanBarcodeMutMutation();
  const [popupRenderCount, setPopupRenderCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState("prompt");
  const { userIPLocation } = useSelector((state: any) => state.auth);

  const fetchBarcodeDetails = async (barcode_text: string) => {
    getBarcodeDetailsReference({
      barcode: barcode_text,
    })
      .unwrap()
      .then((data) => {
        setIsScan(true);
        debugLog("Scanditdata: " + data);
        setData(data);
        setShowProductWidget(true);
        sendEvent(
          "searchScan",
          "scan",
          "scanResult",
          "found",
          barcode_text + "_" + data.id
        );
        if (barcodePickerRef.current) barcodePickerRef.current.pauseScanning();
      })
      .catch((error) => {
        console.log("Scandit Error: " + error);
        if ("status" in error) {
          switch (error.status) {
            case 404:
              debugLog("ArsyScanError: 404");
              setIsScan(true);
              setShowEmptyWidget(true);
              sendEvent(
                "searchScan",
                "scan",
                "scanResult",
                "empty",
                barcode_text
              );
              if (barcodePickerRef.current)
                barcodePickerRef.current.pauseScanning();
              break;
            default:
              break;
            // default error handling
          }
        }
      });
  };

  const handleClickCloseBottomSheet = () => {
    if (barcodePickerRef.current) barcodePickerRef.current.resumeScanning();
    setIsScan(false);
  };
  // https://github.com/Scandit/barcodescanner-sdk-for-web-samples/blob/master/vanilla-samples/simple/index.html#L83
  // https://support.scandit.com/hc/en-us/articles/4417247597970-How-to-optimize-scanning-workflow-depending-on-the-use-case-
  // https://docs.scandit.com/4.1/web/classes/barcodepicker.html#isvisible

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "scan",
    });
  }, []);

  const checkCameraPermission = async () => {
    try {
      debugLog("checkCameraPermission permissionStatus: " + permissionStatus);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      setPermissionStatus("granted");
    } catch (error: any) {
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        setPermissionStatus("denied");
      } else {
        setPermissionStatus("error");
        console.error("Error accessing camera:", error);
      }
    }
  };

  const checkCameraAvailability = async () => {
    try {
      const cameras = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = cameras.some((device) => device.kind === "videoinput");
      if (hasCamera) {
        // Camera available, check permission
        checkCameraPermission();
      } else {
        // No camera available
        setPermissionStatus("not_available");
      }
    } catch (error) {
      console.error("Error checking camera availability:", error);
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if(permissionStatus != "granted")
  //       checkCameraAvailability();
  //   }, 2000); // Check every 2 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   if(!isNonMobileScreens)
  //     checkCameraPermission(); // Check camera availability initially
  // }, []);

  // useEffect(() => {
  //   console.log("useEffect permissionStatus : "+ permissionStatus);
  // }, [permissionStatus])

  useEffect(() => {
    debugLog("Arsy....");
    try {
      if (isNonMobileScreens == false) {
        configurationPromise
          .then(() => {
            try {
              // console.log("elref: " + elRef.current);
              if (!pScanner.current) {
                console.log("Init pscanner....");
                pScanner.current = BarcodePicker.create(
                  document.getElementById("barcode-picker")!,
                  {
                    playSoundOnScan: true,
                    vibrateOnScan: true,
                    guiStyle: BarcodePicker.GuiStyle.NONE,
                    enableTorchToggle: false,
                    enableCameraSwitcher: false,
                    visible: true,
                  }
                );
                pScanner.current &&
                  pScanner.current
                    .then((barcodePicker) => {
                      barcodePickerRef.current = barcodePicker;
                      const scanSettings = new ScanSettings({
                        enabledSymbologies: [
                          Barcode.Symbology.EAN8,
                          Barcode.Symbology.EAN13,
                          Barcode.Symbology.UPCA,
                          Barcode.Symbology.UPCE,
                          Barcode.Symbology.CODE128,
                          Barcode.Symbology.CODE39,
                          Barcode.Symbology.CODE93,
                          Barcode.Symbology.CODE25,
                          Barcode.Symbology.CODE11,
                          Barcode.Symbology.CODE32,
                          Barcode.Symbology.FIVE_DIGIT_ADD_ON,
                          Barcode.Symbology.TWO_DIGIT_ADD_ON,
                        ],
                        codeDuplicateFilter: 1000, // Minimum delay between duplicate results
                        searchArea: {
                          x: 0.1,
                          y: 0.25,
                          width: 0.8,
                          height: 0.4,
                        },
                      });

                      barcodePicker.applyScanSettings(scanSettings);

                      barcodePicker.on("ready", function () {
                        // document.getElementById("lib-loading").hidden = true;
                        // document.getElementById("barcode-picker-starter-button").hidden = false;
                        barcodePicker.setVisible(true);
                      });

                      barcodePicker.on("scan", (scanResult) => {
                        const barcode_text = scanResult.barcodes.reduce(
                          (string, barcode) => {
                            // console.log("ArsyBarcoderead 11"+ string);
                            // console.log("ArsyBarcoderead 12"+ barcode.data);
                            // return string + `${Barcode.Symbology.toHumanizedName(barcode.symbology)}: ${barcode.data}\n`;
                            return string + barcode.data;
                          },
                          ""
                        );
                        debugLog("ArsyBarcoderead" + barcode_text);
                        // setBarcode("825634187956");
                        // setBarcode(barcode_text);
                        sendEvent(
                          "searchScan",
                          "scan",
                          "scanTrigerred",
                          "scanned",
                          barcode_text
                        );
                        fetchBarcodeDetails(barcode_text);
                      });
                      barcodePicker.on("scanError", (errorResult) => {
                        alert(errorResult);
                      });
                    })
                    .catch((error: any) => {
                      console.log("Scandit config error", error);
                      if (
                        error.name === "NotAllowedError" ||
                        error.name === "PermissionDeniedError"
                      ) {
                        setShowErrorAccess(true);
                      }
                    });
              }
            } catch (ex: any) {
              if (ex.message.indexOf("network connection error")) {
                let customMsg =
                  "Failed to connect to Scandit License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                // console.log(customMsg);
                alert(customMsg);
              }
              throw ex;
            }
          })
          .catch((error: any) => {
            console.log("Scandit config error", error);
          });
        return () => {
          (async () => {
            if (pScanner.current) {
              (await pScanner.current).destroy();
              pScanner.current = null;
              debugLog("BarcodeScanner Component Unmount");
            }
          })();
        };
      }
    } catch (error) {
      console.error("Error in scan feature:", error);
    }
  }, []);
  const navBar = isNonMobileScreens ? <NavBarDesktop /> : <GenericNavBar />;

  return (
    <>
      <Box height="100%">
        <Helmet title={`Bitewise`}></Helmet>
        {navBar}
        <FlexBetweenVertical width="100%" height="100%">
          <Box
            width="100%"
            display={isNonMobileScreens ? "flex" : "block"}
            gap="0.5rem"
            justifyContent="space-between"
            height="100%"
            // mt="1rem"
          >
            <Box flexBasis={isNonMobileScreens ? "26%" : undefined} />
            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
              height="100%"
            >
              {isNonMobileScreens ? (
                NotAvailable()
              ) : (
                <Box style={isNonMobileScreens ? { height: "100%" } : {}}>
                  {/* <svg className="dce-bg-loading" viewBox="0 0 1792 1792">
                                <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path>
                              </svg>
                              <svg className="dce-bg-camera" viewBox="0 0 3000 3000">
                                <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
                              </svg> */}

                  {/* {permissionStatus === "denied"
                  ?
                  <FlexBetweenVertical
                    gap="1rem"
                    padding="1rem"
                    mt="2rem"
                    sx={{ justifyContent: "center" }}
                  >
                    <CameraDeniedIcon style={{width:"64px", height:"64px"}}/>
                    <FlexBetweenVertical
                      gap="1.5rem"
                      pl="1rem"
                      pr="1rem"
                      sx={{ justifyContent: "center", alignItems: "flex-start" }}
                    >
                      <Typography
                        fontSize="16"
                        fontWeight="400"
                      >
                        {"Camera permission denied. Please grant access to use scan feature and reload this page."}
                      </Typography>
                    </FlexBetweenVertical>

                  </FlexBetweenVertical>
                  : */}
                  <Box>
                    <Box
                      ref={elRef}
                      id="barcode-picker"
                      className="dce-video-container"
                      style={{ zIndex: -1 }}
                    ></Box>
                    <Box
                      width="100%"
                      height="80%"
                      padding="2rem 6%"
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      justifyContent={"space-around"}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-around"}
                        gap={"5rem"}
                        flexDirection={"column"}
                        marginTop={"25%"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-around"}
                          gap={"5rem"}
                        >
                          <ScanIcon
                            style={{
                              transform: "rotate(-90deg)",
                            }}
                          ></ScanIcon>
                          <ScanIcon
                            style={{
                              transform: "rotate(0deg)",
                            }}
                          ></ScanIcon>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"space-around"}
                          gap={"5rem"}
                        >
                          <ScanIcon
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          ></ScanIcon>
                          <ScanIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          ></ScanIcon>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* } */}
                </Box>
              )}
            </Box>

            {isNonMobileScreens && (
              <Box flexBasis="26%">
                <Box m="1.5rem 0" />
              </Box>
            )}
          </Box>
        </FlexBetweenVertical>
      </Box>
      {showEmptyWidget && (
        <Dialog
          open={showEmptyWidget}
          onClose={() => {
            setShowEmptyWidget(false);
            handleClickCloseBottomSheet();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              bottom: "0rem",
              borderRadius: "1rem",
              padding: "1.25rem 1.5rem",
              margin: "1rem",
            },
          }}
        >
          <Box sx={{ padding: 0 }}>
            <EmptyScanResultsWidget
              onClose={() => {
                setShowEmptyWidget(false);
                handleClickCloseBottomSheet();
              }}
            />
          </Box>
        </Dialog>
      )}
      {showProductWidget && (
        <Dialog
          open={showProductWidget}
          onClose={() => {
            setShowProductWidget(false);
            handleClickCloseBottomSheet();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              bottom: "0rem",
              borderRadius: "1rem",
            },
          }}
        >
          <Box>
            {data && !showErrorAccess && (
              <ProductListItemWidget productItem={data}></ProductListItemWidget>
            )}
          </Box>
        </Dialog>
      )}
      {showErrorAccess && (
        <Dialog
          open={showErrorAccess}
          onClose={() => {
            setShowErrorAccess(false);
            setIsScan(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              bottom: "0rem",
              borderRadius: "1rem",
            },
          }}
        >
          <ErrorAccessWidget
            onClose={() => {
              setShowErrorAccess(false);
              setIsScan(false);
            }}
          />
        </Dialog>
      )}
      {!isNonMobileScreens &&
        !isScan &&
        popupRenderCount < 1 &&
        userIPLocation &&
        userIPLocation.ipapi &&
        userIPLocation.ipapi.countryName.toUpperCase() !== "VIETNAM" && (
          <>
            <PopUpBiteboard
              barcodePickerRef={barcodePickerRef}
              handleCount={() =>
                setPopupRenderCount((prevCount) => prevCount + 1)
              }
            />
          </>
        )}
    </>
  );
};

export default ScanditScanPage;

export function NotAvailable() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FlexBetweenVertical
        justifyItems={"center"}
        alignItems={"center"}
        gap={"2rem"}
        padding={"1rem"}
        width={"100%"}
      >
        <Typography fontSize={"4rem"}>😿</Typography>
        <FlexBetweenVertical gap={"0.75rem"}>
          <Typography fontSize={"1.25rem"} fontWeight={600} color={"#32363D"}>
            {t("scanPage.we_are_sorry")}
          </Typography>
          <Typography
            fontSize={"1rem"}
            fontWeight={700}
            color={"#7D8899"}
            fontFamily={"Fuzzy Bubbles"}
            textAlign={"center"}
          >
            {t(
              "scanPage.scan_feature_is_not_available_on_desktop_version_please_use_your_mobile"
            )}
          </Typography>
        </FlexBetweenVertical>
      </FlexBetweenVertical>
    </Box>
  );
}
