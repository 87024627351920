import {
  Box,
  Typography,
  useTheme,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import FlexBetween from "./FlexBetween";
import { useTranslation } from "react-i18next";
import {
  getRatingBackgroundColor,
  getRatingTextColor,
  getRatingImage,
  getRatingText,
} from "utils/commonutils";
import { ReactComponent as ExplanationIcon } from "../assets/icon_explanation.svg";
import DialogWidget from "scenes/widgets/DialogWidget";
import FaqWidgetBottom from "scenes/widgets/FaqWidgetBottom";
import { useEffect, useRef, useState } from "react";

const ProductDetailItemRating = ({
  rating,
  score = null,
  h = "36px",
  w = "147px",
  isProductDetail = false,
}) => {
  const { palette } = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:375px)");
  const isSupperSmallScreen = useMediaQuery("(max-width:330px)");
  const textMainSecondary = palette.textColor.mainSecondary;
  const [openFAQ, setOpenFAQ] = useState(false);
  const panelDefault = "panel7";
  const handleClose = () => {
    setOpenFAQ(false);
  };

  const { t } = useTranslation();
  rating = rating?.toUpperCase();
  const needResize =
    (isSmallScreen && isProductDetail && score < 40) || isSupperSmallScreen;

  return (
    <FlexBetween
      // height={h}
      minHeight={h}
      backgroundColor={getRatingBackgroundColor(rating, palette)}
      sx={{
        justifyContent: "flex-start",
        gap: "0.5rem",
        borderRadius: "0.75rem",
        pl: needResize ? "0.25rem" : "0.8rem",
        pr: needResize ? "0.25rem" : "0.8rem",
      }}
    >
      {getRatingImage(rating, needResize ? "1.1rem" : "1.25rem")}
      <Typography
        variant={needResize ? "h15" : "h17"}
        color={getRatingTextColor(rating, palette)}
        fontWeight="800"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {getRatingText(rating, t)}
      </Typography>

      {rating.toUpperCase() !== "NOT_RATED" && score >= 0 && (
        <FlexBetween
          sx={{
            justifyContent: "flex-start",
          }}
        >
          {score >= 40 && (
            <>
              <Typography
                variant={needResize ? "h11" : "h13"}
                color={textMainSecondary}
                fontWeight="900"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {score}
                {"/"}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                }}
                variant={needResize ? "h11" : "h13"}
                color={textMainSecondary}
                fontWeight="600"
              >
                {"100"}
              </Typography>
            </>
          )}
          {score < 40 && (
            <Typography
              variant={needResize ? "h11" : "h13"}
              color={textMainSecondary}
              fontWeight="900"
              sx={{
                whiteSpace: "nowrap",
                fontSize: needResize ? "11px" : "13px",
              }}
            >
              {t("productDetail.under_40")}
            </Typography>
          )}
        </FlexBetween>
      )}
      <IconButton
        sx={{
          padding: needResize ? "4px" : "8px",
        }}
        onClick={() => {
          setOpenFAQ((pre) => !pre);
        }}
      >
        <ExplanationIcon />
      </IconButton>
      <DialogWidget
        open={openFAQ}
        onClose={() => handleClose()}
        dialogContent={
          <FaqWidgetBottom
            onClose={() => handleClose()}
            panelDefault={panelDefault}
          />
        }
      />
    </FlexBetween>
  );
};

export default ProductDetailItemRating;
