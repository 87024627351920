import {
  Box,
  ButtonBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchNavbar from "./SearchNavbar";
import {
  useGetAllHistoryProductListQuery,
  useGetSearchSuggestionsQuery,
} from "app/services/auth/authService";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import SearchListItem from "./SearchListItem";
import { Helmet } from "react-helmet";
import SearchSuggest from "./SearchSuggest";
import { useDispatch, useSelector } from "react-redux";
import { saveHistoryToLocal, updateHistory } from "features/search/searchSlice";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { useTranslation } from "react-i18next";
import DiscoveryWidget from "scenes/widgets/DiscoveryWidget";

const BitewiseSearchPage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const backgrountWhite = palette.background.alt;
  const [searchValue, setSearchValue] = useState("");
  const { historyLocal, newUpdateHistory } = useSelector(
    (state) => state.search
  );
  const [page, setPage] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userIPLocation } = useSelector((state) => state.auth);

  const { data } = useGetSearchSuggestionsQuery(
    { searchValue: searchValue, page },
    {
      skip: searchValue.length < 3,
    }
  );
  const { data: dataHistoryFromApi } = useGetAllHistoryProductListQuery();

  const saveToLocal = (dataHistory = []) => {
    dispatch(saveHistoryToLocal(dataHistory));
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "search",
    });
  }, []);

  useEffect(() => {
    if (dataHistoryFromApi?.length > 0) {
      saveToLocal(dataHistoryFromApi);
    }
  }, [dataHistoryFromApi]);

  const [newSearchHistory, setNewSearchHistory] = useState("");

  useEffect(() => {
    const newProductSearch = localStorage.getItem("newProductSearch");
    if (newProductSearch) {
      setNewSearchHistory(JSON.parse(newProductSearch));
      localStorage.removeItem("newProductSearch");
    }
  }, [newUpdateHistory]);

  useEffect(() => {
    const historyFromLocal = localStorage.getItem("historyData");
    const maxCountHistory = 5;
    if (historyFromLocal) {
      let historyLocalJson = JSON.parse(historyFromLocal);
      let existProduct = historyLocalJson?.find(
        (value) => value.id === newSearchHistory.id
      );
      if (newSearchHistory && existProduct === undefined) {
        if (historyLocalJson.length === maxCountHistory) {
          historyLocalJson.pop();
        }
        const valueDispatch = [newSearchHistory, ...historyLocalJson];
        dispatch(updateHistory(valueDispatch));
      } else if (existProduct !== undefined) {
        let newValue = historyLocalJson.filter(
          (value) => value !== existProduct
        );
        const valueNew = [newSearchHistory, ...newValue];
        dispatch(updateHistory(valueNew));
      }
    } else {
      saveToLocal(dataHistoryFromApi);
    }
  }, [newSearchHistory, dispatch]);

  const onSearchInputChange = (searchText) => {
    setSearchValue(searchText);
    setPage(0);
  };
  const onEnterKeyDown = (searchText) => {
    setSearchValue(searchText);
    if (searchText.length >= 3) {
      let encodedSearchValue = encodeURIComponent(searchText);
      encodedSearchValue = encodedSearchValue
        .replace(/%E2%80%99/g, "%27")
        .replace(/%E2%80%98/g, "%27")
        .replace(/%E2%80%B2/g, "%27")
        .replace(/%20%25/g, "%27")
        .replace(/%25%20/g, "%27");
      navigate(`/search_detail/${encodedSearchValue}`);
      // Save the search text in local storage
      localStorage.setItem("searchQueryText", searchText);
    }
  };

  const isHistoric = historyLocal?.length > 0 ? true : false;

  // const debouncedRequest = useDebounce(() => {
  //     // send request to the backend
  //     // access to latest state here
  //     console.log("SearchPage"+searchValue);

  // });
  const isPositiveProduct = true;

  return (
    <Box>
      <Helmet title={"Search | Bitewise"}></Helmet>
      {isNonMobileScreens && <NavBarDesktop />}
      <SearchNavbar
        onSearchInputChange={onSearchInputChange}
        onEnterKeyDown={onEnterKeyDown}
      />
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined} />
        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          backgroundColor={backgrountWhite}
          minHeight="95vh"
          display="flex"
          flexDirection="column"
          width={isNonMobileScreens ? "30%" : undefined}
        >
          <FlexBetween pt="1.5rem">
            {data && data.length > 0 && searchValue.length >= 3 && (
              <SearchListItem searchData={data} searchValue={searchValue} />
            )}
          </FlexBetween>
          {historyLocal && searchValue.length < 3 && (
            <SearchListItem searchData={historyLocal} isHistoric={isHistoric} />
          )}

          {!isHistoric && <SearchSuggest />}
          {userIPLocation.ipapi.countryName.toUpperCase() !== "VIETNAM" && (
            <Box pl="1.5rem" mt="2rem">
              <DiscoveryWidget isHomePage={true} isSearchPage={true} />
            </Box>
          )}

          {/* <SearchResultTabsView ></SearchResultTabsView> */}
        </Box>

        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BitewiseSearchPage;
