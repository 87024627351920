import {
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import SearchResultTabsView from "scenes/bitewise/searchDetailsPage/SearchResultTabsView";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSearchIngredientCountQuery,
  useGetSearchProductCountQuery,
} from "app/services/auth/authService";
import { Helmet } from "react-helmet";
import GenericNavBar from "../navbar/GenericNavBar";
import NavBarDesktop from "../navbar/NavBarDesktop";
import FlexBetween from "components/FlexBetween";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";

const BitewiseSearchDetailsPage = () => {
  //   const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const backgrountSearchBar = palette.background.searchBar;
  const textAppBarTitle = palette.textColor.appBarTitle;

  const { searchValue } = useParams();
  // console.log("decodedSearchValue", searchValue);
  const { data: productCount } = useGetSearchProductCountQuery({ searchValue });
  const navigate = useNavigate();
  const { data: ingredientCount } = useGetSearchIngredientCountQuery({
    searchValue,
  });
  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar
      title={searchValue}
      appBarBackgroundColor={backgrountSearchBar}
    />
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "section_of_website" : "search_detail"
    });
  }, []);

  useEffect(() => {
    if (productCount) {
      // console.log(productCount);
      if (productCount.count == 0) {
        sendEvent("searchScan", "searchProduct", "searchResult", "empty", searchValue);
      }
      if (productCount.count > 0) {
        sendEvent("searchScan", "searchProduct", "searchResult", "found", searchValue);
      }
    }
    if (ingredientCount) {
      // console.log(ingredientCount);
      if (ingredientCount.count == 0) {
        sendEvent("searchScan", "searchIngredient", "searchResult", "empty", searchValue);
      }
      if (ingredientCount.count > 0) {
        sendEvent("searchScan", "searchIngredient", "searchResult", "found", searchValue);
      }
    }
    // console.log(isLoading)
  }, [productCount, ingredientCount]);

  // console.log(riskAccordionItemList);

  const isPositiveProduct = true;
  return (
    <Box>
      <Helmet title={`${searchValue} | Bitewise`}></Helmet>

      {navBar}
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined} />
        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          width={isNonMobileScreens ? "30%" : undefined}
        >

            {isNonMobileScreens && (
                <FlexBetween
                  gap="1rem"
                  sx={{ justifyContent: "flex-between", background: palette.background.searchBar }}
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon
                      style={{
                        color: "#3D3C3D",
                        width: "1.725rem",
                        height: "1.5rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    variant="h20"
                    color={textAppBarTitle}
                    fontWeight="400"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <FaqMenuGroupButton
                      showShareIcon={false}
                      offDehazeIcon={isNonMobileScreens}
                    />
                  </Typography>
                </FlexBetween>
              )}
          {productCount && ingredientCount && (
            <SearchResultTabsView
              searchValue={searchValue}
              productCount={
                productCount && productCount.count ? productCount.count : 0
              }
              ingredientCount={
                ingredientCount && ingredientCount.count
                  ? ingredientCount.count
                  : 0
              }
            ></SearchResultTabsView>
          )}
        </Box>

        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

function sendEvent(eventName, eventCategory, eventAction, eventLabel, eventValue){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
        value: eventValue
    }
  });
}

export default BitewiseSearchDetailsPage;
