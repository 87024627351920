import { Box, Typography, Switch, Grid, useTheme, useMediaQuery, CircularProgress } from "@mui/material";

import React, {useState, useEffect} from "react";
import { useGetHealthConditionListQuery, useGetProfileQuery, useUpdateHealthConditionMutation } from '../../../app/services/auth/authService'

import map from 'lodash/map';
import GenericNavBar from "../navbar/GenericNavBar";
import { Helmet } from 'react-helmet';
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { debugLog } from "utils/commonutils";

const HealthConditionSettingPage = () => {
    const { palette } = useTheme()
    const { data: profile, isLoading: profileIsLoading, refetch: refetchProfile } = useGetProfileQuery();
    const { data: healthConditions, isLoading: healthConditionLoading, error, refetch: refetchHealthConditions} = useGetHealthConditionListQuery();
    const [updateHealthCondition] = useUpdateHealthConditionMutation();

    const handleSwitchChange = async(id) => {
      await updateHealthCondition([id]);
      refetchProfile();
      refetchHealthConditions();
    };

    const theme = useTheme();

    const backgrountWhite = palette.background.alt;
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const [userHealthCondition, setUserHealthCondition] = useState(null);

    // useEffect(() => {
    //   window.dataLayer = window.dataLayer || [];
    //   window.dataLayer.push({
    //     "section_of_website" : "health_condition"
    //   });
    // }, []);

    useEffect(() => {
      if (profile && profile.health_conditions && profile.health_conditions.length > 0) {
        setUserHealthCondition(profile.health_conditions[0]);
      }
    }, [profile]);

    return (
      <Box>
      <Helmet title={`Health Condition | Bitewise`}>
      </Helmet>
     
      <GenericNavBar />
      { healthConditionLoading || profileIsLoading ? (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
          <CircularProgress />
        </Box>
        ) : error ? (
          
          <Box>
            <h1>
              {debugLog(error)}
              ({error.status}) Could not load data
            </h1>
            <p className="error">{error.data ? error.data.title : ""}</p>
          </Box>
        ) : (
          <Box
            width="100%"
            display={isNonMobileScreens ? "flex" : "block"}
            gap="0.5rem"
            justifyContent="space-between"
            height="100vh"
          >
            <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            </Box>
            <Box
              flexBasis={isNonMobileScreens ? "42%" : undefined}
              width={isNonMobileScreens ? "42%" : undefined}
              height="100%"
              pl = "2rem"
              pr = "2rem"
              pt = "1.5rem"
              backgroundColor={backgrountWhite}
            >
              <Typography fontWeight="400" fontFamily="h12" sx={{ mb: "1.5rem" }} color="#B1B8C2">
              SPECIAL CONDITION OR ISSUE
              </Typography>
              {
                profile && healthConditions ?
                (
                  <FlexBetweenVertical sx={{alignItems:"flex-start"}}>
                    {map(healthConditions, (item, index) => {
                        return (
                          <FlexBetween width="100%" key={index}>
                            <Typography
                              fontWeight="500"
                              fontSize="1rem"
                              alignItems="center"
                            >
                              {" "}
                              {item.name}{" "}
                            </Typography>
                            <Switch
                              color="warning"
                              checked={item.name === userHealthCondition}
                              onChange={() => handleSwitchChange(item.id)}
                            />
                          </FlexBetween>
                        );
                    })
                  }
                  </FlexBetweenVertical>
                ) :
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
              }
            </Box>
            {isNonMobileScreens && (
              <Box flexBasis="26%">
                <Box m="1.5rem 0" />
                </Box>
              )}
          </Box>
        )
        }
      </Box>
    );
};
  
export default HealthConditionSettingPage;