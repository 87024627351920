import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import parse from "html-react-parser";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { ExpandMoreOutlined, HelpOutline } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import HeaderBottomSheet from "./HeaderBottomSheet";
import { useTranslation } from "react-i18next";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid rgba(235, 235, 240, 1)`,
  "&:first-of-type": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(255, 99, 71, 0)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  padding: 0,
  textAlign: "justify",
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(235, 235, 240, 1)", // background:"#EBEBF0"
  textAlign: "justify",
}));

const StyleLi = {
  display: "flex",
  gap: 10,
  height: "25px",
  borderRadius: "4px 4px 0px 0px",
  alignItems: "center",
};

const ColorCodeItem = ({ color, bgColor, name, content }) => (
  <li style={StyleLi}>
    <Typography
      style={{
        color: color,
        backgroundColor: bgColor,
        padding: "4px 12px",
        textTransform: "uppercase",
        fontWeight: 600,
      }}
    >
      <CircleIcon style={{ width: "5px", height: "5px" }} /> {name}
    </Typography>
    <Typography fontWeight="600">{content}</Typography>
  </li>
);

const FaqWidgetBottom = (props) => {
  const { onClose, panelDefault = "panel1" } = props;
  const [expanded, setExpanded] = useState(panelDefault);
  const { t } = useTranslation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    onClose();
  };

  const FaqItem = ({ id, question, answer, ...props }) => (
    <AccordionStyled expanded={expanded === id} onChange={handleChange(id)}>
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreOutlined />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography
          variant="h14"
          color="#38383D"
          sx={{ width: "90%", flexShrink: 0, fontWeight: 600 }}
        >
          {question}
        </Typography>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <Typography
          align="justify"
          variant="h14"
          color="#32363D"
          fontWeight="400"
        >
          {parse(answer)}
        </Typography>
        {props.content ? props.content : ""}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
  const ColorCode = [
    {
      name: `${t("faq_bottomsheet.red")}`,
      color: "#D93843",
      bgColor: "#FFF0F1",
      content: `${t("faq_bottomsheet.bad_high_risk")}`,
    },
    {
      name: `${t("faq_bottomsheet.yellow")}`,
      color: "#E59900",
      bgColor: "#FFFCED",
      content: `${t("faq_bottomsheet.meh_caution")}`,
    },
    {
      name: `${t("faq_bottomsheet.green")}`,
      color: "#00AB56",
      bgColor: "#EFFFF4",
      content: `${t("faq_bottomsheet.good_safe")}`,
    },
  ];
  const ColorCodeList = () => (
    <ul
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        justifyContent: "space-between",
      }}
    >
      {ColorCode.map((value, index) => (
        <ColorCodeItem key={index} {...value} />
      ))}
    </ul>
  );

  const faqData = [
    {
      id: "panel1",
      question: `${t("FAQs.questions.what_is_bitewise")}`,
      answer: `${t("FAQs.answers.what_is_bitewise")}`,
    },
    {
      id: "panel3",
      question: `${t("FAQs.questions.when_should_i_use_it")}`,
      answer: `${t("FAQs.answers.when_should_i_use_it")}`,
    },
    {
      id: "panel4",
      question: `${t(
        "FAQs.questions.can_I_expect_to_find_all_packaged_food_products_on_Bitewise"
      )}`,
      answer: `${t(
        "FAQs.answers.can_I_expect_to_find_all_packaged_food_products_on_Bitewise"
      )}`,
    },
    {
      id: "panel5",
      question: `${t(
        "FAQs.questions.how_does_Bitewise_identify_health_risks/conditions_from_the_nutrientsand_ingredients"
      )}`,
      answer: `${t(
        "FAQs.answers.how_does_Bitewise_identify_health_risks/conditions_from_the_nutrientsand_ingredients"
      )}`,
    },
    {
      id: "panel6",
      question: `${t(
        "FAQs.questions.how_accurate_is_the_information_provided_by_Bitewise_can_some_information_be_wrong"
      )}`,
      answer: `${t(
        "FAQs.answers.how_accurate_is_the_information_provided_by_Bitewise_can_some_information_be_wrong"
      )}`,
    },
    {
      id: "panel7",
      question: `${t("FAQs.questions.how_is_the_score_calculated")}`,
      answer: `${t("FAQs.answers.how_is_the_score_calculated")}`,
    },
    {
      id: "panel8",
      question: `${t("FAQs.questions.how_does_Bitewise_suggest_alternatives")}`,
      answer: `${t("FAQs.answers.how_does_Bitewise_suggest_alternatives")}`,
    },
    {
      id: "panel9",
      question: `${t(
        "FAQs.questions.do_you_have_an_app_If_not_how_can_I_use_it_frequently"
      )}`,
      answer: `${t(
        "FAQs.answers.do_you_have_an_app_If_not_how_can_I_use_it_frequently"
      )}`,
    },
    {
      id: "panel10",
      question: `${t("FAQs.questions.how_can_I_reach_the_Bitewise_team")}`,
      answer: `${t("FAQs.answers.how_can_I_reach_the_Bitewise_team")}`,
    },
  ];
  

  const getFaqData = (panelDefault) => {
    if (panelDefault !== "panel1") {
      const dataPanel7 = faqData[5];
      // faqData.remove(dataPanel7);
      faqData.splice(5, 1); // 2nd parameter means remove one item only
      const index = 0;
      const newFaqData = [
          ...faqData.slice(0, index),
          dataPanel7,
          ...faqData.slice(index)
      ];
      // console.log(newNumbers); // Output: [1, 2, 3, 4, 5]
      // const term = faqData[0];
      // // const dataPanel7 = faqData[5];
      // faqData[0] = dataPanel7;
      // faqData[5] = term;
      return newFaqData;
    }
    return faqData;
  };
  const { palette } = useTheme();
  const textOrangeDark = palette.textColor.orangeDark;
  const iconFaq = (
    <HelpOutline style={{ color: textOrangeDark, fontSize: "25px" }} />
  );

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "100%",
          zIndex: 10,
        }}
      >
        <HeaderBottomSheet name={"FAQ"} close={handleClose} icon={iconFaq} />
      </Box>
      {getFaqData(panelDefault).map((item) => (
        <FaqItem key={item.id} {...item} />
      ))}
    </Box>
  );
};

export default FaqWidgetBottom;
