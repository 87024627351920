import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProductItemWidgetSearch from "scenes/widgets/ProductItemWidgetSearch";
import { useGetSearchProductListQuery } from "app/services/auth/authService";
import SearchProductsListView from "./SearchProductsListView";
import SearchIngredientsListView from "./SearchIngredientsListView";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import SearchListItemPagi from "./SearchListItemPagi";
import SearchIngredientListViewPagi from "./SearchIngredientListViewPagi";

const StyledTab = styled(Tab)({
  fontSize: "16px",
  fontWeight: "600",
  textTransform: "capitalize",
  "&.Mui-selected": {
    color: "#FC820A",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { palette } = useTheme();
  const mainHeading = palette.textColor.mainHeading;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0.75rem 0.25rem" }}>
          <Typography color={mainHeading}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const SearchResultTabsView = ({
  searchValue,
  productCount,
  ingredientCount,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { palette } = useTheme();

  const backgroundSearchBar = palette.background.searchBar;
  const selectedTabColor = palette.textColor.selectedTabColor;
  const mainHeading = palette.textColor.mainHeading;
  const [page, setPage] = React.useState(0);

  //   const { data: productList, isLoading: isLoadingProductList, error: isErrorProductList } = useGetSearchProductListQuery({searchValue, page}, {
  //       skip: (searchValue === '' || productCount<=0),
  //   })

  React.useEffect(() => {
    if (productCount <= 0 && ingredientCount > 0) {
      setValue(1);
    }
  }, [productCount, ingredientCount]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box>
      {/* <AppBar position="static"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        //   indicatorColor={selectedTabColor}
        //   textColor={selectedTabColor}

        TabIndicatorProps={{ style: { background: selectedTabColor } }}
        variant="fullWidth"
        sx={{
          backgroundColor: backgroundSearchBar,
          color: mainHeading,
        }}
        aria-label="full width tabs example"
      >
        <StyledTab
          sx={{
            color: mainHeading,
          }}
          label={t("searchDetail.tab_products") + " (" + productCount + ")"}
          {...a11yProps(0)}
        />
        <StyledTab
          sx={{
            color: mainHeading,
          }}
          label={
            t("searchDetail.tab_ingredients") + " (" + ingredientCount + ")"
          }
          {...a11yProps(1)}
        />
      </Tabs>
      {/* </AppBar> */}
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SearchListItemPagi
            searchValue={searchValue}
            productCount={productCount}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SearchIngredientListViewPagi
            searchValue={searchValue}
            ingredientCount={ingredientCount}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default SearchResultTabsView;
