import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomRisksAccordion from "./CustomRisksAccordion";
import AlternativeProductWidget from "./AlternativeProductWidget";
import { useTranslation } from "react-i18next";

const AlternativeProductsListWidget = ({ rating, name, alternativeItems }) => {
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const wrap = isNonMobileScreens ? "wrap" : "nowrap";
  const elementFlex = isNonMobileScreens ? "40%" : "auto";
  const widthEle = isNonMobileScreens ? "143px" : "144px";
  const isScroll = isNonMobileScreens ? "auto" : "scroll";

  if (
    (!alternativeItems || alternativeItems.length === 0) &&
    "GOOD" != rating
  ) {
    return <></>;
  }

  if (!alternativeItems || alternativeItems.length === 0) {
    return <EmptyView name={name} count={0} palette={palette} />;
  }

  const count = alternativeItems.length;

  return (
    <Box
      gap="1rem"
      sx={{
        maxWidth: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleHeader
        name={name}
        count={count}
        palette={palette}
        isNonMobileScreens={isNonMobileScreens}
        style={{ width: "100%" }}
      />
      <Box
        style={{
          width: "100%",
          overflow: "auto",
          display: "flex",
          gap: "0.5rem",
          flexWrap: wrap,
        }}
      >
        {alternativeItems.map((data, index) => {
          return (
            <AlternativeProductWidget
              productItem={data}
              key={index}
              style={elementFlex}
              widthEle={widthEle}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export function EmptyView({ name, count, palette }) {
  const { t } = useTranslation();
  return (
    <Box gap="0.75rem" mt="1.875rem" width="100%">
      <TitleHeader name={name} count={count} palette={palette} />
      <Box
        gap="0.75rem"
        mt="0.75rem"
        pt="2rem"
        pb="2rem"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        sx={{
          border: 1.5,
          borderColor: palette.borderColor.grey,
          borderRadius: "1rem",
          borderStyle: "dashed",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="0.25rem"
          pl="1rem"
          pr="1rem"
        >
          <Typography
            variant="h16"
            color={palette.textColor.mainHeading}
            fontWeight="600"
          >
            {t("productDetail.enjoy_your_food")}
          </Typography>
          <Typography
            align="center"
            variant="h14"
            color={palette.textColor.greyLight}
            fontWeight="400"
          >
            {t("productDetail.no_higher_score_product_found_in_this_category")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function TitleHeader({ name, count, palette, isNonMobileScreens }) {
  const { t } = useTranslation();
  const flexDirection = isNonMobileScreens ? "column" : "row";
  const alignItems = isNonMobileScreens ? "start" : "center";
  return (
    <FlexBetween
      sx={{
        mb: "0.75rem",
        flexDirection: flexDirection,
        alignItems: alignItems,
        gap: "0.5rem",
      }}
    >
      <Typography
        variant="h20"
        color={palette.textColor.greyLight}
        fontWeight="900"
      >
        {name}
      </Typography>
      <Typography
        variant="h12"
        color={palette.textColor.greyLight}
        fontWeight="500"
      >
        {count} {t("productDetail.healthieralternatives_products_count")}
      </Typography>
    </FlexBetween>
  );
}

export default AlternativeProductsListWidget;
