import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { styled, useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { forEach, map } from "lodash";
import {
  getNutrientLineChartRatingColor,
  getNutrientLineChartRatingColor2,
  getNutrientRatingColor,
} from "utils/commonutils";

const Item = styled(Paper)(
  ({ theme, backgroundcolor, color, borderradius }) => ({
    ...theme.typography.body2,
    backgroundColor: backgroundcolor,
    color,
    textAlign: "center",
    borderTopLeftRadius: borderradius?.borderTopLeftRadius,
    borderBottomLeftRadius: borderradius?.borderBottomLeftRadius,
    borderTopRightRadius: borderradius?.borderTopRightRadius,
    borderBottomRightRadius: borderradius?.borderBottomRightRadius,
    boxShadow: "none",
    padding: "0.25rem",
  })
);

const getMarkerRange = (ranges, markerValue) => {
  let activeRange = 0;
  forEach(ranges, (range, i) => {
    if (markerValue >= range.min && markerValue <= range.max) activeRange = i;
  });
  return activeRange;
};

const getCaratMargin = (range, markerValue) => {
  const offset = markerValue - range.min;
  const margin = (100 * offset) / (range.max - range.min) - 5;
  if (margin < 0) return -5;
  return (100 * offset) / (range.max - range.min) - 5 + "%";
};

const getRangesFromScales = (scales) => {
  var ranges = [];
  forEach(scales, (range, i) => {
    ranges.push({ min: range.min, max: range.max });
  });
  return ranges;
};

const getColorsFromScales = (scales, palette) => {
  var colors = [];

  if (scales?.length > 2) {
    forEach(scales, (scale, i) => {
      colors.push(getNutrientLineChartRatingColor2(scale.rate_code, palette));
    });
  } else {
    forEach(scales, (scale, i) => {
      colors.push(getNutrientLineChartRatingColor(scale.rate_code, palette));
    });
  }
  return colors;
};

const NutritionLineView = ({
  markerValue: _markervalue,
  scales,
}) => {
  const { palette } = useTheme();
  const ranges = getRangesFromScales(scales);
  const colors = getColorsFromScales(scales, palette);
  const markerValue = Math.min(_markervalue, ranges[ranges.length - 1].max);
  const markerRange = getMarkerRange(ranges, markerValue);
  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems={"center"}
      justifyContent={"space-around"}
      pt="0.25rem"
    >
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0, sm: 0, md: 0 }}
        width="100%"
        borderRadius={"0.25rem"}
      >
        {map(colors, (color, i) => {
          return (
            <Grid key={i} item xs={12 / colors.length}>
              {i === markerRange ? (
                <Box
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: "0.375rem solid transparent",
                    borderRight: "0.375rem solid transparent",
                    borderTop: "0.375rem solid " + colors[i],
                    marginBottom: "0.125rem",
                    marginLeft: getCaratMargin(ranges[i], markerValue),
                  }}
                ></Box>
              ) : (
                <Box
                  style={{
                    minHeight: "0.375rem",
                    marginBottom: "0.125rem",
                  }}
                ></Box>
              )}
              <Item
                backgroundcolor={colors[i]}
                color={colors[i]}
                borderradius={
                  i === 0
                    ? {
                        borderTopLeftRadius: "0.25rem",
                        borderBottomLeftRadius: "0.25rem",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }
                    : i === colors.length - 1
                    ? {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                      }
                    : {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }
                }
              >
                {" "}
              </Item>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  height: "21px",
                }}
              >
                <Typography
                  variant="h11"
                  color="#A6A6B0"
                  fontWeight="400"
                  sx={{
                    position: "absolute",
                    left: "-5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    textAlign: "right",
                    height: "100%",
                  }}
                >
                  {ranges[i].min}
                </Typography>
                {i === colors.length - 1 && (
                  <Typography
                    variant="h11"
                    color="#A6A6B0"
                    fontWeight="400"
                    sx={{
                      position: "absolute",
                      right: "-15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      textAlign: "right",
                      height: "100%",
                    }}
                  >
                    {"≥ "}
                    {ranges[i].max}
                  </Typography>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default NutritionLineView;
