import Slider from "react-slick";
import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";

import "./styles/slick.css";
import "./styles/slick-theme.css";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import { useGetHomePageProductsQuery } from "app/services/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import { addNewHistoryToLocal } from "features/search/searchSlice";

const HomePageProductsViewPager = ({ initialSlide = 0 }) => {
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const slider = useRef();
  const { isChangeHealth } = useSelector((state) => state.bottomSheet);

  const {
    data: productList,
    isLoading,
    error,
    refetch,
  } = useGetHomePageProductsQuery();

  useEffect(() => {
    refetch();
  }, [isChangeHealth]);

  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current.slickGoTo(initialSlide);
      setHasSetPosition(true);
    }
  }, [initialSlide, hasSetPosition, slider]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide,
    // autoplay: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };

  if (isLoading || !productList || error) return <></>;

  return (
    <Box mt="1rem" ml="-0.75rem" mr="-0.75rem">
      <Slider {...settings} height="100%" width="100%">
        {productList.map((item, index) => {
          // return <MovieCard movie={item}/>

          return (
            <ProductListItemWidgetWrapper productItem={item} key={index} />
          );
        })}
      </Slider>
    </Box>
  );
};

function ProductListItemWidgetWrapper({ productItem }) {
  const dispatch = useDispatch();

  const handleClick = (value) => {
    dispatch(addNewHistoryToLocal(value));
  };

  return (
    <Box height="100%" pb={1}>
      <Box pb="3px">
        <ProductListItemWidget
          showBoxShadow={true}
          productItem={productItem}
          handeClick={handleClick}
        />
      </Box>
    </Box>
  );
}

export default HomePageProductsViewPager;
