import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IngredientIcon } from "../../assets/chemical.svg";

const MyStyledChip = ({
  onClick,
  label,
  righticon,
  textColor,
  backgroundColor,
  borderColor,
  fontWeight,
  leftEdgeColor,
  type = "",
  icon = "",
}) => {
  const handleChipClick = () => {
    onClick();
  };
  return (
    <Box
      onClick={handleChipClick}
      p={righticon ? "0rem 0.5rem 0rem 0.75rem" : "0.25rem 0.5rem"}
      sx={{
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: backgroundColor,
        border: 1,
        borderColor: borderColor,
        borderLeftColor:
          leftEdgeColor !== "transparent" ? leftEdgeColor : borderColor,
        borderRadius: "0.5rem",
        display: "flex",
        minHeight: "2rem",
        width: "fit-content",
        position: "relative",
        gap: "4px",
        "&::before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: leftEdgeColor !== "transparent" ? "4.5px" : 0,
          height: "100%",
          backgroundColor: leftEdgeColor,
          borderTopLeftRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
        },
      }}
    >
      {type === "additive" && (
        <IngredientIcon
          style={{ width: "18px", height: "18px", marginTop: "0.125rem" }}
        />
      )}
      {type && type === "nutrient" && (
        <img
          style={{ objectFit: "cover", marginTop: "0.125rem" }}
          width="18px"
          height="18px"
          alt="Nutrient Image"
          src={icon}
        />
      )}

      <Typography
        align="center"
        variant="h14"
        color={textColor}
        fontWeight={fontWeight}
      >
        {label}
      </Typography>
      {righticon && <IconButton>{righticon}</IconButton>}
    </Box>
  );
};

export default MyStyledChip;
