import React from "react";
import {
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  ButtonBase,
} from "@mui/material";
// import { HelpOutline } from "@mui/icons-material";
// import { useDispatch } from "react-redux";
// import { setMode, setLogout } from "state";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
// import { styled } from "@mui/material/styles";
// import Logo from "components/Logo";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";
// import { ReactComponent as PersonCircle } from "../../../assets/person_circle.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { useDebounce } from "components/Debounce";
import FaqMenuGroupButton from "./FaqMenuGroupButton";

const GenericNavBar = ({
  title = "",
  appBarBackgroundColor = "#ffffff",
  showShareIcon = false,
  shareData = null,
}) => {
  // const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const { palette } = useTheme();

  const alt = palette.background.alt;
  // const textMain = palette.textColor.main;
  const textMainHeading = palette.textColor.mainHeading;
  // const textGreyLight = palette.textColor.greyLight;
  // const textOrangeLight = palette.textColor.orangeLight;
  // const textOrangeDark = palette.textColor.orangeDark;
  const textAppBarTitle = palette.textColor.appBarTitle;
  // const borderGrey = palette.borderColor.grey;
  // const backgroundSearchBar = palette.background.searchBar;
  // const neutralLight = palette.neutral.light;

  // const LanguageButton = styled(Button)({
  //   boxShadow: "none",
  //   fontSize: 14,
  //   "&:hover": {
  //     backgroundColor: "transparent",
  //   },
  // });

  // const [value, setValue] = useState("");
  // const [showClearIcon, setShowClearIcon] = useState(false);

  return (
    <FlexBetween
      width="100%"
      padding={isNonMobileScreens ? "1rem 6%" : "1rem 3%"}
      backgroundColor={isNonMobileScreens ? alt : appBarBackgroundColor}
      position={"relative"}
      zIndex={10}
    >
      {isNonMobileScreens && (
        <ButtonBase onClick={() => navigate(`/`)}>
          {" "}
          <LogoIcon />{" "}
        </ButtonBase>
      )}
      {!isNonMobileScreens && (
        <FlexBetween gap="1rem" sx={{ justifyContent: "flex-between" }}>
          <IconButton
            onClick={() => {
              if (location.key === 'default' || window.history.length <= 2) {
                navigate(`/`);
              } else {
                navigate(-1);
              }
            }}
          >
            <ArrowBackIcon
              style={{ color: "#3D3C3D", width: "1.725rem", height: "1.5rem" }}
            />
          </IconButton>

          <Typography
            variant="h20"
            color={textAppBarTitle}
            fontWeight="400"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
        </FlexBetween>
      )}
      <FaqMenuGroupButton showShareIcon={showShareIcon} shareData={shareData} />
    </FlexBetween>
  );
};

export default GenericNavBar;
