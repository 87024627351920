import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FlexBetween from "components/FlexBetween";
import { useTranslation } from "react-i18next";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import parse from "html-react-parser";

const ExpandableLongTextView = ({ value }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { palette } = useTheme();
  const { t } = useTranslation();
  const textOrangeDark = palette.textColor.orangeDark;
  const greyLight = palette.textColor.greyLight;
  const mainHeading = palette.textColor.mainHeading;
  const [maxHeight, setMaxHeight] = useState("300px");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  function getTypographyColor(text) {
    return text && text.length > 0 ? mainHeading : greyLight;
  }

  useEffect(() => {
    if (isExpanded) {
      setMaxHeight("100%");
    } else {
      setMaxHeight("300px");
    }
  }, [isExpanded]);

  return (
    <Box
      className="expandable-text-view"
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{
        maxHeight: isExpanded ? "none" : maxHeight,
        overflow: isExpanded ? "hidden" : "visible",
        width: "100%",
        position: "relative",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <FlexBetweenVertical
        sx={{ alignItems: "flex-start", height: "100%", overflowY: "hidden" }}
      >
        <Typography
          variant="h14"
          fontWeight="300"
          color={getTypographyColor(value)}
        >
          {parse(value)}
        </Typography>
      </FlexBetweenVertical>
      {value && (
        <Box mt="0.75rem" sx={{ alignItems: "center" }}>
          <Divider
            ml="-0.75rem"
            mr="-0.75rem"
            display="flex"
            mt="0.75rem"
            width="100%"
          />
          <FlexBetween
            mt="0.75rem"
            sx={{ justifyContent: "center" }}
            onClick={toggleExpand}
          >
            <Typography variant="h12" fontWeight="700" color={textOrangeDark}>
              {isExpanded ? t("common.see_less") : t("common.see_more")}
            </Typography>
            {isExpanded ? (
              <ExpandLessIcon style={{ color: "#FF751E" }} />
            ) : (
              <ExpandMoreIcon style={{ color: "#FF751E" }} />
            )}
          </FlexBetween>
        </Box>
      )}
    </Box>
  );
};

export default ExpandableLongTextView;
