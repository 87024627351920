import { Box, Typography, useTheme, Button, List, ListItem, ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";

import FlexBetweenVertical from "components/FlexBetweenVertical";
import HeaderBottomSheet from "./HeaderBottomSheet";
import { ReactComponent as AddToHomePhone } from "../../assets/addtohome_phone.svg";
import { ReactComponent as LogoIcon2 } from "../../assets/bitewise_logo_navbar.svg";
import { ReactComponent as SafariMenu } from "../../assets/safari_menu.svg";
import { ReactComponent as SafariAdd } from "../../assets/safari_add.svg";
import { ReactComponent as ChromeMenu } from "../../assets/chrome_menu.svg";
import { ReactComponent as ChromeAdd } from "../../assets/chrome_add.svg";

import { useNavigate } from "react-router-dom";
import WidgetWrapper from "components/WidgetWrapper";
import { useTranslation } from "react-i18next";
import {isMobile, isSafari, isChrome} from 'react-device-detect';

const AddToHomeScreenWidget = (props) => {
const { palette } = useTheme();
const navigate = useNavigate();

const { t } = useTranslation();

const backgrountWhite = palette.background.alt;

const { onClose } = props;

// useEffect(() => {
//     window.dataLayer = window.dataLayer || [];
//     window.dataLayer.push({
//       "section_of_website" : "add_to_home_screen"
//     });
//   }, []);

// console.log("Arsy add to home screen");
const iconLogo = (
    <LogoIcon2 style={{ 
            width: "12px", 
            height: "12px", 
            minWidth: "12px",
            minHeight: "12px" }} />
  );

return (
    <WidgetWrapper
    width="100%"
    sx={{
        borderRadius: "1rem",
        background: backgrountWhite,
        padding: "0",
    }}
    >
        <HeaderBottomSheet name={t("menu.add_bitewise_to_homescreen")} close={onClose} icon={iconLogo}/>

        <FlexBetweenVertical
            gap="1rem"
            sx={{ justifyContent: "center" }}
        >
            <AddToHomePhone />
            <Typography
                align="center"
                variant="h20"
                color={palette.textColor.mainHeading}
                fontWeight="600"
            >
                {t("addToHomeScreen.title")}
            </Typography>
            <Typography
                align="center"
                variant="h15FuzzyBubbles"
                color="#7D8899"
                fontWeight="700"
            >
                {t("addToHomeScreen.sub_title")}
            </Typography>
            { 
                (isMobile && isSafari) 
                ? 
                    <SafariWidget palette={palette} t={t}/>
                :
                (isMobile && isChrome)
                ?
                    <ChromeWidget palette={palette} t={t}/>
                :
                <></>
                // <ChromeWidget palette={palette} t={t}/>
            }
            
        </FlexBetweenVertical>
    </WidgetWrapper>
);
};

function SafariWidget({palette, t}){
    return <FlexBetweenVertical
        mt="0.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
    >
        <Typography
            variant="h14"
            color={palette.textColor.mainHeading}
            fontWeight="400"
        >
            {t("addToHomeScreen.safari_header")}
        </Typography>
        <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
                <Box style={{ display: "flex", alignItems: "center"}}>
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        {t("addToHomeScreen.safari_step1_1")} &nbsp;
                    </Typography>
                    <SafariMenu />
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        &nbsp; {t("addToHomeScreen.safari_step1_2")}
                    </Typography>
                </Box>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <Typography
                    variant="h14"
                    color={palette.textColor.mainHeading}
                    fontWeight="400"
                >
                    {t("addToHomeScreen.safari_step2")} &nbsp;
                </Typography>
            </ListItem>
        </List>
        <Box style={{ display: "flex"}}>
            <Typography
                variant="h14"
                color={palette.textColor.mainHeading}
                fontWeight="400"
            >
                {t("addToHomeScreen.safari_note_1")} &nbsp;
                <SafariAdd style={{pt:"0.5rem"}} />
                &nbsp; {t("addToHomeScreen.safari_note_2")}
            </Typography>
        </Box>
    </FlexBetweenVertical>
}

function ChromeWidget({palette, t}){
    return <FlexBetweenVertical
        mt="0.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
    >
        <Typography
            variant="h14"
            color={palette.textColor.mainHeading}
            fontWeight="400"
        >
            {t("addToHomeScreen.chrome_header")}
        </Typography>
        <List sx={{ listStyle: "decimal", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
                <Box style={{ display: "flex", alignItems: "center"}}>
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        {t("addToHomeScreen.chrome_step1_1")} &nbsp;
                    </Typography>
                    <ChromeMenu />
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        &nbsp; {t("addToHomeScreen.chrome_step1_2")}
                    </Typography>
                </Box>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <Box style={{ display: "flex", alignItems: "center"}}>
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        {t("addToHomeScreen.chrome_step2_1")} &nbsp;
                    </Typography>
                    <ChromeAdd />
                    <Typography
                        variant="h14"
                        color={palette.textColor.mainHeading}
                        fontWeight="400"
                    >
                        &nbsp; {t("addToHomeScreen.chrome_step2_2")}
                    </Typography>
                </Box>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
                <Typography
                    variant="h14"
                    color={palette.textColor.mainHeading}
                    fontWeight="400"
                >
                    {t("addToHomeScreen.chrome_step3")}
                </Typography>
            </ListItem>
        </List>
    </FlexBetweenVertical>
}

export default AddToHomeScreenWidget;
