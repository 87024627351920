import React, { useState, useEffect } from 'react';

// Counter component
const DeveloperModeCounter = ({ count, setCount, openDeveloperPopup }) => {
  // Effect to clear the count after 10 seconds
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCount(0); // Clear the count after 10 seconds
    }, 10000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout
  }, [count]); // Run effect whenever count or increment function changes

  useEffect(() => {
    if(count === 10){
        setCount(0); // Clear the count after opening developer POPup
        openDeveloperPopup();
    }
  }, [count]);
  
  return (
    <></>
  );
}

export default DeveloperModeCounter;