import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {  getRatingBackgroundColor, 
  getRatingTextColor, 
  getRatingImage, 
  getRatingText, 
  getRatingImage2} from "utils/commonutils";
import FlexBetweenVertical from "./FlexBetweenVertical";

const ProductListItemRating = ({ rating, w = "64px" }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  rating = rating ? rating.toUpperCase() : "";

  return (
    <FlexBetweenVertical
      width={w}
      minWidth={w}
      backgroundColor={getRatingBackgroundColor(rating, palette)}
      pt="0.675rem"
      pb="0.675rem"
      gap="0.25rem"
      px="0.5rem"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.75rem",
      }}
    >
      {getRatingImage2(rating)}
      <Typography
        variant="h16"
        align="center"
        color={getRatingTextColor(rating, palette)}
        fontWeight="800"
      >
        {getRatingText(rating, t)}
      </Typography>
    </FlexBetweenVertical>
  );
};

export default ProductListItemRating;
