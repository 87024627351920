import { useTheme } from "@emotion/react";
import { Search } from "@mui/icons-material";
import { Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useNavigate } from "react-router-dom";
import RestoreIcon from "@mui/icons-material/Restore";
import { useTranslation } from "react-i18next";

const SearchListItem = ({
  searchData,
  isHistoric = false,
  searchValue = null,
}) => {
  const { palette, typography } = useTheme();
  const { t } = useTranslation();
  const textMainSecondary = palette.textColor.mainSecondary;
  const textGreySecondary = palette.textColor.greySecondary;
  const textFont12 = typography.h7;
  const navigate = useNavigate();
  const showIcon = isHistoric ? (
    <RestoreIcon sx={{ color: "#B1B8C2" }} />
  ) : (
    <Search />
  );
  const historicTitle = isHistoric ? (
    <Typography
      sx={{
        textColor: "#B1B8C2",
        textTransform: "uppercase",
        fontSize: textFont12,
        fontWeight: 600,
        letterSpacing: "1px",
      }}
    >
      {t("menu.history")}
    </Typography>
  ) : (
    <></>
  );

  const gotoSearchDetailsPage = (searchText, searchValue) => {
    const encodedSearchValue = encodeURIComponent(searchText);
    // console.log("encodedSearchValue", encodedSearchValue);

    navigate(`/search_detail/${encodedSearchValue}`);
    // Save the search text in local storage
    localStorage.setItem("searchQueryText", searchValue);
  };

  return (
    <FlexBetweenVertical
      gap="1.75rem"
      pl="1.5rem"
      pr="1.5rem"
      sx={{ alignItems: "flex-start", color: "#B1B8C2" }}
    >
      {historicTitle}
      {searchData?.slice(0, 3).map((res, index) => {
        const name = !isHistoric ? res : res.name;
        const path = !isHistoric ? res : res.name;
        return (
          <FlexBetween
            gap="0.875rem"
            onClick={() => {
              gotoSearchDetailsPage(path, searchValue);
            }}
            key={index}
            style={{ cursor: "pointer" }}
          >
            {showIcon}
            <Typography
              variant="h16"
              color={textMainSecondary}
              fontWeight="400"
            >
              {name}
            </Typography>
          </FlexBetween>
        );
      })}
    </FlexBetweenVertical>
  );
};

export default SearchListItem;
