import { Box, Typography } from "@mui/material";
import { ReactComponent as InforIcon } from "../../assets/info_icon.svg";

const getTextSugar = (percent, sugar_index, t) => {
  if (percent) {
    return (
      <Box>
        <Typography
          sx={{
            display: "inline",
          }}
        >
          {t("productDetail.although_not_mentioned_in_the_Nutrition_Fact")}
          <Typography sx={{ display: "inline", fontWeight: 600 }}>
            {t("productDetail.bitewise_identifies_the_presence_of_sugar_with")}{" "}
            {percent}%{" "}
          </Typography>
          {t("productDetail.in_its_ingredient_list")}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography
        sx={{
          display: "inline",
        }}
      >
        {t("productDetail.although_not_specified_by_the_manufacturer")}
        <Typography sx={{ display: "inline", fontWeight: 600 }}>
          {t("productDetail.bitewise_identifies_the_presence_of_sugar_at_the")}
          {sugar_index} {t("productDetail.place")}{" "}
        </Typography>
        {t(
          "productDetail.in_its_ingredient_list_This_means_the_quantity_could_be_considerable"
        )}
      </Typography>
    </Box>
  );
};

const DescriptionSugerNotSpecific = ({ percent, sugar_index, t }) => {
  return (
    <Box
      sx={{
        borderRadius: "1rem",
        border: "1px solid #D8DBE0",
        padding: "8px 12px",
        marginTop: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "8px",
          flex: "1 0 0",
        }}
      >
        <Box
          sx={{
            width: "1rem",
            height: "1rem",
          }}
        >
          <InforIcon />
        </Box>
        <Typography fontSize={"13px"}>
          {getTextSugar(percent, sugar_index, t)}
        </Typography>
      </Box>
    </Box>
  );
};

export default DescriptionSugerNotSpecific;
