import { FC } from "react";

interface ShareData {
  files?: File[];
  text?: string;
  title?: string;
  url?: string;
}

// Props for the ShareController component
interface Props {
  children: React.ReactNode;
  shareData: ShareData;
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  onNonNativeShare?: () => void;
  onInteraction?: () => void;
  disabled?: boolean;
}

// The ShareController component
const ShareController: FC<Props> = ({
  children,
  shareData,
  onInteraction,
  onSuccess,
  onError,
  onNonNativeShare,
  disabled,
}) => {
  const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleOnClick = async () => {
    onInteraction && onInteraction(); // Call interaction callback if provided

    try {
      // Handle Android WebView sharing
      if (isAndroid && window.Android && shareData?.title) {
        window.Android.shareContent(
          shareData.title,
          shareData.text ?? "",
          shareData.url ?? ""
        );
        onSuccess && onSuccess();
      }
      // Handle iOS WebView sharing
      else if (
        isIOS &&
        window.webkit?.messageHandlers?.shareContent &&
        shareData?.title
      ) {
        window.webkit.messageHandlers.shareContent.postMessage({
          title: shareData.title,
          text: shareData.text ?? "",
          url: shareData.url ?? "",
        });
        onSuccess && onSuccess();
      }
      // Handle Web Browser sharing using Web Share API
      else if (navigator.share) {
        await navigator.share(shareData);
        onSuccess && onSuccess();
      }
      // Fallback for unsupported browsers/platforms
      else {
        onNonNativeShare && onNonNativeShare();
      }
    } catch (error) {
      onError && onError(error);
    }
  };

  return (
    <button
      onClick={handleOnClick}
      type="button"
      disabled={disabled}
      style={{
        border: "none",
        backgroundColor: "transparent",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {children}
    </button>
  );
};

export default ShareController;
