import { styled, useTheme } from "@mui/material/styles";
import { Chip, Box } from "@mui/material";
import {
  getChipBorderColor,
  getChipEdgeColor,
  getRatingBackgroundColor,
  getRatingChipFontWeight,
  getRatingImageForChip,
  getTextColorChip,
} from "utils/commonutils";
import MyStyledChip from "scenes/widgets/MyStyledChip";
import { useEffect } from "react";

const options = {
  shouldForwardProp: (prop) => prop !== "bgcolor",
};
export const StyledChip = styled(
  Chip,
  options
)(({ bgcolor, textColor, leftEdgeColor }) => ({
  color: textColor,
  backgroundColor: bgcolor,
  position: "relative",
  paddingLeft: "1rem",
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "8px",
    backgroundColor: leftEdgeColor,
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
}));

const ProductChips = ({
  chipsList = {},
  productDetailPage = false,
  ingredientDetailPage = false,
  showInfo = false,
  handleClick = () => {},
  handleClickOpen = () => {},
  isHover = false,
}) => {
  const { palette } = useTheme();
  const borderGrey = palette.borderColor.chip;
  const bgHover = isHover ? "#D5D6D7" : "white";

  return (
    <Box
      gap="0.5rem"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        alignItems: "flex-start",
        m: 0,
      }}
    >
      {chipsList.map((data, index) => {
        const labelName = ingredientDetailPage
          ? data
          : productDetailPage
          ? data.risk_item.name
          : data.name;
        // console.log("data: ", data);
        // console.log("jahajhaj1: ", productDetailPage);

        return (
          <MyStyledChip
            onClick={() => {
              handleClick(data);
              handleClickOpen("openHealthImpact");
            }}
            key={index}
            label={labelName}
            backgroundColor={getRatingBackgroundColor(
              data.risk_item ? data.risk_item.rating : data.rating,
              palette
            )}
            borderColor={getChipBorderColor(
              data.risk_item ? data.risk_item.rating : data.rating,
              palette,
              data?.type
            )}
            textColor={getTextColorChip(
              data.risk_item ? data.risk_item.rating : data.rating,
              palette,
              data?.type
            )}
            fontWeight={getRatingChipFontWeight(
              data.risk_item ? data.risk_item.rating : data.rating,
              data?.type
            )}
            righticon={getRatingImageForChip(
              data.risk_item ? data.risk_item.rating : data.rating,
              "0.75rem",
              productDetailPage,
              data?.type
            )}
            leftEdgeColor={getChipEdgeColor(
              data.risk_item ? data.risk_item.rating : data.rating,
              palette,
              data?.type,
              data?.scales,
              data?.color
            )}
            type={data?.type}
            icon={data?.icon}
          />
          // <StyledChip
          //   onClick={() => {
          //     handleClick(data);
          //     handleClickOpen("openHealthImpact");
          //   }}
          //   key={index}
          //   label={labelName}
          //   bgcolor="white"
          //   textColor="#3D3C3D"
          //   textSize="14"
          //   deleteIcon={<VerifiedOutlined />}
          //   sx={{
          //     border: 1,
          //     backgroundColor: getRatingBackgroundColor(data.risk_item ? data.risk_item.rating : data.rating, palette),
          //     borderColor: getRatingChipBorderColor(data.risk_item ? data.risk_item.rating : data.rating, palette),
          //     borderRadius: "0.5rem",
          //     minHeight: "30px",
          //     height: "100%",
          //     "& .MuiChip-label": {
          //       fontSize: 13,
          //       maxWidth: "500px",
          //       whiteSpace: "pre-wrap",
          //     },
          //     "& .MuiChip-deleteIcon": {
          //       color: "grey",
          //     },
          //     "&:hover": {
          //       backgroundColor: bgHover,
          //     },
          //   }}
          // />
        );
      })}
    </Box>
  );
};

// function MyStyledChip(){
//   return
//   (<Box padding="0.25rem 0.25rem 0.25rem 0.25rem" backgroundColor="white">

//   </Box>)
// }
export default ProductChips;
