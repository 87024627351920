import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import NutritionLineView from "components/NutritionLineView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorIcon from "@mui/icons-material/Error";
import isEmpty from "lodash/isEmpty";
import parse from "html-react-parser";

import {
  Box,
  Typography,
  Divider,
  useTheme,
  Badge,
  Avatar,
  Chip,
  ButtonBase,
} from "@mui/material";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import {
  getBorderColorNutrient,
  getIngredientRatingText,
  getNutrientRatingColor,
} from "utils/commonutils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { ReactComponent as AdditiveIcon } from "../../assets/chemical.svg";
import { ReactComponent as IngredientIcon } from "../../assets/ingredient-new.svg";
import DescriptionSugerNotSpecific from "./DescriptionSugerNotSpecific";
import { t } from "i18next";
import { setIsActiveAccordionPDP } from "features/bottomSheet/bottomSheetSlice";
import { useDispatch, useSelector } from "react-redux";

const GetSugarTagName = (index) => {
  const { t } = useTranslation();
  if (index >= 1 && index <= 20) {
    return t(`tag_name.${index}`);
  }
};

const GetRateCode = (index) => {
  if (index <= 3) {
    return "RED";
  } else if (index <= 5) {
    return "CAUTION";
  } else if (index > 5) {
    return "LIME_GREEN";
  } else {
    return "";
  }
};

const CustomRisksAccordion = ({
  healthRiskItem,
  isIngredientAccordian = false,
  isRiskView,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isActiveAccordionPDP } = useSelector((state) => state.bottomSheet);

  return (
    <WidgetWrapper sx={{ mt: "0.75rem", pt: "0.75rem" }}>
      <FlexBetween
        onClick={() => {
          setIsActive(!isActive);
          dispatch(setIsActiveAccordionPDP(!isActiveAccordionPDP));
        }}
      >
        {isIngredientAccordian ? (
          <AccordionHeaderIngredient
            healthRiskItem={healthRiskItem}
            palette={palette}
            isActive={isActive}
            ingredientCount={healthRiskItem.length}
            translation={t}
            isRiskView={isRiskView}
          />
        ) : (
          <AccordionHeaderNutrient
            healthRiskItem={healthRiskItem}
            palette={palette}
            isActive={isActive}
            isSugarCase={
              healthRiskItem?.sugar_index || healthRiskItem?.sugar_percent
            }
            percent={healthRiskItem?.sugar_percent}
            t={t}
          />
        )}
      </FlexBetween>
      {isActive && !isIngredientAccordian && (
        <AccordionFooterNutrient
          healthRiskItem={healthRiskItem}
          palette={palette}
          navigate={navigate}
          t={t}
        />
      )}
      {isActive && isIngredientAccordian && (
        <AccordionFooterIngredient
          healthRiskItem={healthRiskItem}
          palette={palette}
          navigate={navigate}
          t={t}
        />
      )}
    </WidgetWrapper>
  );
};

const openIngredientDetails = (ingredientId, navigate) => {
  sendEvent(
    "expandAdditive",
    "ingredient",
    "Understand More",
    ingredientId,
    ingredientId,
    ingredientId
  );
  navigate(`/ingredient/${ingredientId}`);
};

const openNutrientDetails = (nutrientId, navigate) => {
  sendEvent(
    "expandAdditive",
    "nutrient",
    "Understand More",
    nutrientId,
    nutrientId,
    nutrientId
  );
  navigate(`/nutrient/${nutrientId}`);
};

const getNutrientText = (quantity, servingUnit) => {
  return quantity + "" + servingUnit;
};

export function RatingWidget({
  type,
  rate_code,
  tag_name,
  palette,
  isSugarCase = false,
  scales = [],
}) {
  const { t } = useTranslation();
  if (type == null || rate_code == null || tag_name == null) return <></>;
  return (
    <FlexBetween gap="0.375rem" sx={{ justifyContent: "flex-start" }}>
      {(type == "POSITIVE" || type == "GOOD" || type == "NEUTRAL") &&
      !isSugarCase ? (
        <CheckCircleIcon
          fontSize="small"
          sx={{
            color:
              scales?.length > 2
                ? getBorderColorNutrient(rate_code, palette)
                : getNutrientRatingColor(rate_code, palette),
          }}
        />
      ) : (
        <ErrorIcon
          fontSize="small"
          sx={{ color: getNutrientRatingColor(rate_code, palette) }}
        />
      )}
      <Typography
        variant="h12"
        color={
          scales?.length > 2
            ? getBorderColorNutrient(rate_code, palette)
            : getNutrientRatingColor(rate_code, palette)
        }
        fontWeight="600"
        sx={{ textTransform: "uppercase" }}
      >
        {getIngredientRatingText(tag_name.toUpperCase(), t)}
      </Typography>
    </FlexBetween>
  );
}

export function AccordionHeaderNutrient({
  healthRiskItem,
  palette,
  isActive,
  isSugarCase = false,
  percent = 0,
  t
}) {
  return (
    <FlexBetween width="100%" gap="1rem">
      <FlexBetween
        gap="1rem"
        sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
      >
        <img
          style={{ objectFit: "cover", marginTop: "0.125rem" }}
          width="18px"
          height="18px"
          alt="Nutrient Image"
          src={healthRiskItem?.icon}
        />
        <FlexBetweenVertical sx={{ alignItems: "flex-start" }}>
          <Typography variant="h16" color="#3D3C3D" fontWeight="600">
            {healthRiskItem?.name}
          </Typography>
          <Typography
            variant="h14"
            color={palette.textColor.greyLight}
            fontWeight="400"
          >
            {healthRiskItem?.sugar_index && !healthRiskItem?.sugar_percent && (
              <Typography>{t("productDetail.not_specified")}</Typography>
            )}
            {!isSugarCase &&
              getNutrientText(healthRiskItem?.amount, healthRiskItem?.unit)}
            {isSugarCase &&
              healthRiskItem?.sugar_index &&
              healthRiskItem?.sugar_percent &&
              getNutrientText(healthRiskItem?.amount, healthRiskItem?.unit) +
                " (" +
                percent +
                "%)"}
          </Typography>
        </FlexBetweenVertical>
      </FlexBetween>
      <FlexBetween gap="0.25rem" sx={{ justifyContent: "flex-start" }}>
        {!isSugarCase && (
          <RatingWidget
            type={healthRiskItem?.type}
            rate_code={healthRiskItem?.rate_code}
            tag_name={healthRiskItem?.tag_name}
            palette={palette}
            isSugarCase={isSugarCase}
            scales={healthRiskItem?.scales}
          />
        )}
        {isSugarCase && healthRiskItem?.sugar_percent && (
          <RatingWidget
            type={healthRiskItem?.type}
            rate_code={healthRiskItem?.rate_code}
            tag_name={healthRiskItem?.tag_name}
            palette={palette}
            isSugarCase={isSugarCase}
            scales={healthRiskItem?.scales}
          />
        )}

        {isSugarCase &&
          healthRiskItem?.sugar_index &&
          !healthRiskItem?.sugar_percent && (
            <RatingWidget
              type={healthRiskItem.type}
              rate_code={GetRateCode(healthRiskItem?.sugar_index)}
              tag_name={GetSugarTagName(healthRiskItem?.sugar_index)}
              palette={palette}
              isSugarCase={isSugarCase}
              scales={healthRiskItem?.scales}
            />
          )}

        {isActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </FlexBetween>
    </FlexBetween>
  );
}

export function AccordionHeaderIngredient({
  healthRiskItem,
  palette,
  isActive,
  ingredientCount,
  translation,
  isRiskView = true,
}) {
  let caution_count = 0;
  let highrisk_count = 0;
  let neutral_count = 0;
  for (let item in healthRiskItem) {
    // console.log("AccordionHeaderIngredient Item: ", item);
    if (healthRiskItem[item].rate_code == "CAUTION") {
      caution_count++;
    } else if (healthRiskItem[item].rate_code == "HIGH_RISK") {
      highrisk_count++;
    } else if (healthRiskItem[item].rate_code == "NEUTRAL") {
      neutral_count++;
    }
  }

  return (
    <FlexBetweenVertical
      width="100%"
      gap="0.25rem"
      sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
    >
      <FlexBetween width="100%">
        <FlexBetween
          gap="1rem"
          sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          {healthRiskItem[0].allergic ? (
            <IngredientIcon
              style={{ width: "18px", height: "18px", marginTop: "0.125rem" }}
            />
          ) : (
            <AdditiveIcon
              style={{ width: "18px", height: "18px", marginTop: "0.125rem" }}
            />
          )}
          <FlexBetweenVertical
            gap="0.25rem"
            sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
          >
            <Typography variant="h16" color="#3D3C3D" fontWeight="600">
              {/* Show Ingredienttitle */}
              {healthRiskItem[0].allergic
                ? translation("productDetail.ingredient_title")
                : translation("productDetail.additive_title")}
            </Typography>
            <Typography
              variant="h14"
              color={palette.textColor.greyLight}
              fontWeight="400"
            >
              {!healthRiskItem[0].allergic &&
                (isRiskView
                  ? ingredientCount +
                    " " +
                    translation("productDetail.harmful_ingredient_count")
                  : ingredientCount > 1
                  ? ingredientCount +
                    " " +
                    translation("productDetail.additives")
                  : ingredientCount +
                    " " +
                    translation("productDetail.additive"))}
              {isRiskView &&
                healthRiskItem[0].allergic &&
                `${ingredientCount} ${
                  ingredientCount === 1
                    ? translation("productDetail.allergen")
                    : translation("productDetail.allergens")
                }`}
            </Typography>
          </FlexBetweenVertical>
        </FlexBetween>
        <FlexBetween gap={"0.25rem"}>
          {!isRiskView && neutral_count > 0 && (
            <RatingWidget
              type="NEUTRAL"
              rate_code="NO_RISK"
              tag_name={translation("productDetail.no_risk")}
              palette={palette}
            />
          )}
          {isActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </FlexBetween>
      </FlexBetween>
      {isRiskView && !isActive && (highrisk_count > 0 || caution_count > 0) && (
        <FlexBetweenVertical
          mt="0.5rem"
          ml="2.125rem"
          gap="0.25rem"
          sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          {highrisk_count > 0 && (
            <RatingWidget
              type="HIGH_RISK"
              rate_code="HIGH_RISK"
              tag_name={
                highrisk_count +
                translation("productDetail.highrisk_additive_count")
              }
              palette={palette}
            />
          )}
          {caution_count > 0 && (
            <RatingWidget
              type="CAUTION"
              rate_code="CAUTION"
              tag_name={
                caution_count +
                translation("productDetail.caution_additive_count")
              }
              palette={palette}
            />
          )}
        </FlexBetweenVertical>
      )}
    </FlexBetweenVertical>
  );
}

function AccordionFooterNutrient({ healthRiskItem, palette, navigate, t }) {
  // const nutrient_size = healthRiskItem.product_nutrient_tags ? healthRiskItem.product_nutrient_tags.length : 0;
  // const ingredient_size = healthRiskItem.product_ingredient_tags ? healthRiskItem.product_ingredient_tags.length : 0;
  // const size = nutrient_size + ingredient_size;
  if (
    (healthRiskItem.name === "Đường" &&
      (healthRiskItem?.sugar_percent || healthRiskItem?.sugar_index)) ||
    (healthRiskItem.name === "Sugar" &&
      (healthRiskItem?.sugar_percent || healthRiskItem?.sugar_index))
  ) {
    return (
      <DescriptionSugerNotSpecific
        percent={healthRiskItem?.sugar_percent}
        sugar_index={healthRiskItem?.sugar_index}
        t={t}
      />
    );
  }
  return (
    <FlexBetweenVertical
      width="100%"
      pt="0.75rem"
      sx={{ gap: "0.75rem", alignItems: "flex-start" }}
    >
      {healthRiskItem.scales && healthRiskItem.scales.length > 0 && (
        <NutritionLineView
          scales={healthRiskItem.scales}
          markerValue={healthRiskItem.amount}
        />
      )}

      <Typography
        variant="h13"
        color={palette.textColor.greyLight}
        fontWeight="300"
      >
        {parse(healthRiskItem.short_description)}
      </Typography>

      <ButtonBase
        onClick={() => openNutrientDetails(healthRiskItem.id, navigate)}
      >
        <FlexBetween>
          <Typography
            variant="h13"
            color={palette.textColor.orangeDark}
            fontWeight="400"
          >
            {t("productDetail.understand_more")}
          </Typography>
          <ChevronRightIcon style={{ color: palette.textColor.orangeDark }} />
        </FlexBetween>
      </ButtonBase>
      {/* <DescriptionSugerNotSpecific /> */}
    </FlexBetweenVertical>
  );
}

function AccordionFooterIngredient({ healthRiskItem, palette, navigate, t }) {
  // const nutrient_size = healthRiskItem.product_nutrient_tags ? healthRiskItem.product_nutrient_tags.length : 0;
  // const ingredient_size = healthRiskItem.product_ingredient_tags ? healthRiskItem.product_ingredient_tags.length : 0;
  // const size = nutrient_size + ingredient_size;
  return (
    <FlexBetweenVertical
      width="100%"
      mt="0.75rem"
      gap="0.75rem"
      sx={{ alignItems: "flex-start" }}
    >
      {healthRiskItem.map((item, index) => {
        // console.log("item", item);
        return (
          <FlexBetweenVertical
            width="100%"
            sx={{
              gap: "0.75rem",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Divider
              display="flex"
              width="100%"
              style={{ background: "#EBEBF0" }}
            />
            <FlexBetweenVertical
              sx={{ alignItems: "flex-start" }}
              gap="0.25rem"
              width="100%"
            >
              <FlexBetween width="100%" gap="1rem">
                <Typography
                  variant="h14"
                  color={palette.textColor.dark}
                  fontWeight="400"
                >
                  {item.name}
                </Typography>

                <RatingWidget
                  type={item.allergic ? "ALLERGIC" : item.rate_code}
                  rate_code={item.allergic ? "ALLERGIC" : item.rate_code}
                  tag_name={item.allergic ? "ALLERGIC" : item.rate_code}
                  palette={palette}
                />
              </FlexBetween>

              {/* <FlexBetween sx={{ alignItems: "flex-start" }}> */}
              {(!isEmpty(item.code) || !isEmpty(item.usage)) && (
                <Typography
                  variant="h13"
                  fontWeight="400"
                  color={palette.textColor.greyLight}
                >
                  {item.code}&nbsp;&nbsp;
                  <font color="#EBEBF0">|</font>
                  &nbsp;&nbsp;{item.usage}
                </Typography>
              )}
              {/* <Typography variant="h13" fontWeight="400"color={palette.textColor.greyLight}>{item.usage}</Typography> */}
              {/* </FlexBetween> */}
            </FlexBetweenVertical>

            {!isEmpty(item.short_description) && (
              <Typography
                variant="h13"
                color={palette.textColor.greyLight}
                fontWeight="300"
              >
                {parse(item.short_description)}
              </Typography>
            )}

            {!item.allergic && (
              <ButtonBase
                onClick={() => openIngredientDetails(item.id, navigate)}
              >
                <FlexBetween>
                  <Typography
                    variant="h13"
                    color={palette.textColor.orangeDark}
                    fontWeight="400"
                  >
                    {t("productDetail.understand_more")}
                  </Typography>
                  <ChevronRightIcon
                    style={{ color: palette.textColor.orangeDark }}
                  />
                </FlexBetween>
              </ButtonBase>
            )}
          </FlexBetweenVertical>
        );
      })}
    </FlexBetweenVertical>
  );
}

function NutrientsListView({ palette, data }) {
  // console.log(data);
  if (data == null) return;
  return (
    <Box m="0.5rem">
      {data.map((item, index) => {
        return (
          <FlexBetween>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Box
                width="0.25rem"
                height="0.25rem"
                backgroundColor={palette.textColor.greyLight}
                borderRadius="0.25rem"
              />
              <Typography
                variant="h14"
                color={palette.textColor.greyLight}
                fontWeight="400"
              >
                {item.name}
              </Typography>
            </Box>
            <Typography
              variant="h14"
              color={palette.textColor.greyLight}
              fontWeight="400"
            >
              {item.value}
            </Typography>
          </FlexBetween>
        );
      })}
    </Box>
  );
}

function sendEvent(
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  eventId
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
      id: eventId,
    },
  });
}

export default CustomRisksAccordion;
