import React, { useEffect, useState } from "react";
import { IconButton, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import DehazeIcon from "@mui/icons-material/Dehaze";
import MenuWidget from "scenes/widgets/MenuWidget";
import { AddToHomeScreen, HelpOutline } from "@mui/icons-material";
import DialogWidget from "scenes/widgets/DialogWidget";
import FaqWidgetBottom from "scenes/widgets/FaqWidgetBottom";
import HealthConditionsWidget from "scenes/widgets/HealthConditionsWidget";
import { useDispatch, useSelector } from "react-redux";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import Share from "components/share/Share";
import AddToHomeScreenWidget from "scenes/widgets/AddToHomeScreenWidget";
import { isMobile } from "react-device-detect";
import CategorySelectionBottomSheet from "../discoveryPage/CategorySelectionBottomSheet";
import FAQIconImage from "./../../../assets/faq_icon.svg";
import FilterIconImage from "./../../../assets/slider_icon.svg";
import WidgetIconImage from "./../../../assets/widget_icon.svg";
// import { RWebShare } from "react-web-share";

const FaqMenuGroupButton = (props) => {
  const { showShareIcon, shareData, changeBottom, offDehazeIcon } = props;
  const nameWidget = [
    "openMenu",
    "openHealth",
    "openFaq",
    "openAddToHome",
    "openCategoryListing",
  ];
  const { palette } = useTheme();
  const textMainHeading = palette.textColor.mainHeading;
  const { openFaq, openHealth, openMenu, openAddToHome, openCategoryListing } =
    useSelector((state) => state.bottomSheet.openBottomSheet);

  const [payload, setPayload] = useState({
    name: "",
    isOpen: false,
  });
  const [menuAction, setMenuAction] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload, changeBottom]);

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };
  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  return (
    <>
      <FlexBetween gap="1.5rem" position={"relative"} marginRight={"0.3rem"}>
        <FlexBetween gap="0.5rem">
          {
            <IconButton onClick={() => handleClickOpen(nameWidget[1])}>
              <img src={FilterIconImage} alt="health-icon" />
            </IconButton>
          }
          {showShareIcon && shareData && isMobile && (
            <Share shareData={shareData}>
              <IconButton
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "shareClick",
                    eventProps: {
                      category: shareData.type,
                      action: "share",
                      label: shareData.text, //Name of product or ingredient
                      value: shareData.text,
                      id: shareData.url.substring(
                        shareData.url.lastIndexOf("/") + 1
                      ),
                    },
                  });
                }}
              >
                <ShareIcon style={{ color: textMainHeading }} />
              </IconButton>
            </Share>
          )}
          <IconButton onClick={() => handleClickOpen(nameWidget[2])}>
            <img src={FAQIconImage} alt="faq-icon" />
          </IconButton>
          {!offDehazeIcon && (
            <IconButton onClick={() => handleClickOpen(nameWidget[0])}>
              <img src={WidgetIconImage} alt="wid-icon" />
            </IconButton>
          )}
        </FlexBetween>
      </FlexBetween>
      <DialogWidget
        open={openMenu}
        onClose={() => handleClose(nameWidget[0])}
        dialogContent={
          <MenuWidget
            onClose={() => handleClose(nameWidget[0])}
            setMenuAction={setMenuAction}
            menuAction={menuAction}
            showNote={false}
          />
        }
      />
      <DialogWidget
        open={openFaq}
        onClose={() => handleClose(nameWidget[2])}
        dialogContent={
          <FaqWidgetBottom onClose={() => handleClose(nameWidget[2])} />
        }
      />
      <DialogWidget
        open={openHealth}
        onClose={() => handleClose(nameWidget[1])}
        dialogContent={
          <HealthConditionsWidget onClose={() => handleClose(nameWidget[1])} />
        }
      />
      <DialogWidget
        open={openAddToHome}
        onClose={() => handleClose(nameWidget[3])}
        dialogContent={
          <AddToHomeScreenWidget onClose={() => handleClose(nameWidget[3])} />
        }
      />
      <DialogWidget
        open={openCategoryListing}
        onClose={() => handleClose(nameWidget[4])}
        dialogContent={
          <CategorySelectionBottomSheet
            onClose={() => handleClose(nameWidget[4])}
          />
        }
      />
    </>
  );
};

export default FaqMenuGroupButton;
