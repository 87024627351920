import { Box, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ScanIconImage from "../../assets/scan.svg";
import { ReactComponent as SearchArrowIcon } from "../../assets/searcharrow.svg";
import { ReactComponent as ScanArrowIcon } from "../../assets/scanarrow.svg";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";
import NavbarScanImage from "../../assets/navbar_scan.svg";

const SearchScanWidget = (props) => {
  const { t, i18n } = useTranslation();
  const {
    showPlaceHolder,
    isDesktop,
    showNote = false,
    showShadow = true,
    isHistoryEmpty = false,
    isNavbar = false,
    isShowScan = false,
  } = props;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const textMainHeading = palette.textColor.mainHeading;
  const textGreyLight = palette.textColor.greyLight;
  const textOrangeLight = palette.textColor.orangeLight;
  const textOrangeDark = palette.textColor.orangeDark;
  const backgrountWhite = palette.background.alt;
  const colorSearch = showPlaceHolder ? textMainHeading : backgrountWhite;
  const bgSearch = showPlaceHolder ? backgrountWhite : textOrangeDark;
  const pdSearch = showPlaceHolder
    ? "1rem 1.25rem 1rem 1.25rem"
    : "0.75rem 1rem 0.75rem 1rem";
  const colorSearchIcon = !showPlaceHolder ? backgrountWhite : textOrangeDark;
  const styleNoneNavbar =
    isNavbar && isDesktop
      ? {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
        }
      : {
          width: "100%",
          // display: "grid",
          flexDirection: "column",
          // gridTemplateColumns: "repeat(6,auto)",
          justifyContent: "space-between",
          gap: showNote ? "0rem" : "1rem",
        };
  return (
    <Box mt="1rem">
      {showNote ? (
        <>
          <Typography
            variant="h13FuzzyBubbles"
            ml="2.25rem"
            color={textOrangeLight}
            fontWeight="700"
          >
            {t("home.searchscan_tryitout")}
          </Typography>
          <FlexBetween mt="-0.5rem">
            <Box
              ml="1.25rem"
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <SearchArrowIcon />
            </Box>
            {/* <Typography
              mr="1rem"
              variant="h13FuzzyBubbles"
              mt="-0.5rem"
              color={textOrangeLight}
              fontWeight="700"
            >
              {t("home.searchscan_scanbarcode")}
            </Typography> */}
          </FlexBetween>
          {/* <Box display="flex" justifyContent="right" mr="1.75rem" mt="-0.25rem">
            <ScanArrowIcon />
          </Box> */}
        </>
      ) : (
        <></>
      )}
      <Box
        sx={styleNoneNavbar}
        // onClick={() => navigate(`/profile/${userId}`)}
      >
        <FlexBetween
          id="search_btn"
          gap="0.5rem"
          sx={{
            border: 2,
            backgroundColor: bgSearch,
            borderColor: colorSearch,
            justifyContent: "flex-start",
            borderRadius: "1.125rem",
            padding: pdSearch,
            cursor: "pointer",
            boxShadow: showShadow ? "2px 4px 10px 0px rgb(0 0 0 / 15%)" : "0",
            gridColumn: showNote ? "1/ span 5" : "1/ span 3",
          }}
          onClick={() => {
            navigate(`/search`);
          }}
        >
          <SearchIcon
            style={{
              color: colorSearchIcon,
              cursor: "pointer",
              fontSize: "1.75rem",
            }}
          />
          {showPlaceHolder ? (
            <FlexBetweenVertical sx={{ alignItems: "flex-start" }}>
              <Typography variant="h17" color={colorSearch} fontWeight="700">
                {t("home.searchscan_searchproduct")}
              </Typography>
              <Typography mt="0.25rem" color={textGreyLight}>
                {t("home.enter_product_name")}
              </Typography>
            </FlexBetweenVertical>
          ) : (
            <Typography variant="h15" color={colorSearch} fontWeight="700">
              {isHistoryEmpty
                ? t("history.search_product")
                : t("home.searchscan_searchproduct")}
            </Typography>
          )}
        </FlexBetween>

        {/* {showNote ? (
          <IconButton
            id="scan_btn"
            onClick={() => navigate(`/scan`)}
            text={t("menu.scan")}
            sx={{ cursor: "pointer", width: "fit-content" }}
          >
            <img src={ScanIconImage} alt="scan-icon" />
          </IconButton>
        ) : (
          <FlexBetween
            id="scan_btn"
            gap="0.5rem"
            width="100%"
            style={{
              border: 1.5,
              borderStyle: "solid",
              borderColor: textMainHeading,
              justifyContent: "center",
              borderRadius: "1.125rem",
              padding: "0.625rem 1rem",
              cursor: "pointer",
              gridColumn: showNote ? "5/ span 1" : "4/ span 3",
            }}
            onClick={() => navigate(`/scan`)}
          >
            <img src={NavbarScanImage} alt="scan-icon" />

            <Typography
              variant="h15"
              color={textMainHeading}
              fontWeight="700"
              style={{ textTransform: "none" }}
            >
              {t("menu.scan")}
            </Typography>
          </FlexBetween>
        )} */}

        {/* <ManageAccountsOutlined /> */}
      </Box>
    </Box>
  );
};

export default SearchScanWidget;
