import { Box, Typography } from "@mui/material";
import React from "react";

const HeaderWiseGPT = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "12px",
        flexDirection: "column",
        paddingX: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: "900",
            color: "#191B1F",
            lineHeight: "45px",
          }}
        >
          wise
        </Typography>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: "900",
            color: "#FF751E",
            lineHeight: "45px",
          }}
        >
          gpt
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          color: "#32363D",
          lineHeight: "20px",
          paddingX: "8px",
        }}
      >
        Your health and nutrition sidekick! Dive into our lively LLM chat model for quick answers to your burning questions - we've got the insights you need. Stay curious, stay healthy!
      </Typography>
    </Box>
  );
};

export default HeaderWiseGPT;
