import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { ReactComponent as CloseIcon } from "./../../assets/close_icon.svg";
import { Divider } from "@mui/material";

const HeaderBottomSheet = (props) => {
  const { name, icon, close, isDivider = true ,paddingTop = "20px" } = props;
  const { palette } = useTheme();
  const textMainHeading = palette.textColor.mainHeading;
  return (
    <Box
      sx={{
        marginBottom: "20px",
        backgroundColor: "white",
        paddingTop: paddingTop,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FlexBetween alignItems={"center"}>
        <FlexBetween
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <IconButton sx={{ minHeight: 0, minWidth: 0, padding: 0 }}>
            {icon}
          </IconButton>
          <Typography
            fontWeight="900"
            color={textMainHeading}
            variant="h20"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {name}
          </Typography>
        </FlexBetween>
        <IconButton
          onClick={() => close()}
          sx={{ minHeight: 0, minWidth: 0, padding: 1 }}
        >
          <CloseIcon />
        </IconButton>
      </FlexBetween>
      {isDivider && (
        <Divider
          display="flex"
          width="112.5%"
          style={{ background: "#EBEBF0", margin: "1rem 0rem 0rem -1.5rem" }}
        />
      )}
    </Box>
  );
};

export default HeaderBottomSheet;
