import { createSlice } from "@reduxjs/toolkit";
import localStorage from "redux-persist/es/storage";

const initialState = {
  newUpdateHistory: false,
  historyLocal: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    saveHistoryToLocal: (state, action) => {
      const lengthData = action.payload?.length;
      const size = 5;
      let dataStored;
      if (lengthData <= size) {
        dataStored = action.payload;
      } else {
        dataStored = action.payload?.slice(0, size);
      }
      if (action.payload) {
        localStorage.setItem("historyData", JSON.stringify(dataStored));
      }
      state.historyLocal = dataStored;
    },
    addNewHistoryToLocal: (state, action) => {
      state.newUpdateHistory = !state.newUpdateHistory;
      localStorage.setItem("newProductSearch", JSON.stringify(action.payload));
    },
    updateHistory: (state, action) => {
      state.historyLocal = action.payload;
      localStorage.setItem("historyData", JSON.stringify(action.payload));
    },
  },
});

export const { saveHistoryToLocal, addNewHistoryToLocal, updateHistory } =
  searchSlice.actions;
export default searchSlice.reducer;
