
// import Icon from '@mui/icons-material';

import { ReactComponent as LogoIcon } from '../assets/logo.svg';


const Logo = () => {
return (
    <LogoIcon />
)
}

export default Logo

