import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FlexBetween from "components/FlexBetween";
import { useTranslation } from "react-i18next";
import FlexBetweenVertical from "components/FlexBetweenVertical";

const ExpandableListTextView = ({ dataItems, minShowCount = 10 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dataItemsCount = dataItems.length;
  const [showCount, setShowCount] = useState(Math.min(minShowCount, dataItems.length));
  const { palette } = useTheme();
  const { t } = useTranslation();
  const textOrangeDark = palette.textColor.orangeDark;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if(dataItemsCount > minShowCount){
        if(showCount > minShowCount)
            setShowCount(minShowCount);
        else
            setShowCount(dataItemsCount);
    }
  };

  return (
    <Box className="expandable-text-view" display="flex" flexDirection="column" width="100%">
        
        <FlexBetweenVertical sx={{alignItems:"flex-start"}}>
            {(dataItems?.slice(0, showCount).map((value) => (
                <Typography variant="h14" fontWeight="300" color={palette.textColor.mainHeading} key={value} style={{ marginLeft: "1rem" }}>
                    <CircleIcon
                    style={{
                        width: "5px",
                        height: "5px",
                        marginRight: "0.5rem",
                    }}
                    />
                    {value}
                </Typography>
                )))
            }
        </FlexBetweenVertical>
        {dataItemsCount > 10 &&
            <Box mt="0.75rem" sx={{alignItems:"center"}} >
                <Divider ml="-0.75rem" mr="-0.75rem" display="flex" mt="0.75rem" width="100%" />
                <FlexBetween mt="0.75rem" sx={{ justifyContent:"center"}} onClick={toggleExpand}>
                    <Typography variant="h12" fontWeight="700" color={textOrangeDark}>
                        {isExpanded ? t("common.see_less") : t("common.see_more")}
                    </Typography>
                    {isExpanded ? <ExpandLessIcon style={{ color: "#FF751E"}}/> : <ExpandMoreIcon style={{ color: "#FF751E"}} />}
                </FlexBetween>
            </Box>
        }
    </Box>
  );
};

export default ExpandableListTextView;
