import {
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";

import FlexBetweenVertical from "components/FlexBetweenVertical";
import WidgetWrapper from "components/WidgetWrapper";
import { useTranslation } from "react-i18next";
import HeaderBottomSheet from "scenes/widgets/HeaderBottomSheet";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPageInit,
  resetProductsListBiteBoard,
  setSelectedProductCategory,
} from "features/bottomSheet/bottomSheetSlice";

const CategorySelectionBottomSheet = (props) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const backgrountWhite = palette.background.alt;
  const { onClose } = props;
  const { productCategoryList, selectedProductCategory } = useSelector(
    (state) => state.bottomSheet
  );

  function BpRadio(props) {
    return <Radio disableRipple {...props} />;
  }
  const categoryContainerRef = useRef(null);
  const autoScrollToSelectedCategory = useCallback(() => {
    const container = categoryContainerRef.current;
    if (selectedProductCategory && container) {
      const selectedCategoryElement = container.querySelector(
        `[data-category-id-bottom="${selectedProductCategory.id}"]`
      );

      if (selectedCategoryElement) {
        container.scrollTo({
          top: selectedCategoryElement.offsetTop - container.offsetTop,
          behavior: "instant",
        });
      }
    }
  }, [selectedProductCategory, selectedProductCategory.id]);

  useEffect(() => {
    autoScrollToSelectedCategory();
  }, [
    selectedProductCategory,
    selectedProductCategory.id,
    autoScrollToSelectedCategory,
  ]);

  function handleChange(event) {
    const selectedCategoryId = Number(event.target.value);
    dispatch(setSelectedProductCategory(selectedCategoryId));
    dispatch(resetPageInit());
    dispatch(resetProductsListBiteBoard());
    onClose();
  }

  return (
    <WidgetWrapper
      width="100%"
      height="100%"
      sx={{
        borderRadius: "1rem",
        background: backgrountWhite,
        padding: "0",
        boxShadow: "none",
      }}
    >
      <HeaderBottomSheet
        name={t("discovery.discovery_bottomsheet_title")}
        close={onClose}
      />

      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedProductCategory.id}
        onChange={handleChange}
        ref={categoryContainerRef}
        sx={{
          maxHeight: "600px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 10,
            background: "rgba(0, 0, 0, 0)",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(0, 0, 0, 0)",
            borderRadius: 10,
          },
        }}
      >
        <FlexBetweenVertical
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            gap: "0.5rem",
          }}
        >
          {productCategoryList &&
            productCategoryList.map((item, index) => {
              return (
                <FlexBetweenVertical
                  key={index}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: "0.5rem",
                  }}
                >
                  <FormControlLabel
                    value={Number(item.id)}
                    control={
                      <Radio
                        data-category-id-bottom={item.id}
                        sx={{
                          padding: 0,
                          marginX: "1rem",
                          marginY: "0.5rem",
                          color: "#DDDDE3",
                          "&.Mui-checked": {
                            color: "#ff751e",
                          },
                        }}
                      />
                    }
                    label={item.name}
                  />
                  <Divider
                    display="flex"
                    width="100%"
                    style={{ background: "#EBEBF0" }}
                  />
                </FlexBetweenVertical>
              );
            })}
        </FlexBetweenVertical>
      </RadioGroup>
    </WidgetWrapper>
  );
};

export default CategorySelectionBottomSheet;
