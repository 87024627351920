import { Box, Button } from "@mui/material";
import React from "react";
import MenuWidget from "scenes/widgets/MenuWidget";
import { useEffect, useState } from "react";
import DialogWidget from "scenes/widgets/DialogWidget";
import FaqWidgetBottom from "scenes/widgets/FaqWidgetBottom";
import HealthConditionsWidget from "scenes/widgets/HealthConditionsWidget";
import { useDispatch, useSelector } from "react-redux";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import Logo from "components/Logo";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useNavigate } from "react-router-dom";
import AddToHomeScreenWidget from "scenes/widgets/AddToHomeScreenWidget";
import CategorySelectionBottomSheet from "../discoveryPage/CategorySelectionBottomSheet";

const NavBarDesktop = (props) => {
  const { changeBottom } = props;
  const nameWidget = ["openMenu", "openHealth", "openFaq", "openAddToHome", "openCategoryListing"];
  const { openFaq, openHealth, openAddToHome, openCategoryListing } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );

  const [payload, setPayload] = useState({
    name: "",
    isOpen: false,
  });
  const [menuAction, setMenuAction] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload, changeBottom]);

  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  return (
    <Box
      id="navbar_desktop"
      sx={{
        width: "300px",
        minHeight: "100vh",
        position: "fixed",
        background: "white",
      }}
    >
      <FlexBetweenVertical
        sx={{
          padding: "2rem 1rem 1rem 1rem",
          justifyContent: "flex-start",
          alignItems: "start",
          gap: "3rem",
        }}
      >
        <Button onClick={() => navigate("/")}>
          <Logo />
        </Button>
        <MenuWidget
          onClose={() => handleClose(nameWidget[0])}
          setMenuAction={setMenuAction}
          menuAction={menuAction}
        />
        <DialogWidget
          open={openFaq}
          onClose={() => handleClose(nameWidget[2])}
          dialogContent={
            <FaqWidgetBottom onClose={() => handleClose(nameWidget[2])} />
          }
        />
        <DialogWidget
          open={openHealth}
          onClose={() => handleClose(nameWidget[1])}
          dialogContent={
            <HealthConditionsWidget
              onClose={() => handleClose(nameWidget[1])}
            />
          }
        />
        <DialogWidget
          open={openAddToHome}
          onClose={() => handleClose(nameWidget[3])}
          dialogContent={
            <AddToHomeScreenWidget onClose={() => handleClose(nameWidget[3])} />
          }
        />
        <DialogWidget
          open={openCategoryListing}
          onClose={() => handleClose(nameWidget[4])}
          dialogContent={
            <CategorySelectionBottomSheet onClose={() => handleClose(nameWidget[4])} />
          }
        />
      </FlexBetweenVertical>
    </Box>
  );
};

export default NavBarDesktop;
