import { Box, Typography } from "@mui/material";
import React from "react";

const QuestionInBoxChat = ({
  question = " Hi, what is the difference between carb and sugar could you please explain?",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "11px",
          color: "#7D8899",
          lineHeight: "1.5rem",
          letterSpacing: "10%",
          textAlign: "end",
          textTransform: "uppercase",
        }}
      >
        Question
      </Typography>
      <Box
        sx={{
          border: "1px solid #D8DBE0",
          padding: "1rem",
          borderRadius: "0.75rem",
          width : "280px"
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "1rem",
            color: "#191B1F",
            lineHeight: "22px",
          }}
        >
          {question}
        </Typography>
      </Box>
    </Box>
  );
};

export default QuestionInBoxChat;
