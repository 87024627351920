import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Chip,
  Paper,
  styled,
  Typography,
  Button,
  Avatar,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  resetPageInit,
  resetProductsListBiteBoard,
  setSelectedProductCategory,
  setSelectedProductSubCategory,
  setWidget,
} from "features/bottomSheet/bottomSheetSlice";
import { useTranslation } from "react-i18next";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useNavigate } from "react-router-dom";
import { getImageCategory } from "utils/commonutils";

export const StyledChip = styled(Chip)(({ theme, isActive, isHomePage }) => ({
  color: isActive ? "#FF751E" : "#27272A",
  fontSize: 14,
  backgroundColor: isActive ? "white" : "transparent",
  borderColor: isActive ? "#FF751E" : "#DDDDE3",
  borderWidth: isHomePage ? "1px" : 0,
  borderStyle: isHomePage ? "solid" : "none",
  borderRadius: isHomePage ? "16px" : 0,
  fontWeight: 400,
  paddingX: "0px !important",
  paddingBottom: !isHomePage ? "6px" : 0,
  height: isHomePage ? 103 : "100%",
  width: isHomePage ? "100%" : 79,
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    height: !isHomePage && isActive ? "3px" : 0,
    width: !isHomePage && isActive ? "28px" : 0,
    backgroundColor: "#FF751E",
  },

  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#FF751E",
  },
  "&:focus": {
    borderColor: "#FF751E",
  },
  "&:active": {
    borderColor: "#FF751E",
  },
  "& .MuiChip-label": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function sendEvent(
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    },
  });
}

const CategoriesListHorizontalWidget = ({
  categoriesList,
  isHomePage = false,
  isSearchPage = false,
}) => {
  const navigate = useNavigate();
  const [scrollLeft, setScrollLeft] = useState(0);
  const { selectedProductCategory } = useSelector((state) => state.bottomSheet);
  const categoryContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const itemMore = {
    id: -1,
    name: t("discovery.view_more"),
    product_count: 0,
    visibility: 1,
    image: "",
  };

  //   console.log("Received CategoryList "+ categoriesList);
  categoriesList = isHomePage
    ? [...categoriesList.slice(0, 8), itemMore]
    : categoriesList.slice().sort((a, b) => b.product_count - a.product_count);

  const handleScroll = (event) => {
    setScrollLeft(event.target.scrollLeft);
  };

  const openCategorySelectionBottomSheet = () => {
    if (isHomePage)
      sendEvent("categoryList", "homePage", "openBottomSheet", "");
    else if (isSearchPage)
      sendEvent("categoryList", "searchPage", "openBottomSheet", "");
    else sendEvent("categoryList", "discoveryPage", "openBottomSheet", "");

    dispatch(
      setWidget({
        name: "openCategoryListing",
        isOpen: true,
      })
    );
  };

  const onClickSelectCategory = (categoryItem) => {
    if (categoryItem.id === -1) {
      navigate("/biteboard");
    }
    if (
      selectedProductCategory.id !== categoryItem.id &&
      categoryItem.id !== -1 &&
      categoryItem
    ) {
      dispatch(resetProductsListBiteBoard());
      dispatch(setSelectedProductCategory(categoryItem));
      if (categoryItem?.subcategories) {
        dispatch(
          setSelectedProductSubCategory(
            categoryItem?.subcategories[0] || {
              id: -1,
            }
          )
        );
        dispatch(resetPageInit());
      }
    }
    if (isHomePage) {
      sendEvent("categoryList", "homePage", "clickCategory", categoryItem.name);
      navigate("/biteboard");
    } else if (isSearchPage)
      sendEvent(
        "categoryList",
        "searchPage",
        "clickCategory",
        categoryItem.name
      );
    else
      sendEvent(
        "categoryList",
        "discoveryPage",
        "clickCategory",
        categoryItem.name
      );
  };

  useEffect(() => {
    const container = categoryContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    // Scroll to the selected category when it changes
    if (selectedProductCategory && container) {
      const selectedCategoryElement = container.querySelector(
        `[data-category-id="${selectedProductCategory.id}"]`
      );

      if (selectedCategoryElement) {
        container.scrollTo({
          left: selectedCategoryElement.offsetLeft - container.offsetLeft - 5,
          behavior: "instant",
        });
      }
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedProductCategory]);

  useEffect(() => {
    // console.log("categoriesListarsy "+categoriesList);

    if (
      selectedProductCategory.id == -1 &&
      categoriesList &&
      categoriesList.length > 0
    ) {
      dispatch(setSelectedProductCategory(categoriesList[0]));
      if (categoriesList[0]?.subcategories) {
        setSelectedProductSubCategory(
          categoriesList[0]?.subcategories[0] || {
            id: -1,
          }
        );
      }
    }
  }, [categoriesList]);

  return (
    <Box
      width="100%"
      sx={{
        display: isHomePage ? "block" : "flex",
        paddingRight: isHomePage ? "2rem" : 0,
      }}
    >
      <Paper
        sx={{
          display: isHomePage ? "block" : "flex",
          boxShadow: "none",
          maxWidth: "100%",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            display: "none",
          },
        }}
        ref={categoryContainerRef}
      >
        <Box
          sx={{
            display: isHomePage ? "grid" : "flex",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "0.5rem",
            paddingX: 0,
          }}
        >
          {categoriesList?.map((categoryItem, index) => {
            return (
              <StyledChip
                key={index}
                className="chip-category"
                label={
                  <FlexBetweenVertical>
                    <Box
                      sx={{
                        height: 48,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingX: 0,
                      }}
                    >
                      <img
                        src={getImageCategory(categoryItem.id)}
                        alt="test"
                        style={{
                          width: categoryItem.id !== -1 ? 48 : 12,
                          height: categoryItem.id !== -1 ? 48 : 12,
                        }}
                      />
                    </Box>

                    <Typography
                      variant="h12"
                      color={
                        !isHomePage &&
                        categoryItem.id === selectedProductCategory.id
                          ? "#FF751E"
                          : "#27272A"
                      }
                      className="category_name"
                      sx={{
                        display: "-webkit-box", // Use webkit box
                        WebkitBoxOrient: "vertical", // Orient vertically
                        WebkitLineClamp: 2, // Clamp to 2 lines
                        overflow: "hidden", // Ensure content is hidden when clamped
                        textOverflow: "ellipsis",
                        maxHeight: "36px",
                        textAlign: "center",
                        maxWidth: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      {categoryItem.name}
                    </Typography>
                  </FlexBetweenVertical>
                }
                onClick={() => onClickSelectCategory(categoryItem)}
                isActive={
                  categoryItem.id === selectedProductCategory.id && !isHomePage
                }
                data-category-id={categoryItem.id} // Add data attribute for selecting the element later
                isHomePage={isHomePage}
              />
            );
          })}
        </Box>
      </Paper>
    </Box>
  );
};

export default CategoriesListHorizontalWidget;
