import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import CustomRisksAccordion from "./CustomRisksAccordion";

const RisksPositivesWidget = ({
  isRiskView,
  name,
  inquantity,
  servingUnit,
  healthItems = [],
  translation,
  hideNutrients = false,
}) => {
  const { palette } = useTheme();
  const servingSize = getServingSizeHeaderText(inquantity, servingUnit);
  // console.log("ser", servingSize);
  // console.log("inq", inquantity);
  // console.log("unit", servingUnit);

  if (
    !healthItems ||
    ((!healthItems.nutrients || healthItems.nutrients.length == 0) &&
      (!healthItems.ingredients || healthItems.ingredients.length == 0)) &&
      (!healthItems.additives || healthItems.additives.length == 0))
   {
    return (
      <EmptyView
        isRiskView={isRiskView}
        name={name}
        inquantity={servingSize}
        palette={palette}
        trans={translation}
      />
    );
  }
  return (
    <Box gap="0.75rem" width="100%">
      <TitleHeader
        name={name}
        inquantity={servingSize}
        palette={palette}
        trans={translation}
      />
      <Box sx={{ gap: "0.75rem" }}>
        {healthItems.ingredients && healthItems.ingredients.length > 0 && (
          <CustomRisksAccordion
            healthRiskItem={healthItems.ingredients}
            isIngredientAccordian={true}
            isRiskView={isRiskView}
          />
        )}
        {healthItems.additives && healthItems.additives.length > 0 && (
          <CustomRisksAccordion
            healthRiskItem={healthItems.additives}
            isIngredientAccordian={true}
            isRiskView={isRiskView}
          />
        )}
        {!hideNutrients &&
          healthItems.nutrients.map((data, index) => {
            return <CustomRisksAccordion key={index} healthRiskItem={data} />;
          })}
      </Box>
    </Box>
  );
};

const getServingSizeHeaderText = (inquantity, servingUnit) => {
  // return inquantity + servingUnit;
  switch (servingUnit) {
    case "kg":
      return inquantity + "kg/" + inquantity + "l";
    case "g":
      return inquantity + "g/" + inquantity + "ml";
    case "mg":
      return inquantity + " mg";
    case "ml":
      return inquantity + "g/" + inquantity + "ml";
    case "l":
      return inquantity + "kg/" + inquantity + "l";
    default:
      return inquantity + "g/" + inquantity + "ml";
  }
};

function EmptyView({ isRiskView, name, inquantity, palette, trans }) {
  return (
    <Box gap="0.75rem" mt="1.875rem" width="100%">
      <TitleHeader
        name={name}
        inquantity={inquantity}
        palette={palette}
        trans={trans}
      />
      <Box
        gap="0.75rem"
        mt="0.75rem"
        pt="0.25rem"
        pb="0.25rem"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        sx={{
          border: 1.5,
          borderColor: palette.borderColor.grey,
          borderRadius: "0.5rem",
          borderStyle: "dashed",
        }}
      >
        <Typography
          variant="h14"
          color={palette.textColor.mainHeading}
          fontWeight="600"
        >
          {trans("productDetail.negatives_empty")}
        </Typography>
      </Box>
    </Box>
  );
}

function TitleHeader({ name, inquantity, palette, trans }) {
  return (
    <FlexBetween>
      <Typography
        variant="h20"
        color={palette.textColor.greyLight}
        fontWeight="900"
      >
        {name}
      </Typography>
      <Typography
        variant="h12"
        color={palette.textColor.greyLight}
        fontWeight={"500"}
        letterSpacing={"0.6px"}
        textAlign={"right"}
      >
        {trans("productDetail.per")} {inquantity}
      </Typography>
    </FlexBetween>
  );
}

export default RisksPositivesWidget;
