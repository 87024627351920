import { useMediaQuery } from "@mui/material";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import MostAskedQuestions from "components/MostAskedQuestions";
import HeaderWiseGPT from "components/HeaderWiseGPT";
import Navbar from "../navbar";
import SearchWiseGPT from "components/SearchWiseGPT";
import QuestionAndAnswer from "scenes/widgets/QuestionAndAnswer";
import { useGetWiseGPTMutation } from "app/services/auth/authService";

const WiseGPTPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [listQuestion, setListQuestion] = useState([]);
  const [question, setQuestion] = useState("");
  const [getWiseGPTAnswer] = useGetWiseGPTMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const askedQuestionsRef = useRef(null);

  useEffect(() => {
    const fetchAnswer = async () => {
      if (!question) return;
      setIsLoading(true);
      try {
        const data = await getWiseGPTAnswer({ message: question }).unwrap();
        if (data && data.wisegpt_response) {
          const updatedListQuestion = [
            { question, answer: data.wisegpt_response },
          ];
          setListQuestion(updatedListQuestion);
        }
        setQuestion("");
        sessionStorage.removeItem("chosenQuestion");
      } catch (error) {
        console.error("Error fetching answer:", error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnswer();
  }, [question, getWiseGPTAnswer]);

  const navBar = isNonMobileScreens ? <NavBarDesktop /> : <Navbar />;

  useEffect(() => {
    const storedQuestion = sessionStorage.getItem("chosenQuestion");
    if (storedQuestion) {
      setQuestion(JSON.parse(storedQuestion));
    }
  }, [setQuestion]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Helmet title={`Wisegpt | Bitewise`} />
      {navBar}
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobileScreens ? "row" : "column"}
        gap="0.5rem"
        justifyContent="space-between"
        sx={{
          backgroundColor: "white",
          height: "100%",
          flex: 1,
        }}
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          width={isNonMobileScreens ? "30%" : undefined}
          sx={{
            height: "100%",
            flex: isNonMobileScreens ? "initial" : 1,
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              padding: "2rem 1.5rem 1.5rem 1.5rem",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "white",
            }}
          >
            <HeaderWiseGPT />
            {!isLoading && listQuestion ? (
              <QuestionAndAnswer listQuestionsAndAnswer={listQuestion} />
            ) : (
              <></>
            )}
            {isLoading | error ? (
              <QuestionAndAnswer
                listQuestionsAndAnswer={[{ question, answer: "" }]}
                isLoading={isLoading}
                isAsking={true}
                defaultIndex={listQuestion?.length}
                error={error}
              />
            ) : (
              <></>
            )}
            {!isLoading && !error && (
              <MostAskedQuestions
                setQuestion={setQuestion}
                hasQuestion={listQuestion.length > 0}
              />
            )}

            <Box
              ref={askedQuestionsRef}
              sx={{
                height: "150px",
              }}
            ></Box>
          </Box>
          <SearchWiseGPT
            showPlaceHolder={true}
            showNote={false}
            setQuestion={setQuestion}
            value={question}
            isLoading={isLoading}
          />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WiseGPTPage;
