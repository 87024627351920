import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GenericNavBar from "../navbar/GenericNavBar";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { useTranslation } from "react-i18next";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import SubCategoriesListHorizontalWidget from "scenes/widgets/SubCategoriesListHorizontalWidget";
import { Virtuoso } from "react-virtuoso";
import { useEffect, useState } from "react";
import {
  useGetAllBiteboardCategoriesQuery,
  useGetAllBiteboardProductsforCategoryQuery,
  useGetProfileQuery,
} from "app/services/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageInit,
  resetPageInit,
  resetProductsListBiteBoard,
  setCountProductBiteBoard,
  setIsRefetchSearch,
  setProductCategoryList,
  setWidget,
  updateProductsListBiteBoard,
} from "features/bottomSheet/bottomSheetSlice";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import { debugLog, sortDataByCategory } from "utils/commonutils";
import CategoriesListHorizontalWidget from "scenes/widgets/CategoriesListHorizontalWidget";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import DiscoveryItemWidget from "scenes/widgets/DiscoverytemWidget";
import DialogWidget from "scenes/widgets/DialogWidget";
import HealthImpactsDetailsPage from "../healthImpactsDetailsPage";
import { preProcessData } from "../productPage";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#FF751E",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "grey",
    boxSizing: "border-box",
  },
}));

const Footer = (isCategoryProductsListFetching = false) => {
  isCategoryProductsListFetching && (
    <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
      Loading...
    </Box>
  );
};

function sendEvent(
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    },
  });
}
const DiscoveryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const backgroundWhite = palette.background.alt;
  const { t } = useTranslation();
  const grayColor = palette.textColor.greySecondary;
  const grayDefault = palette.background.default;
  const textAppBarTitle = palette.textColor.appBarTitle;
  const heightGenericNavBar = isNonMobileScreens ? "72px" : "72px";
  const totalOtherContentHeight = "164px"; //"220px";
  const heightBox = `calc(100vh - ${heightGenericNavBar} - ${totalOtherContentHeight})`;
  const { isRefetchSearch } = useSelector((state) => state.bottomSheet);

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     "section_of_website" : "discovery"
  //   });
  // }, []);

  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar title={`Biteboard`} />
  );

  const {
    pageInit,
    productsListBiteBoard,
    selectedProductCategory,
    selectedProductSubCategory,
    countProductCategory,
  } = useSelector((state) => state.bottomSheet);
  const [pageSize] = useState(10);
  const [checked, setChecked] = useState(true);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const loadMoreItems = () => {
    if (pageSize * (pageInit + 1) < countProductCategory) {
      dispatch(changePageInit());
    }
  };
  useEffect(() => {
    debugLog("selectedCategory", selectedProductCategory);
  }, [selectedProductCategory, pageInit, productsListBiteBoard]);

  const {
    data: dataCategoryList,
    isLoading: isCategoryListLoading,
    isError: categoryListError,
  } = useGetAllBiteboardCategoriesQuery();
  const {
    data: categoryProductsList,
    isLoading: isCategoryProductsListLoading,
    isError: categoryProductsListError,
    isFetching: isCategoryProductsListFetching,
    refetch,
  } = useGetAllBiteboardProductsforCategoryQuery(
    {
      id: selectedProductSubCategory?.id,
      page: pageInit,
      pageSize: pageSize,
    },
    {
      skip: selectedProductSubCategory?.id === -1,
    }
  );
  useEffect(() => {
    if (isRefetchSearch) {
      dispatch(resetPageInit());
      refetch().then(() => {
        dispatch(resetProductsListBiteBoard());
        dispatch(
          updateProductsListBiteBoard(
            categoryProductsList?.product_detail_responses_list || []
          )
        );

        dispatch(setIsRefetchSearch(false));
      });
    }
  }, [isRefetchSearch, dispatch, categoryProductsList, refetch]);
  useEffect(() => {
    dispatch(
      updateProductsListBiteBoard(
        categoryProductsList?.product_detail_responses_list || []
      )
    );
    dispatch(setCountProductBiteBoard(categoryProductsList?.count || 0));
  }, [categoryProductsList, dispatch]);

  useEffect(() => {
    console.debug("Fetched all categories: " + dataCategoryList);
    dispatch(
      setProductCategoryList({
        categoryList: dataCategoryList,
      })
    );
  }, [dataCategoryList]);

  useEffect(() => {
    debugLog(
      "Fetched all products for category: " +
        selectedProductCategory.id +
        " " +
        categoryProductsList
    );
  }, [categoryProductsList, selectedProductCategory]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "biteboard",
    });
  }, []);
  const { openHealthImpactBiteBoard } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );
  const { productBiteBoardClick } = useSelector((state) => state.bottomSheet);

  const handleClose = (openWidgetName) => {
    dispatch(
      setWidget({
        name: openWidgetName,
        isOpen: false,
      })
    );
  };
  const handleItemContent = (index) => {
    const secondItemIndex =
      index + 1 < productsListBiteBoard.length ? index + 1 : null;
    if (productsListBiteBoard[index] === undefined) {
      return (
        <Box
          key={"none" + index}
          sx={{
            height: "0.6px",
            opacity: 0,
          }}
        ></Box>
      );
    }
    return (
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
        key={index + "biteboard"}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          sx={{
            marginBottom: "0.5rem",
          }}
        >
          <DiscoveryItemWidget
            key={`item-${index}`}
            productItem={productsListBiteBoard[index]}
          />
        </Grid>
        {secondItemIndex !== null && (
          <Grid item xs={6} sm={6} md={6}>
            <DiscoveryItemWidget
              key={`item-${index + 1}`}
              productItem={productsListBiteBoard[secondItemIndex]}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const renderDiscoveryProducts = () => {
    if (productsListBiteBoard && productsListBiteBoard.length > 0) {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "8px",
              alignSelf: "stretch",
              ml: "1rem",
            }}
          >
            <Typography variant="h12" fontWeight="400" color={"#171717"}>
              {countProductCategory} items
            </Typography>
          </Box>
          {productsListBiteBoard && productsListBiteBoard.length && (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Virtuoso
                style={{
                  height: heightBox,
                  width: "100%",
                  borderRadius: "1rem",
                }}
                data={productsListBiteBoard}
                itemContent={(index) => handleItemContent(index * 2)}
                endReached={loadMoreItems}
                components={{ Footer: Footer(isCategoryProductsListFetching) }}
              />
            </Box>
          )}
        </>
      );
    }
    return (
      <Typography variant="h12" fontWeight="400" color={"#171717"} ml="1rem">
        No items available
      </Typography>
    );
  };

  return (
    <>
      <Box
        height="100vh"
        sx={{
          overflowY: "hidden",
        }}
      >
        <Helmet title={`Categories`}></Helmet>
        {navBar}
        <FlexBetweenVertical width="100%" height="100%">
          <Box
            width="100%"
            display={isNonMobileScreens ? "flex" : "block"}
            gap="0.5rem"
            justifyContent="space-between"
            height="100%"
          >
            <Box flexBasis={isNonMobileScreens ? "26%" : undefined} />
            <Box
              flexBasis={isNonMobileScreens ? "30%" : undefined}
              width={isNonMobileScreens ? "30%" : undefined}
              height="100%"
            >
              {categoryProductsListError && (
                <FlexBetweenVertical
                  sx={{
                    width: "100%",
                    height: isNonMobileScreens
                      ? "100vh"
                      : `calc(100vh - ${heightGenericNavBar})`,
                  }}
                  alignItems={"center"}
                >
                  <ErrorWidget error={categoryProductsListError} />
                </FlexBetweenVertical>
              )}
              {isNonMobileScreens && !categoryListError && (
                <FlexBetween
                  gap="12px"
                  sx={{ justifyContent: "flex-between", background: "white" }}
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon
                      style={{
                        color: "#3D3C3D",
                        width: "1.725rem",
                        height: "1.5rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    variant="h20"
                    color={textAppBarTitle}
                    fontWeight="400"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <FaqMenuGroupButton
                      showShareIcon={false}
                      offDehazeIcon={isNonMobileScreens}
                    />
                  </Typography>
                </FlexBetween>
              )}

              <FlexBetweenVertical
                gap="12px"
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
                color={grayDefault}
              >
                {dataCategoryList && (
                  <Box
                    paddingTop={isNonMobileScreens ? "0.5rem" : "0rem"}
                    paddingLeft={isNonMobileScreens ? "0.5rem" : "1rem"}
                    backgroundColor="white"
                    width={"100%"}
                  >
                    <CategoriesListHorizontalWidget
                      categoriesList={sortDataByCategory(dataCategoryList)}
                    />
                  </Box>
                )}
                <FlexBetweenVertical
                  sx={{
                    width: "100%",
                    paddingX: "1rem",
                    gap: "12px",
                  }}
                >
                  {dataCategoryList && selectedProductCategory && (
                    <Box width={"100%"}>
                      <SubCategoriesListHorizontalWidget
                        categoriesList={
                          selectedProductCategory?.subcategories || []
                        }
                      />
                    </Box>
                  )}
                  {/* {!isCategoryListLoading && !isCategoryProductsListLoading && (
                    <FlexBetween
                      sx={{
                        border: "1px solid #D4D4D4",
                        borderRadius: "1rem",
                        padding: "0.75rem 1rem",
                        gap: "1rem",
                        width: "100%",
                        justifyContent: "center",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography variant="h12" color={"#171717"}>
                        Only products safe for my health condition(s)
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          defaultChecked
                          checked={checked}
                          onChange={handleSwitchChange}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                      </Stack>
                    </FlexBetween>
                  )} */}
                  {categoryProductsListError ? (
                    <Box sx={{ height: heightBox, width: "100%" }}>
                      <ErrorWidget />
                    </Box>
                  ) : (isCategoryProductsListFetching && pageInit === 0) ||
                    isCategoryProductsListLoading ? (
                    <Box sx={{ height: heightBox, width: "100%" }}>
                      <LoadingWidget />
                    </Box>
                  ) : (
                    renderDiscoveryProducts()
                  )}
                </FlexBetweenVertical>
              </FlexBetweenVertical>
            </Box>

            {isNonMobileScreens && (
              <Box flexBasis="26%">
                <Box m="1.5rem 0" />
              </Box>
            )}
          </Box>
        </FlexBetweenVertical>
        <DialogWidget
          open={openHealthImpactBiteBoard}
          onClose={() => handleClose("openHealthImpactBiteBoard")}
          dialogContent={
            <HealthImpactsDetailsPage
              healthImpactsDetail={
                preProcessData(productBiteBoardClick || {})?.health_impacts
                  ?.negative
              }
              onClose={() => handleClose("openHealthImpactBiteBoard")}
            />
          }
        />
      </Box>
    </>
  );
};

export default DiscoveryPage;
