import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorWidget = ( error ) => {
  const { t } = useTranslation();
  console.log("Error: " + error);
  if ("status" in error) {
    switch (error.status) {
      case 404:
        return <PageNotFoundWidget />
      default:
    }
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: " 0px 2rem",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        flex: "1 0 0",
        alignSelf: "stretch",
        fontFamily: "Lexend",
        lineHeight: "normal",
        height: "100%",
      }}
    >
      <Box>
        <Typography
          style={{
            color: "#000",
            fontSize: "4rem",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "uppercase",
          }}
        >
          🚧
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Typography
          sx={{
            color: "#32363D",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: 600,
          }}
        >
          {t("error.something_went_wrong")}
        </Typography>
        <Typography
          style={{
            color: "#7D8899",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          {t("error.please_try_again")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          padding: "12px 16px",
          justifyContent: " center",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Button
          style={{
            borderRadius: "18px",
            backgroundColor: "#FF751E",
            color: "#fff",
            fontSize: "15px",
            fontWeight: 700,
            lineHeight: "20px",
            padding: "12px 16px",
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("error.try_again")}
        </Button>
      </Box>
    </Box>
  );
};


function PageNotFoundWidget(){
  return (
    <Box
    sx={{
      width: "100%",
      display: "flex",
      padding: " 0px 2rem",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "2rem",
      flex: "1 0 0",
      alignSelf: "stretch",
      fontFamily: "Lexend",
      lineHeight: "normal",
      height: "100%",
    }}
  >
    <Box>
      <Typography
        style={{
          color: "#000",
          fontSize: "4rem",
          fontWeight: 600,
          lineHeight: "normal",
          textTransform: "uppercase",
        }}
      >
        🚧
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Typography
        sx={{
          color: "#32363D",
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: 600,
        }}
      >
        {"Page Not Found!"}
      </Typography>
    </Box>
  </Box>
  )
}
export default ErrorWidget;
