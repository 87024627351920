import {
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSearchProductCountQuery } from "app/services/auth/authService";
import { Helmet } from "react-helmet";
import GenericNavBar from "../navbar/GenericNavBar";
import NavBarDesktop from "../navbar/NavBarDesktop";
import FlexBetween from "components/FlexBetween";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import SearchListItemPagi from "../searchDetailsPage/SearchListItemPagi";

const SameBrandProductsPage = () => {
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const backgrountSearchBar = palette.background.searchBar;
  const textAppBarTitle = palette.textColor.appBarTitle;

  const { searchValue } = useParams();
  const navigate = useNavigate();
  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar
      title={searchValue}
      appBarBackgroundColor={backgrountSearchBar}
    />
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "search_detail",
    });
  }, []);

  // useEffect(() => {
  //   if (productCount) {
  //     // console.log(productCount);
  //     if (productCount.count == 0) {
  //       sendEvent("searchScan", "searchProduct", "searchResult", "empty", searchValue);
  //     }
  //     if (productCount.count > 0) {
  //       sendEvent("searchScan", "searchProduct", "searchResult", "found", searchValue);
  //     }
  //   }
  //   if (ingredientCount) {
  //     // console.log(ingredientCount);
  //     if (ingredientCount.count == 0) {
  //       sendEvent("searchScan", "searchIngredient", "searchResult", "empty", searchValue);
  //     }
  //     if (ingredientCount.count > 0) {
  //       sendEvent("searchScan", "searchIngredient", "searchResult", "found", searchValue);
  //     }
  //   }
  //   // console.log(isLoading)
  // }, [productCount, ingredientCount]);

  // console.log(riskAccordionItemList);
  const [brandProductsCount, setBrandProductsCount] = useState(0);

  useEffect(() => {
    const productBrandCount = localStorage.getItem("productBrandCount");
    if (productBrandCount) {
      setBrandProductsCount(Number(productBrandCount));
    }
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Helmet title={`${searchValue} | Bitewise`}></Helmet>

      {navBar}
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined} />
        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          width={isNonMobileScreens ? "30%" : undefined}
        >
          {isNonMobileScreens && (
            <FlexBetween
              gap="1rem"
              sx={{
                justifyContent: "flex-between",
                background: palette.background.searchBar,
              }}
            >
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon
                  style={{
                    color: "#3D3C3D",
                    width: "1.725rem",
                    height: "1.5rem",
                  }}
                />
              </IconButton>
              <Typography
                variant="h20"
                color={textAppBarTitle}
                fontWeight="400"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                <FaqMenuGroupButton
                  showShareIcon={false}
                  offDehazeIcon={isNonMobileScreens}
                />
              </Typography>
            </FlexBetween>
          )}
          <SearchListItemPagi
            searchValue={searchValue}
            productCount={brandProductsCount}
            heightNoneTab={"58px"}
            isBrand={true}
          />
        </Box>

        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

// function sendEvent(eventName, eventCategory, eventAction, eventLabel, eventValue){
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     event: eventName,
//     eventProps: {
//         category: eventCategory,
//         action: eventAction,
//         label: eventLabel,
//         value: eventValue
//     }
//   });
// }

export default SameBrandProductsPage;
