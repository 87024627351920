import { Box, Typography, Divider, useTheme, IconButton } from "@mui/material";
import ProductImageProductList from "components/ProductImageProductList";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setProductBiteBoard,
  setWidget,
} from "features/bottomSheet/bottomSheetSlice";
import { useEffect, useState } from "react";
import IconQuestion from "./../../assets/Question-circle.svg";
import IconNoHealth from "./../../assets/heartVerified.svg";
import {
  getRatingBackgroundColorBiteboard,
  getRatingImage,
  getRatingText,
  getRatingTextColorBiteboard,
} from "utils/commonutils";
import { useGetProfileQuery } from "app/services/auth/authService";

const DiscoveryItemWidget = ({
  productItem = {},
  showBoxShadow = true,
  shadowPercent = "15%",
  handeClick = () => {},
}) => {
  const {
    data: profile,
    isLoading: profileIsLoading,
    refetch: refetchProfile,
  } = useGetProfileQuery();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const mainHeading = palette.textColor.mainHeading;
  const bgWhite = palette.background.alt;
  const { t } = useTranslation();
  const [payload, setPayload] = useState({
    name: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <WidgetWrapper
        width="100%"
        sx={{
          border: "1px solid #D4D4D4",
          position: "relative",
          borderRadius: "1rem",
          background: bgWhite,
        }}
        padding={0}
      >
        {/* FIRST ROW */}
        <Box
          gap="0.5rem"
          pb="0.5rem"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem",
          }}
          onClick={() => {
            navigate(
              productItem.id
                ? `/product/${productItem.id}`
                : `/product/2d3a80c1-610f-4364-b68f-acd6129a8a21`
            );
            handeClick(productItem);
          }}
        >
          <FlexBetween gap="0.75rem" sx={{ alignItems: "flex-start" }}>
            <ProductImageProductList
              imageUrl={productItem.main_image ? productItem.main_image : ""}
              size="128px"
            />
          </FlexBetween>
          <FlexBetweenVertical
            mt="0.25rem"
            sx={{
              alignItems: "flex-start",
              overflow: "hidden",
              height: "40px",
              maxWidth: "100%",
            }}
          >
            <Typography
              variant="h14"
              color={mainHeading}
              fontWeight="400"
              lineHeight={"18px"}
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                maxWidth: "100%",
                whiteSpace: "collapse",
                overflow: "hidden",
              }}
            >
              {productItem.name ? productItem.name : "Product Name"}
            </Typography>
          </FlexBetweenVertical>
        </Box>
        {productItem?.health_conditions?.length ? (
          <>
            <Divider />
            <FlexBetween
              sx={{
                padding: "8px 12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                {productItem?.health_conditions?.map((item, index) => (
                  <Typography
                    key={index + "health_conditions"}
                    variant="body1"
                    color={palette.textColor.greyDark}
                    fontWeight="500"
                    sx={{
                      backgroundColor:
                        productItem?.rating?.toUpperCase() === "BAD"
                          ? "#FEE2E2"
                          : "transparent",
                      borderRadius: "4px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "18px",
                      height: "18px",
                      lineHeight: "18px",
                    }}
                  >
                    {item?.name.slice(0, 2)}
                  </Typography>
                ))}
              </Box>
              <IconButton
                onClick={() => {
                  handleClickOpen("openHealthImpactBiteBoard");
                  dispatch(setProductBiteBoard(productItem));
                }}
                sx={{
                  padding: "2px",
                }}
              >
                <img
                  src={IconQuestion}
                  alt="icon-question"
                  style={{
                    width: "1rem",
                    height: "1rem",
                  }}
                />
              </IconButton>
            </FlexBetween>
          </>
        ) : (
          profile &&
          profile.health_conditions.length > 0 && (
            <>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  height: "38px",
                }}
              >
                <img src={IconNoHealth} alt="icon" />
                <Typography variant="h12" color={"#737373"} fontWeight="500">
                  {t("discovery.no_health_risk")}
                </Typography>
              </Box>
            </>
          )
        )}
      </WidgetWrapper>
      <Box
        sx={{
          position: "absolute",
          zIndex: 10,
          top: "5px",
          left: "5px",
        }}
      >
        <RatingDashboard rating={productItem.rating} t={t} palette={palette} />
      </Box>
    </Box>
  );
};

export default DiscoveryItemWidget;

function RatingDashboard({ rating, w = "64px", t, palette }) {
  rating = rating ? rating.toUpperCase() : "";

  return (
    <FlexBetween
      width={w}
      minWidth={w}
      backgroundColor={getRatingBackgroundColorBiteboard(rating, palette)}
      paddingX="8px"
      paddingY="2px"
      gap="0.25rem"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.75rem",
      }}
    >
      {getRatingImage(rating, "1rem")}
      <Typography
        variant="h12"
        align="center"
        color={getRatingTextColorBiteboard(rating, palette)}
        fontWeight="600"
      >
        {getRatingText(rating, t)}
      </Typography>
    </FlexBetween>
  );
}
