import {
  Box,
  Typography,
  Switch,
  useTheme,
  Divider,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useGetHealthConditionListQuery,
  useGetProfileQuery,
  useUpdateHealthConditionMutation,
} from "../../app/services/auth/authService";

import { forEach, map } from "lodash";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import HeaderBottomSheet from "./HeaderBottomSheet";
import { ReactComponent as FilterIcon } from "../../assets/slider_icon.svg";
import { useDispatch } from "react-redux";
import {
  changeHealthCondition,
  setIsRefetchHistory,
  setIsRefetchSearch,
} from "features/bottomSheet/bottomSheetSlice";
import ErrorWidget from "./ErrorWidget";
import LoadingWidget from "./LoadingWidget";
import { useTranslation } from "react-i18next";

const HealthConditionsWidget = (props) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const {
    data: profile,
    isLoading: profileIsLoading,
    refetch: refetchProfile,
  } = useGetProfileQuery();
  const {
    data: healthConditions,
    isLoading: healthConditionLoading,
    error,
    refetch: refetchHealthConditions,
  } = useGetHealthConditionListQuery();
  const [updateHealthCondition] = useUpdateHealthConditionMutation();
  const [isChange, setIsChange] = useState(false);
  const [healthConditionUpdate, setHealthConditionUpdate] = useState([]);
  const [userHealthCondition, setUserHealthCondition] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userHealthCondition?.length && healthConditions?.length) {
      const ids = userHealthCondition.map((condition) => {
        const matchingCondition = healthConditions.find(
          (item) => item.name === condition
        );
        return matchingCondition ? matchingCondition.id : null;
      });
      setHealthConditionUpdate(ids.filter((id) => id !== null));
    } else {
      setHealthConditionUpdate([]);
    }
  }, [userHealthCondition, healthConditions]);

  const handleSwitchChange = async (name) => {
    if (userHealthCondition.includes(name)) {
      setUserHealthCondition(
        userHealthCondition.filter((condition) => condition !== name)
      );
    } else {
      setUserHealthCondition([...userHealthCondition, name]);
    }
    setIsChange(true);
  };

  const backgroundWhite = palette.background.alt;
  const bgOrange = palette.background.orangeDark;
  const bgGray = palette.background.grey;
  const bgButton = isChange ? bgOrange : bgGray;
  const styleButton = {
    backgroundColor: bgButton,
    height: "48px",
    padding: "8px 16px",
    color: backgroundWhite,
    textAlign: "center",
    borderRadius: "1rem",
    fontWeight: 700,
    lineHeight: "1.25rem",
    letterSpacing: "0.1px",
  };

  const handleSaveHealthCondition = async () => {
    if (!healthConditionUpdate?.length) {
      await updateHealthCondition([]);
    } else {
      await updateHealthCondition(healthConditionUpdate);
    }
    refetchProfile();
    refetchHealthConditions();
    setIsChange(false);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "changeHealthCondition",
      eventProps: {
        category: "health_condition",
        action: "change",
        label: userHealthCondition,
        value: userHealthCondition,
      },
    });
  };

  useEffect(() => {
    if (profile?.health_conditions?.length) {
      setUserHealthCondition(profile.health_conditions);
    }
  }, [profile]);

  const iconHealth = <FilterIcon />;
  const { onClose } = props;

  return (
    <Box backgroundColor={backgroundWhite} sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          left: "0",
          width: "100%",
          zIndex: 10,
        }}
      >
        <HeaderBottomSheet
          name={`${t("menu.health_codition")}`}
          icon={iconHealth}
          close={onClose}
        />
      </Box>
      {healthConditionLoading && <LoadingWidget />}
      {error && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            flexShrink: 0,
            alignSelf: "stretch",
            height: "65vh",
          }}
        >
          <ErrorWidget error={error} />
        </Box>
      )}

      {healthConditions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              paddingBottom: "10px",
            }}
          >
            {t("productDetail.health_condition_impacts_sub")}
          </Typography>
          <FlexBetweenVertical
            sx={{ alignItems: "flex-start", marginBottom: 2, gap: "1rem" }}
          >
            <Typography
              fontWeight="400"
              fontFamily="h12"
              gap={"0.75rem"}
              color="#B1B8C2"
              sx={{ textTransform: "uppercase" }}
            >
              {t("health_codition_bottomsheet.special_condition_or_issue")}
            </Typography>
            <FlexBetweenVertical width="100%">
              {map(getHealthIssue(healthConditions), (item, index) => {
                return (
                  <FlexBetween width="100%" key={index}>
                    <Typography
                      id="healthconditionlist_name"
                      fontWeight="500"
                      variant="h5"
                      alignItems="center"
                      lineHeight={"1.5rem"}
                    >
                      {item.name}
                    </Typography>
                    <Switch
                      id="healthconditionlist_switch"
                      color="warning"
                      checked={
                        userHealthCondition?.includes(item.name) || false
                      }
                      onChange={() => handleSwitchChange(item.name)}
                    />
                  </FlexBetween>
                );
              })}
            </FlexBetweenVertical>

            <Typography
              fontWeight="400"
              fontFamily="h12"
              gap={"0.75rem"}
              color="#B1B8C2"
              sx={{ textTransform: "uppercase" }}
            >
              {t("health_codition_bottomsheet.allergy_or_intolerance")}
            </Typography>
            <FlexBetweenVertical width="100%">
              {map(getAllergy(healthConditions), (item, index) => {
                return (
                  <FlexBetween width="100%" key={index}>
                    <Typography
                      id="healthconditionlist_name"
                      fontWeight="500"
                      alignItems="center"
                      variant="h5"
                      lineHeight={"1.5rem"}
                    >
                      {item.name}
                    </Typography>
                    <Switch
                      id="healthconditionlist_switch"
                      color="warning"
                      checked={
                        userHealthCondition?.includes(item.name) || false
                      }
                      onChange={() => handleSwitchChange(item.name)}
                    />
                  </FlexBetween>
                );
              })}
            </FlexBetweenVertical>
          </FlexBetweenVertical>
          <Divider style={{ background: "#EBEBF0", marginBottom: "0.7rem" }} />
          <Box
            sx={{
              padding: "1rem 1rem",
              backgroundColor: "white",
            }}
          >
            <Button
              style={styleButton}
              sx={{
                position: "fixed",
                width: "90%",
                bottom: 0,
                left: "1rem",
                zIndex: 9,
              }}
              onClick={async () => {
                await handleSaveHealthCondition();
                onClose();
                dispatch(changeHealthCondition());
                dispatch(setIsRefetchHistory(true));
                dispatch(setIsRefetchSearch(true));
              }}
            >
              {t("health_codition_bottomsheet.save_and_update_results")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

function getHealthIssue(healthConditions) {
  let healthConditionFiltered = [];
  forEach(healthConditions, (healthConditionItem, i) => {
    if (healthConditionItem.allergy == null || !healthConditionItem.allergy) {
      healthConditionFiltered.push(healthConditionItem);
    }
  });
  return healthConditionFiltered;
}

function getAllergy(healthConditions) {
  let healthConditionFiltered = [];
  forEach(healthConditions, (healthConditionItem, i) => {
    if (healthConditionItem.allergy != null && healthConditionItem.allergy) {
      healthConditionFiltered.push(healthConditionItem);
    }
  });
  return healthConditionFiltered;
}

export default HealthConditionsWidget;
