import { Verified } from "@mui/icons-material";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  styled,
  IconButton,
} from "@mui/material";

import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductChips from "components/ProductChips";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { Helmet } from "react-helmet";
import GenericNavBar from "../navbar/GenericNavBar";
import { ReactComponent as IngredientIcon } from "../../../assets/chemical.svg";
import { ReactComponent as DescriptionIcon } from "../../../assets/desIcon.svg";
import { ReactComponent as TagNameIcon } from "../../../assets/tagIcon.svg";
import { ReactComponent as ScienceIcon } from "../../../assets/source.svg";
import { ReactComponent as HumanIcon } from "../../../assets/humanIcon.svg";
import BlockIcon from "@mui/icons-material/Block";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CircleIcon from "@mui/icons-material/Circle";
import { useGetNutrientDetailQuery } from "app/services/auth/authService";
import { HOST_NAME } from "utils/constants";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { RatingWidget } from "scenes/widgets/CustomRisksAccordion";
import { debugLog, getRatingBackgroundColor } from "utils/commonutils";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import ExpandableListTextView from "scenes/widgets/ExpandableListTextView";
import parse from "html-react-parser";
import { getNoDataTypography, getReferencesLinksText } from "../ingredientPage";
import ExpandableLongTextView from "scenes/widgets/ExpandableLongTextView";

const BoxVerticalWidget = styled(Box)(({ palette }) => ({
  backgroundColor: palette.background.alt,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  padding: "1rem 0.75rem 1rem 0.75rem",
  borderRadius: "1rem",
  alignItems: "flex-start",
  gap: "0.75rem",
}));

const NutrientDetailsPage = () => {
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { t } = useTranslation();
  const dark = palette.neutral.dark;
  const backgrountWhite = palette.background.alt;
  const greyLight = palette.textColor.greyLight;
  const mainHeading = palette.textColor.mainHeading;
  const shapeStyles = { bgcolor: palette.neutral.main, width: 40, height: 40 };
  const [nutrientTitle, setNutrientTitle] = useState("");
  let { nutrientId } = useParams();
  const navigate = useNavigate();
  const textAppBarTitle = palette.textColor.appBarTitle;
  const location = useLocation();

  const {
    data: nutrient,
    isLoading,
    error,
  } = useGetNutrientDetailQuery({ id: nutrientId });

  // const isLoading = false;
  // const error = false;
  // const nutrient = {
  //   id: "aec5903a-53e1-4c74-90c6-d8b2d9c96e83",
  //   name: "Energy",
  //   other_names: [
  //     "energy",
  //     "giá trị năng lượng",
  //     "Energy",
  //     "năng lượng",
  //     "năng lượng",
  //     "Năng lượng",
  //     "năng lượ̣ng",
  //     "Energyinkcal",
  //     "EnergyinkJ",
  //     "total energy",
  //     "Total energy",
  //     "calories",
  //     "Năng lượng",
  //     "năng ượng",
  //     "enegry",
  //     "Calories",
  //     "enegey",
  //     "Tổng năng lượng",
  //     "Energy value",
  //     "giá trị năng lượng",
  //     "กิโลแคลอรี (Total energy)",
  //     "total enegry",
  //     "total Energy",
  //     "Enegry",
  //     "Năng lượng (Calories)",
  //     "Energy/ Năng lượng",
  //     "Energy/Calories",
  //     "năn lượng",
  //     "Giá trị năng lượng",
  //   ],
  //   icon: "https://bitewise.co/cdn/icons/nutrient_calories.svg",
  //   short_description:
  //     "Energy, measured in calories, is the fuel our bodies need to function and perform daily activities. It comes from the food and drinks we consume and helps us stay active and alert throughout the day.",
  //   long_description:
  //     "Energy is the fuel received from the food we consume. Everything we do requires energy, right from allowing your heart to pump, muscles to grow and repair, and brain to do all the serious work!\nWe get this energy from three essential macronutrients-  carbohydrates, proteins and fat. The amount of each of these determines the energy content (or calories) in your food. \n\nFoods like cereals, beans, nuts, fats, dairy, seeds, and flesh foods are energy-dense as they contain higher amounts of these macronutrients. Foods like fresh fruits, vegetables and legumes are lower in these macronutrients. \n\nSo, how much energy do you need?\nThere is no one-size-fits-all solution to this question! Your energy needs would be different based on your gender, age, physical activity and even your overall health.\n\nBut, in general, it is very important to find that sweet balance to maintain your body weight. Consuming more calories than you require would likely lead to weight gain, as our body stores that extra energy into fat. Unwanted weight gain and obesity puts you on an increased risk of developing Type 2 diabetes, heart diseases including high blood pressure, fatty liver disease, certain cancers, and impaired immunity.\nWhile consuming less than your requirements may likely lead to weight loss. Being underweight can lead to severe nutrient deficiencies leading to increased risk of anemia, bone fractures, and a weakened immune system. \n\nPro tips:\n- Rather than focusing on one food, focus on diversifying your diet with a variety of food groups- whole grains, pulses, fruits, vegetables, dairy etc.\n- Serving size of the food is equally important to maintain the energy content of your food. Listen to your body!",
  //   benefit: null,
  //   sources: [
  //     "https://www.nhs.uk/live-well/healthy-weight/managing-your-weight/understanding-calories/",
  //     "https://drive.google.com/file/d/1gHSXrGDZekHlh7TuPik9MszPjsgCVeHi/view",
  //     "https://www.nin.res.in/RDA_short_Report_2024.html",
  //   ],
  //   negatives: null,
  //   banned_in: [],
  //   possible_food_use: null,
  //   health_impacts: null,
  // };

  function getShareData(data) {
    // Set share data
    if (data == null) return;
    return {
      type: "nutrient",
      title: "Share",
      text: data.name,
      url: HOST_NAME + "/nutrient/" + data.id,
    };
  }

  debugLog("nutrient", nutrient);

  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar
      appBarBackgroundColor={backgrountWhite}
      showShareIcon={true}
      shareData={getShareData(nutrient)}
    />
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "nutrient",
    });
  }, []);

  return (
    <Box>
      <Helmet
        title={
          nutrientTitle?.length > 0 ? `${nutrientTitle} | Bitewise` : `Bitewise`
        }
      ></Helmet>
      {navBar}
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
        {isLoading ? (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            <LoadingWidget />
          </Box>
        ) : error ? (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            <Box
              sx={{
                width: "100%",
                height: "85vh",
              }}
            >
              <ErrorWidget error={error} />
            </Box>
          </Box>
        ) : (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            {isNonMobileScreens && (
              <FlexBetween
                gap="1rem"
                sx={{ justifyContent: "flex-between", background: "white" }}
              >
                <IconButton
                  onClick={() => {
                    if (
                      location.key === "default" ||
                      window.history.length <= 2
                    ) {
                      navigate(`/`);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <ArrowBackIcon
                    style={{
                      color: "#3D3C3D",
                      width: "1.725rem",
                      height: "1.5rem",
                    }}
                  />
                </IconButton>
                <Typography
                  variant="h20"
                  color={textAppBarTitle}
                  fontWeight="400"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <FaqMenuGroupButton
                    showShareIcon={true}
                    offDehazeIcon={isNonMobileScreens}
                    shareData={getShareData(nutrient)}
                  />
                </Typography>
              </FlexBetween>
            )}
            {/* FIRST ROW */}
            <FlexBetween
              gap="1.25rem"
              p="1rem 1.5rem 1rem 1.5rem"
              backgroundColor={backgrountWhite}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              // alignItems={}
              // onClick={() => navigate(`/profile/${userId}`)}
            >
              <IngredientIcon />
              <FlexBetweenVertical
                sx={{ alignItems: "flex-start" }}
                gap="0.5rem"
              >
                <Typography variant="h18" color={dark} fontWeight="600">
                  {nutrient?.name}
                </Typography>
                <Typography variant="h14" fontWeight="400" color={greyLight}>
                  {nutrient?.code}
                </Typography>

                <Box
                  padding="0.25rem 0.5rem 0.25rem 0.5rem"
                  backgroundColor={getRatingBackgroundColor(
                    nutrient?.rate_code,
                    palette
                  )}
                  borderRadius="0.5rem"
                >
                  <RatingWidget
                    type={nutrient?.rate_code}
                    rate_code={nutrient?.rate_code}
                    tag_name={nutrient?.rate_code}
                    palette={palette}
                  />
                </Box>
              </FlexBetweenVertical>
            </FlexBetween>

            <FlexBetweenVertical gap="0.75rem" p="1rem" width="100%">
              {/* Description */}
              {nutrient?.long_description && (
                <BoxVerticalWidget palette={palette} height={"100%"}>
                  <AccordionHeader
                    title={`${t("ingredient.description")}`}
                    icon={<DescriptionIcon />}
                  ></AccordionHeader>
                  <Divider display="flex" mt="0.75rem" width="100%" />
                  <ExpandableLongTextView value={nutrient?.long_description} />
                </BoxVerticalWidget>
              )}

              {/* OTHER NAMES AND USAGE */}
              <FlexBetween
                gap="0.75rem"
                width="100%"
                sx={{ alignItems: "flex-start", height: "100%" }}
              >
                {nutrient?.other_names && nutrient?.other_names.length > 0 && (
                  <BoxVerticalWidget
                    palette={palette}
                    sx={{ height: "100%", minHeight: "102px" }}
                  >
                    <AccordionHeader
                      title={`${t("ingredient.other_names")}`}
                      icon={<TagNameIcon />}
                    ></AccordionHeader>

                    <Divider
                      display="flex"
                      mt="0.75rem"
                      width="100%"
                      style={{ background: "#EBEBF0" }}
                    />
                    <ExpandableListTextView dataItems={nutrient?.other_names} />
                    {/* <ListItem data={ingredient?.other_names} palette={palette} t={t}/> */}
                  </BoxVerticalWidget>
                )}
                {nutrient?.usage && nutrient?.usage.length > 0 && (
                  <BoxVerticalWidget
                    palette={palette}
                    sx={{ height: "100%", minHeight: "132px" }}
                  >
                    <AccordionHeader
                      title={`${t("ingredient.usage")}`}
                      icon={<AdsClickIcon />}
                    ></AccordionHeader>
                    <Divider
                      display="flex"
                      mt="0.75rem"
                      width="100%"
                      style={{ background: "#EBEBF0" }}
                    />
                    <Typography>{nutrient?.usage}</Typography>
                  </BoxVerticalWidget>
                )}
              </FlexBetween>

              {/* POTENTIAL HEALTH RISKS */}
              {nutrient?.health_impacts &&
                nutrient?.health_impacts.length > 0 && (
                  <BoxVerticalWidget palette={palette} height={"100%"}>
                    <AccordionHeader
                      title={`${t("ingredient.health_impact")}`}
                      icon={<HumanIcon />}
                    ></AccordionHeader>
                    <Divider
                      display="flex"
                      mt="0.75rem"
                      width="100%"
                      style={{ background: "#EBEBF0" }}
                    />
                    {nutrient?.health_impacts &&
                      nutrient?.health_impacts.length > 0 && (
                        <ProductChips
                          ingredientDetailPage={true}
                          chipsList={nutrient?.health_impacts}
                        />
                      )}
                  </BoxVerticalWidget>
                )}

              <BoxVerticalWidget palette={palette} height={"100%"}>
                <AccordionHeader
                  title={`${t("ingredient.scientific_sources")}`}
                  icon={<ScienceIcon />}
                ></AccordionHeader>
                <Divider
                  display="flex"
                  mt="0.75rem"
                  width="100%"
                  style={{ background: "#EBEBF0" }}
                />

                {nutrient?.sources && nutrient?.sources.length > 0 ? (
                  <Typography
                    variant="h14"
                    fontWeight="300"
                    pl="0.75rem"
                    pr="0.75rem"
                    color={mainHeading}
                    sx={{
                      wordWrap: "break-word",
                      maxWidth: {
                        xs: "20rem",
                        sm: "37.5rem",
                        md: "56.25rem",
                        lg: "20rem",
                        xl: "20rem",
                      },
                    }}
                  >
                    {parse(getReferencesLinksText(nutrient?.sources, t))}
                  </Typography>
                ) : (
                  getNoDataTypography(palette, t)
                )}
              </BoxVerticalWidget>
              {/* POSSIBLE FOOD USE */}
              {/* {
                nutrient?.possible_food_use && nutrient?.possible_food_use.length>0 && 
                <BoxVerticalWidget palette={palette}>
                  <AccordionHeader
                    title={`${t("ingredient.possible_food_uses")}`}
                    icon={<RestaurantIcon />}
                  ></AccordionHeader>
                  <Divider display="flex" mt="0.75rem" width="100%" style={{background:"#EBEBF0"}}/>
                  <Typography>{nutrient?.possible_food_use}</Typography>
                </BoxVerticalWidget>
              } */}

              {/* BANNED IN */}
              {nutrient?.banned_in && nutrient?.banned_in.length > 0 && (
                <BoxVerticalWidget palette={palette}>
                  <AccordionHeader
                    title={`${t("ingredient.banned_in")}`}
                    icon={<BlockIcon />}
                  ></AccordionHeader>
                  <Divider
                    display="flex"
                    mt="0.75rem"
                    width="100%"
                    style={{ background: "#EBEBF0" }}
                  />
                  <ListItem data={nutrient?.banned_in} />
                </BoxVerticalWidget>
              )}
            </FlexBetweenVertical>
          </Box>
        )}
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

function AccordionHeader({ icon, title }) {
  return (
    <FlexBetween gap="0.75rem">
      {icon}
      <Typography variant="h15" color="#3D3C3D" fontWeight="600">
        {title}
      </Typography>
    </FlexBetween>
  );
}

function ListItem({ data }) {
  return data?.map((value) => (
    <Typography key={value} style={{ marginLeft: "1rem" }}>
      <CircleIcon
        style={{
          width: "5px",
          height: "5px",
          marginRight: "0.5rem",
        }}
      />
      {value}
    </Typography>
  ));
}

export default NutrientDetailsPage;
