import { Box } from "@mui/material";
import { styled } from "@mui/system";

const FlexBetweenVertical = styled(Box)({
  display: "flex",
  flexDirection: 'column',
  justifyContent: "space-between",
  alignItems: "center",
});

export default FlexBetweenVertical;
