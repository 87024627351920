import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getRatingText, getRatingImage, getRatingBackgroundColor, getRatingTextColor, debugLog } from "utils/commonutils";
import FlexBetweenVertical from "./FlexBetweenVertical";

const AlternativeProductItemRating = ({rating, score, h = "1.563rem", w = "8.125rem"}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const red = palette.textColor.red;
  const textMainSecondary = palette.textColor.mainSecondary;
  const lightGrey = palette.neutral.light;
  debugLog("AlternativeProductItemRating", score);
  return (
    <Box
      backgroundColor={getRatingBackgroundColor(rating, palette)}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem",
        borderRadius: "0.75rem",
        pl: "0.5rem",
        pr: "0.5rem",
      }}
    >
      {getRatingImage(rating, "1rem")}
      <FlexBetweenVertical>
        <Typography
          variant="h14"
          color={getRatingTextColor(rating, palette)}
          fontWeight="800"
        >
          {getRatingText(rating, t)}
        </Typography>

        {score > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h12"
              color={textMainSecondary}
              fontWeight="700"
            >
              {score}
              {"/"}
            </Typography>

            <Typography
              variant="h12"
              color={textMainSecondary}
              fontWeight="600"
            >
              {"100"}
            </Typography>
          </Box>
        )}
      </FlexBetweenVertical>
    </Box>
  );
};

export default AlternativeProductItemRating;