import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import DialogWidget from "scenes/widgets/DialogWidget";
import DisclaimerWiseGPT from "scenes/widgets/DisclamerWiseGPT";

const SearchWiseGPT = (props) => {
  const {
    showPlaceHolder,
    isDesktop,
    setQuestion,
    value,
    showShadow = true,
    isNavbar = false,
    isLoading = false,
  } = props;
  const { palette } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const handleClose = () => {
    setOpenDisclaimer(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && !isLoading) {
      handleSubmit(event);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setQuestion(inputValue);
    setInputValue("");
  };
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const textMainHeading = palette.textColor.mainHeading;
  const textGreyLight = palette.textColor.greyLight;
  const textOrangeDark = palette.textColor.orangeDark;
  const backgrountWhite = palette.background.alt;
  const colorSearch = showPlaceHolder ? textMainHeading : backgrountWhite;
  const bgSearch = showPlaceHolder ? backgrountWhite : textOrangeDark;
  const pdSearch = showPlaceHolder
    ? "1rem 1.25rem 1rem 1.25rem"
    : "0.75rem 1rem 0.75rem 1rem";
  const colorSearchIcon = !showPlaceHolder ? backgrountWhite : textOrangeDark;
  const styleNoneNavbar =
    isNavbar && isDesktop
      ? {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "space-between",
          padding: "0px 1.5rem 1.25rem 1.5rem",
          backgroundColor: "#F2F3F5",
          paddingY: "1.25rem",
        }
      : {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "1rem",
          padding: "0px 1.5rem 1.25rem 1.5rem",
          backgroundColor: "#F2F3F5",
          paddingY: "1.25rem",
        };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: isNonMobileScreens ? "50%" : 0,
        maxWidth: isNonMobileScreens ? "385px" : "100%",
        zIndex: 99,
        transform: isNonMobileScreens ? "translateX(-50%)" : "",
      }}
    >
      <Box
        sx={{
          height: "50px",
          backgroundImage:
            "linear-gradient(180deg, rgba(242, 243, 245, 0) 0%, #F2F3F5 100%)",
        }}
      ></Box>{" "}
      <Box sx={styleNoneNavbar}>
        <form onSubmit={handleSubmit}>
          <Box
            id="search_btn"
            sx={{
              border: 2,
              backgroundColor: bgSearch,
              borderColor: colorSearch,
              borderRadius: "1.125rem",
              padding: pdSearch,
              cursor: "pointer",
              boxShadow: showShadow ? "2px 4px 10px 0px rgb(0 0 0 / 15%)" : "0",
              gridColumn: "1/ span 3",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FlexBetweenVertical
              sx={{ alignItems: "flex-start", flex: 1, position: "relative" }}
            >
              <textarea
                maxLength={150}
                value={inputValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                defaultValue={value}
                onKeyDown={handleKeyDown}
                placeholder="Enter your question here"
                style={{
                  width: "100%",
                  color: "#191B1F",
                  fontSize: "1rem",
                  height: isFocused ? "60px" : "100%",
                  fontWeight: 700,
                  fontFamily: "Lexend",
                  backgroundColor: bgSearch,
                  cursor: "pointer",
                  maxWidth: "100%",
                  maxHeight: "60px",
                  overflowY: "auto",
                  overflowX: "auto",
                  wordWrap: "break-word",
                  resize: "none",
                  outline: "none",
                  border: "none",
                  position: "relative",
                }}
                className={isFocused ? "focused-placeholder" : ""}
              />

              {!isFocused && inputValue === "" && (
                <Typography
                  mt="0.25rem"
                  color={textGreyLight}
                  onClick={()=>{
                    setIsFocused(true)
                  }}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: isNonMobileScreens ? "2px" : '8px',
                    zIndex: 0,
                  }}
                >
                  Seek and you shall find
                </Typography>
              )}
            </FlexBetweenVertical>

            <IconButton
              type="submit"
              sx={{
                width: "44px",
              }}
              disabled={isLoading}
            >
              <SendIcon
                style={{
                  color: isLoading ? "gray" : colorSearchIcon,
                  cursor: "pointer",
                  fontSize: "1.75rem",
                }}
              />
            </IconButton>
          </Box>
        </form>
      </Box>
      <Typography
        onClick={() => {
          setOpenDisclaimer(true);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#7D8899",
          backgroundColor: "#F2F3F5",
          padding: "0px 24px 20px 24px",
          cursor: "pointer",
        }}
      >
        <InfoIcon
          sx={{
            width: "12px",
            height: "12px",
            color: "#7D8899",
          }}
        />
        Disclaimer
      </Typography>
      <DialogWidget
        open={openDisclaimer}
        onClose={() => handleClose()}
        dialogContent={<DisclaimerWiseGPT onClose={() => handleClose()} />}
      />
    </Box>
  );
};

export default SearchWiseGPT;
