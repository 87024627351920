import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Question from "./Question";
import { useNavigate } from "react-router-dom";

const MostAskedQuestions = ({
  setQuestion,
  hasQuestion,
  isHomeScreen = false,
}) => {
  const [chooseQuestion, setChooseQuestion] = useState();

  useEffect(() => {
    if (chooseQuestion && isHomeScreen) {
      sessionStorage.setItem("chosenQuestion", JSON.stringify(chooseQuestion));
    }
  }, [chooseQuestion, isHomeScreen]);

  const questions = [
    "Please advise me about weight loss in a healthy manner?",
    "How much coffee is too much coffee for a day?",
    "Is it better to do cardio before or after weightlifting?",
    "How much water should I drink daily?",
    "What are some effective strategies for improving sleep quality?",
  ];
  const navigate = useNavigate();

  const renderQuestion = () => {
    const questionRender = !isHomeScreen ? questions : questions.slice(0, 3);
    return questionRender.map((question, index) => (
      <Box
        key={index}
        onClick={async () => {
          if (!isHomeScreen) {
            setQuestion(questions[index]);
          } else {
            await setChooseQuestion(questionRender[index]);
            navigate(`/wisegpt`);
          }
        }}
        sx={{
          textTransform: "none",
          cursor: "pointer",
        }}
      >
        <Question question={question} hasQuestion={hasQuestion} />
      </Box>
    ));
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
        padding: "1.25rem",
        backgroundColor: hasQuestion ? "white" : "#FFF1E8",
        borderRadius: "1rem",
        border: `1px solid  ${hasQuestion ? "#D8DBE0" : "FFF1E8"}`,
        marginTop: hasQuestion ? "24px" : "",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "28px",
          }}
        >{`💡`}</Typography>
        {!isHomeScreen && (
          <Typography variant="h4" sx={{ fontWeight: 700, fontSize: "18px" }}>
            {hasQuestion ? "Have another question?" : "Most Asked Questions"}
          </Typography>
        )}
        {isHomeScreen && (
          <Typography variant="h4" sx={{ fontWeight: 700, fontSize: "18px" }}>
            {"Got burning questions about health?"}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
        }}
      >
        {renderQuestion()}
        {isHomeScreen && (
          <Button
            onClick={() => {
              navigate("/wisegpt");
            }}
            sx={{
              padding: "8px 24px 8px 24px",
              borderRadius: "16px",
              border: "1.5px solid #D8DBE0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              textTransform: "none",
              marginTop: "8px",
              "&:hover": {
                backgroundColor: "#F0F0F0",
                border: "1.5px solid #B8BBBF",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                textTransform: "none",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              💬 Ask wise
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                textTransform: "none",
                lineHeight: "20px",
                textAlign: "center",
                color: "#FF751E",
              }}
            >
              gpt
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MostAskedQuestions;
