import {
    Box,
    Typography,
    Divider,
    useTheme,
    useMediaQuery,
    styled,
    IconButton,
    Button,
  } from "@mui/material";
  import FlexBetween from "components/FlexBetween";
  import { useEffect, useState } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import ProductChips from "components/ProductChips";
  import FlexBetweenVertical from "components/FlexBetweenVertical";
  import { Helmet } from "react-helmet";
  import GenericNavBar from "../navbar/GenericNavBar";
  import NavBarDesktop from "../navbar/NavBarDesktop";
  import { useTranslation } from "react-i18next";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import { useDispatch, useSelector } from "react-redux";
import { changeUserLocationState } from "features/auth/authSlice";
  
  
  const DeveloperModePage = () => {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const dark = palette.neutral.dark;
    const backgrountWhite = palette.background.alt;
    const greyLight = palette.textColor.greyLight;
    const mainHeading = palette.textColor.mainHeading;
    const textAppBarTitle = palette.textColor.appBarTitle;
    const yellowDark = palette.background.yellowDark;
    const textMainHeading = palette.textColor.mainHeading;
    const dispatch = useDispatch();

    const { userIPLocation } = useSelector(
      (state) => state.auth
    );
    
    const navBar = isNonMobileScreens ? (
      <NavBarDesktop />
    ) : (
      <GenericNavBar
        appBarBackgroundColor={backgrountWhite}
      />
    );

    const getCountryName = (userIPLocation) => {
      if(userIPLocation && userIPLocation.ipapi && userIPLocation.ipapi.countryName) {
        return userIPLocation.ipapi.countryName;
      } else {
        return "India"
      }
    }
  
    const changeUserLocation = (userCountryData) => {
      if(userIPLocation && userIPLocation.ipapi){
        dispatch(
          changeUserLocationState({
            locationData: { countryName: userCountryData.countryName, city: userCountryData.city },
          })
        );
      }
    }

    return (
      <Box>
        <Helmet
          title={"Bitewise | Developer Mode"}
        ></Helmet>
  
        {navBar}
  
        <Box
          width="100%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
  
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            {isNonMobileScreens && (
                <FlexBetween
                  gap="1rem"
                  sx={{ justifyContent: "flex-between", background: "white" }}
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon
                      style={{
                        color: "#3D3C3D",
                        width: "1.725rem",
                        height: "1.5rem",
                      }}
                    />
                  </IconButton>
                  <Typography
                    variant="h20"
                    color={textAppBarTitle}
                    fontWeight="400"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <FaqMenuGroupButton
                      offDehazeIcon={isNonMobileScreens}
                    />
                  </Typography>
                </FlexBetween>
              )}
            <Box>
              {/* FIRST ROW */}

              <Divider display="flex" width="100%"/>

              <FlexBetween
                gap="1rem"
                p="1rem 1.5rem 1rem 1.5rem"
                backgroundColor={backgrountWhite}
                sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              >
                <FlexBetween width="100%">
                  <Typography variant="body1" fontWeight="600" >
                      CHANGE COUNTRY
                  </Typography>
                  <FlexBetween gap="0.625rem">
                    <Button
                      id="country_btn"
                      variant="contained"
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      onClick={() => {
                        changeUserLocation({countryName: "Vietnam", city: "Virampur"});
                      }}
                      sx={{
                        maxWidth: "41px",
                        maxHeight: "26px",
                        minWidth: "41px",
                        minHeight: "26px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        justifyContent: "center",
                        borderRadius: "0.5rem",
                        color: textMainHeading,
                        backgroundColor: getCountryName(userIPLocation).toUpperCase() === "VIETNAM" ? yellowDark : "#FFFFFF",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor:
                          getCountryName(userIPLocation).toUpperCase() === "VIETNAM" ? yellowDark : "transparent",
                        },
                      }}
                    >
                      VIE
                    </Button>
                    <Button
                      id="language_btn"
                      variant="contained"
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      onClick={() => {
                        changeUserLocation({countryName: "India", city: "Rampur"});
                      }}
                      sx={{
                        maxWidth: "34px",
                        maxHeight: "26px",
                        minWidth: "34px",
                        minHeight: "26px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        justifyContent: "center",
                        borderRadius: "0.5rem",
                        color: textMainHeading,
                        backgroundColor: getCountryName(userIPLocation).toUpperCase() === "INDIA" ? yellowDark : "#FFFFFF",
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor:
                          getCountryName(userIPLocation).toUpperCase() === "INDIA" ? yellowDark : "transparent",
                        },
                      }}
                    >
                      IND
                    </Button>
                  </FlexBetween>
                </FlexBetween>
              </FlexBetween>
            </Box>
          </Box>
  
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="1.5rem 0" />
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  
  export default DeveloperModePage;
  