import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin } from "./authActions";

// initialize userToken from local storage
// const userToken = localStorage.getItem('userToken')
//   ? localStorage.getItem('userToken')
//   : null

const initialState = {
  mode: "light",
  loading: false,
  userInfo: null,
  userToken: null,
  userProfileReference: null,
  error: null,
  success: false,
  userIPLocation: null,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken"); // delete token from storage
      localStorage.removeItem("userProfileReference"); // delete token from storage
      localStorage.removeItem("userIPLocation"); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.userProfileReference = null;
      state.error = null;
      state.userIPLocation = null;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      // state.user = action.payload.user;
      state.userToken = action.payload.userToken;
      // Only done here as doing static calling
      // localStorage.setItem('userToken', state.userToken)
    },
    setProfileReference: (state, action) => {
      // state.user = action.payload.user;
      state.userProfileReference = action.payload.userProfileReference;
      // Only done here as doing static calling
      // localStorage.setItem('userToken', state.userToken)
    },
    setUserIPLocation: (state, action) => {
      // state.user = action.payload.user;
      state.userIPLocation = action.payload.userIPLocation;
      // Only done here as doing static calling
      // localStorage.setItem('userToken', state.userToken)
    },
    changeUserLocationState: (state, action) => {
      if(state.userIPLocation && state.userIPLocation.ipapi){
        state.userIPLocation.ipapi.countryName = action.payload.locationData.countryName;
        state.userIPLocation.ipapi.city = action.payload.locationData.city;
      }
    }
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.userToken;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  logout,
  setCredentials,
  setMode,
  setLogin,
  setProfileReference,
  setWidget,
  setUserIPLocation,
  changeUserLocationState,
} = authSlice.actions;

export default authSlice.reducer;
