import { yellow } from "@mui/material/colors";

// color design tokens export
export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    150: "#EBEBF0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
    1100: "#7D8899",
    1200: "#808089",
    1300: "#D8DBE0",
    1400: "#B1B8C2",
    1500: "#F2F3F5",
  },
  primary: {
    50: "#E6FBFF",
    100: "#CCF7FE",
    200: "#99EEFD",
    300: "#66E6FC",
    400: "#33DDFB",
    500: "#38383D",
    600: "#00A0BC",
    700: "#006B7D",
    800: "#00353F",
    900: "#001519",
    1000: "#32363D",
    1100: "#191B1F",
    1200: "#38383D",
    1300: "#1C1B1F",
  },
  bitewise: {
    //why bitewise? this is our brand color
    0: "#FF751E",
  },
  orange: {
    50: "#FFAC78",
    100: "#FF751E",
    200: "#FC820A",
  },
  yellow: {
    50: "#FFF1E8",
    100: "#FFE3D2",
    200: "#FFF7DC",
    300: "#FFB700",
    400: "#FFFCED",
    500 : "#F5D489",
  },
  green: {
    50: "#EFFFF4",
    100: "#2DC26D",
    200: "#079449",
    300 : "#7DDCA6",
    400 : "#00AB56"
  },
  red: {
    40: "#FF99A0",
    50: "#FFF0F1",
    60: "#FF424E",
    100: "#D93843",
    200: "#910F17",
    300: "#580006",
  },
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode, //bitewise: not support dark mode
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            // primary: {
            //   dark: colorTokens.primary[200],
            //   main: colorTokens.primary[500],
            //   light: colorTokens.primary[800],
            // },
            // neutral: {
            //   dark: colorTokens.grey[100],
            //   main: colorTokens.grey[200],
            //   mediumMain: colorTokens.grey[300],
            //   medium: colorTokens.grey[400],
            //   light: colorTokens.grey[700],
            // },
            // background: {
            //   default: colorTokens.grey[900],
            //   alt: colorTokens.grey[800],
            // }
            primary: {
              dark: colorTokens.primary[700],
              main: colorTokens.primary[500],
              light: colorTokens.primary[50],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[10],
              alt: colorTokens.grey[0],
            },
            textColor: {
              red: colorTokens.red[100],
            },
            bitewise: {
              orange: colorTokens.bitewise[0],
            },
          }
        : {
            // palette values for light mode
            primary: {
              dark: colorTokens.primary[700],
              main: colorTokens.primary[500],
              light: colorTokens.primary[50],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[150],
              alt: colorTokens.grey[0],
              yellow: colorTokens.yellow[50],
              yellowDark: colorTokens.yellow[100],
              searchBar: colorTokens.grey[1500],
              profilePageHead: colorTokens.grey[1500],
              ratingGood: colorTokens.green[50],
              ratingMeh: colorTokens.yellow[200],
              ratingBad: colorTokens.red[50],
              ratingNotRated: colorTokens.grey[1500],
              ratingCaution: colorTokens.yellow[400],
              orangeDark: colorTokens.orange[100],
              grey: colorTokens.grey[1300],
              productDetail: colorTokens.grey[1500],
            },
            borderColor: {
              grey: colorTokens.grey[1300],
              chip: colorTokens.grey[150],
              red: colorTokens.red[60],
              green : colorTokens.green[100],
              yellow : colorTokens.yellow[300],
              darkGreen : colorTokens.green[200]
            },
            textColor: {
              red: colorTokens.red[100],
              main: colorTokens.primary[1100],
              mainSecondary: colorTokens.primary[1200],
              mainHeading: colorTokens.primary[1100],
              greyLight: colorTokens.grey[1100],
              greyDark: colorTokens.grey[1100],
              greySecondary: colorTokens.grey[1400],
              orangeLight: colorTokens.orange[50],
              orangeDark: colorTokens.orange[100],
              selectedTabColor: colorTokens.orange[200],
              ratingGood: colorTokens.green[100],
              ratingBest: colorTokens.green[200],
              ratingMeh: colorTokens.orange[200],
              ratingBad: colorTokens.red[100],
              ratingAvoid: colorTokens.red[200],
              ratingBestAvoid: colorTokens.red[300],
              ratingNotRated: colorTokens.grey[1100],
              ratingCaution: colorTokens.yellow[300],
              ratingAllergic: colorTokens.red[60],
              appBarTitle: colorTokens.primary[1300],
              riskColor: colorTokens.red[60],
              ratingBadLight: colorTokens.red[60],
              rateGoodNew: colorTokens.green[400]

            },
            bitewise: {
              orange: colorTokens.bitewise[0],
            },
          }),
    },
    typography: {
      fontFamily: ["Lexend", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 14,
      },
      h7: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 12,
      },
      h4FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 20,
      },

      h64: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 64,
      },
      h40: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 40,
      },
      h36: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 36,
      },
      h32: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 32,
      },
      h28: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 28,
      },
      h24: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 24,
      },
      h24FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 24,
      },
      h24Damion: {
        fontFamily: ["Damion", "sans-serif"].join(","),
        fontSize: 24,
      },
      h22: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 22,
      },
      h21: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 21,
      },
      h20: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 20,
      },
      h20FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 20,
      },
      h19: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 19,
      },
      h18: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 18,
      },
      h17: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 17,
      },
      h16: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 16,
      },
      h16Montserrat: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 16,
      },
      h16FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 16,
      },
      h15: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 15,
      },
      h15FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 15,
      },
      h14: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 14,
      },
      h13: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 13,
      },
      h13FuzzyBubbles: {
        fontFamily: ["Fuzzy Bubbles", "sans-serif"].join(","),
        fontSize: 13,
      },
      h12: {
        fontFamily: ["Lexend", "sans-serif"].join(","),
        fontSize: 12,
      },
      h12Montserrat: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        fontSize: 12,
      },
    },
  };
};
