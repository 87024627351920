import { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  Button,
  ButtonBase,
} from "@mui/material";
import { RadioGroup, Radio, Sheet } from "@mui/joy";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
  HelpOutline,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { styled } from "@mui/material/styles";
// import Logo from "components/Logo";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";
import { ReactComponent as BookOpen } from "../../../assets/book_open.svg";
import { ReactComponent as PersonCircle } from "../../../assets/person_circle.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "components/Debounce";
import { useTranslation } from "react-i18next";

const SearchNavbar = ({ onSearchInputChange, onEnterKeyDown }) => {
  const { t } = useTranslation();
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const { palette } = useTheme();

  const alt = palette.background.alt;
  const textMain = palette.textColor.main;
  const textMainHeading = palette.textColor.mainHeading;
  const textGreyLight = palette.textColor.greyLight;
  const textOrangeLight = palette.textColor.orangeLight;
  const textOrangeDark = palette.textColor.orangeDark;
  const borderGrey = palette.borderColor.grey;
  const backgroundSearchBar = palette.background.searchBar;
  const neutralLight = palette.neutral.light;

  const LanguageButton = styled(Button)({
    boxShadow: "none",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "transparent",
    },
  });

  const [value, setValue] = useState("");
  const [showClearIcon, setShowClearIcon] = useState(false);

  const debouncedRequestOnChange = useDebounce(() => {
    // send request to the backend
    // access to latest state here
    // console.log("SearchPage" + value);
    onSearchInputChange(value);
    // setPage(0)
  });

  const debouncedRequestOnKeyDown = useDebounce(() => {
    // send request to the backend
    // access to latest state here
    // console.log("EnterKeyDown" + value);
    onEnterKeyDown(value);
    // setPage(0)
  });

  const onChange = (e) => {
    const value = e ? e.target.value : "";
    setValue(value);
    if (!showClearIcon && value.length > 0) setShowClearIcon(true);
    else if (showClearIcon && value.length === 0) setShowClearIcon(false);
    // if(value.length > 3)
    debouncedRequestOnChange();
  };

  const inputRef = useRef(null);
  useEffect(() => {
    const input = inputRef.current;
    input.focus();
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      input.click();
    }
  }, []);

  const onEnterKey = (e) => {
    const value = e.target.value;
    setValue(value);
    // if (value.length > 3)
    debouncedRequestOnKeyDown();
  };
  const widthDesktop = isNonMobileScreens ? "30%" : "100%";
  const marginDesktop = isNonMobileScreens ? "auto" : "";
  const paddingMobile = isNonMobileScreens ? "" : "0.875rem";
  const borderRadius = isNonMobileScreens ? "none" : "9px";

  return (
    <FlexBetween
      width={widthDesktop}
      margin={marginDesktop}
      pt={paddingMobile}
      pb={paddingMobile}
      backgroundColor={isNonMobileScreens ? alt : "#F2F3F5"}
    >
      <FlexBetween gap="2rem" width={"100%"}>
        <Box
          display="flex"
          flexDirection="row"
          align="center"
          justifyContent="space-between"
          width="100%"
        >
          <FlexBetween
            backgroundColor="#F2F3F5"
            borderRadius={borderRadius}
            gap="1rem"
            width="100%"
            padding="0.1rem 0rem"
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon
                fontSize="medium"
                style={{
                  color: "#3D3C3D",
                  width: "1.725rem",
                  height: "1.5rem",
                }}
              />
            </IconButton>
            <InputBase
              fullWidth
              placeholder={t("searchPage.search_placeholder")}
              sx={{ pl: isNonMobileScreens ? "1rem" : "0rem", fullWidth: true }}
              inputProps={{ style: { fontSize: 20 }, maxLength: 49 }} // font size of input text
              onChange={onChange}
              value={value}
              ref={inputRef}
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === "Enter") {
                  // Do code here
                  onEnterKey(ev);
                  ev.preventDefault();
                }
              }}
            />
            {isNonMobileScreens && (
              <IconButton>
                <Search />
              </IconButton>
            )}
          </FlexBetween>
          {!isNonMobileScreens && showClearIcon && (
            <IconButton onClick={() => onChange(null)}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </FlexBetween>

      {/* {isNonMobileScreens && (
        <FlexBetween gap="1.5rem">
          <FlexBetween gap="1rem">
            <IconButton>
              <BookOpen style={{ color: textMainHeading }} />
            </IconButton>
            <IconButton>
              <HelpOutline
                style={{ color: textMainHeading, fontSize: "25px" }}
              />
            </IconButton>
            <IconButton>
              <PersonCircle style={{ color: textMainHeading }} />
            </IconButton>
          </FlexBetween>
        </FlexBetween>
      )} */}
    </FlexBetween>
  );
};

export default SearchNavbar;
