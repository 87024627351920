import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  styled,
  IconButton,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductChips from "components/ProductChips";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { Helmet } from "react-helmet";
import GenericNavBar from "../navbar/GenericNavBar";
import { ReactComponent as IngredientIcon } from "../../../assets/chemical.svg";
import { ReactComponent as DescriptionIcon } from "../../../assets/desIcon.svg";
import { ReactComponent as TagNameIcon } from "../../../assets/tagIcon.svg";
import { ReactComponent as ScienceIcon } from "../../../assets/source.svg";
import { ReactComponent as HumanIcon } from "../../../assets/humanIcon.svg";
import BlockIcon from "@mui/icons-material/Block";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CircleIcon from "@mui/icons-material/Circle";
import { useGetIngredientDetailQuery } from "app/services/auth/authService";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import { HOST_NAME } from "utils/constants";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { RatingWidget } from "scenes/widgets/CustomRisksAccordion";
import { useTranslation } from "react-i18next";
import { debugLog, getRatingBackgroundColor } from "utils/commonutils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import parse from "html-react-parser";
import FaqMenuGroupButton from "../navbar/FaqMenuGroupButton";
import ExpandableListTextView from "scenes/widgets/ExpandableListTextView";

const BoxVerticalWidget = styled(Box)(({ palette }) => ({
  backgroundColor: palette.background.alt,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "100%",
  padding: "1rem 0rem 1rem 0rem",
  borderRadius: "1rem",
  alignItems: "flex-start",
  gap: "0.75rem",
}));

export function getReferencesLinksText(references, t) {
  let uniqueClassName = "custom-reference-list";
  let styles = `
    <style>
      .${uniqueClassName} {
        color: rgb(37, 103, 235);
        padding-inline-start : 0px;
        padding: 10px 16px 16px 16px;
        margin : 0;
      }
      .${uniqueClassName} li {
        color: rgb(37, 103, 235);
      }
      .${uniqueClassName} li::marker {
        color: rgb(37, 103, 235);
        font-size: 14px;
      }
      .${uniqueClassName} a {
        color: rgb(37, 103, 235);
        font-size: 14px;
        margin: 0;
        font-family: Lexend, sans-serif;
        font-weight: 300;
      }
    </style>
  `;

  let value = `${styles}<ul class="${uniqueClassName}">`;
  let len = references.length;
  for (let i = 0; i < len; i++) {
    value +=
      "<li><a href='" +
      references[i] +
      "' target='_blank'>" +
      references[i] +
      "</a></li>";
  }
  value += `</ul>`;
  return value;
}

const IngredientDetailsPage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const dark = palette.neutral.dark;
  const backgrountWhite = palette.background.alt;
  const greyLight = palette.textColor.greyLight;
  const mainHeading = palette.textColor.mainHeading;
  const textAppBarTitle = palette.textColor.appBarTitle;
  const shapeStyles = { bgcolor: palette.neutral.main, width: 40, height: 40 };
  const rectangle = <Box component="span" sx={shapeStyles} />;
  const [ingredientTitle, setIngredientTitle] = useState("");
  let { ingredientId } = useParams();

  const {
    data: ingredient,
    isLoading,
    error,
  } = useGetIngredientDetailQuery({ id: ingredientId });
  function getShareData(data) {
    // Set share data
    if (data == null) return;
    return {
      type: "ingredient",
      title: "Share",
      text: data.name,
      url: HOST_NAME + "/ingredient/" + data.id,
    };
  }

  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar
      appBarBackgroundColor={backgrountWhite}
      showShareIcon={true}
      shareData={getShareData(ingredient)}
    />
  );

  debugLog("ingredient", ingredient);

  function getTypographyText(text) {
    return text && text.length > 0 ? text : t("ingredient.no_data");
  }

  function getTypographyColor(text) {
    return text && text.length > 0 ? mainHeading : greyLight;
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "ingredient",
    });
  }, []);
  // const nameWidget = "openHealthImpact";

  // const { openHealthImpact } = useSelector(
  //   (state) => state.bottomSheet.openBottomSheet
  // );

  // const [payload, setPayload] = useState({
  //   name: "openHealthImpact",
  //   isOpen: false,
  // });

  // const dispatch = useDispatch();
  // const [valueDetailHealthImpact, setValueDetailHealthImpact] = useState();
  // console.log("valueDetailHealthImpact", valueDetailHealthImpact);

  // useEffect(() => {
  //   dispatch(setWidget(payload));
  // }, [dispatch, payload]);

  // const handleClickOpen = (openWidgetName) => {
  //   setPayload({
  //     name: openWidgetName,
  //     isOpen: true,
  //   });
  // };
  // const handleClose = (openWidgetName) => {
  //   setPayload({
  //     name: openWidgetName,
  //     isOpen: false,
  //   });
  // };
  // const handleClick = (data) => {
  //   setValueDetailHealthImpact(data);
  // };
  debugLog("ingredient?.health_impacts", ingredient?.health_impacts);

  return (
    <Box>
      <Helmet
        title={
          ingredientTitle?.length > 0
            ? `${ingredientTitle} | Bitewise`
            : `Bitewise`
        }
      ></Helmet>

      {navBar}

      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>

        {isLoading ? (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            <LoadingWidget />
          </Box>
        ) : error ? (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            <Box
              sx={{
                width: "100%",
                height: "85vh",
              }}
            >
              <ErrorWidget error={error} />
            </Box>
          </Box>
        ) : (
          <Box
            flexBasis={isNonMobileScreens ? "30%" : undefined}
            width={isNonMobileScreens ? "30%" : undefined}
          >
            {isNonMobileScreens && (
              <FlexBetween
                gap="1rem"
                sx={{ justifyContent: "flex-between", background: "white" }}
              >
                <IconButton
                  onClick={() => {
                    if (
                      location.key === "default" ||
                      window.history.length <= 2
                    ) {
                      navigate(`/`);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <ArrowBackIcon
                    style={{
                      color: "#3D3C3D",
                      width: "1.725rem",
                      height: "1.5rem",
                    }}
                  />
                </IconButton>
                <Typography
                  variant="h20"
                  color={textAppBarTitle}
                  fontWeight="400"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <FaqMenuGroupButton
                    showShareIcon={true}
                    offDehazeIcon={isNonMobileScreens}
                    shareData={getShareData(ingredient)}
                  />
                </Typography>
              </FlexBetween>
            )}
            <Box>
              {/* FIRST ROW */}

              <FlexBetween
                gap="1rem"
                p="1rem 1.5rem 1rem 1.5rem"
                backgroundColor={backgrountWhite}
                sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              >
                <IngredientIcon style={{ width: "1.5rem", height: "1.5rem" }} />
                <FlexBetweenVertical
                  sx={{ alignItems: "flex-start" }}
                  gap="0.5rem"
                >
                  <Typography variant="h18" color={dark} fontWeight="600">
                    {ingredient?.name}
                  </Typography>
                  <Typography variant="h14" fontWeight="400" color={greyLight}>
                    {ingredient?.code}
                  </Typography>

                  <Box
                    padding="0.25rem 0.5rem 0.25rem 0.5rem"
                    backgroundColor={getRatingBackgroundColor(
                      ingredient?.rate_code,
                      palette
                    )}
                    borderRadius="0.5rem"
                  >
                    <RatingWidget
                      type={ingredient?.rate_code}
                      rate_code={ingredient?.rate_code}
                      tag_name={ingredient?.rate_code}
                      palette={palette}
                    />
                  </Box>
                  {/* <ProductDetailItemRating rating={ingredient?.rating} /> */}
                </FlexBetweenVertical>
              </FlexBetween>

              <FlexBetweenVertical gap="0.75rem" p="1rem" width="100%">
                {/* Description */}
                {ingredient?.short_description &&
                  ingredient?.short_description.length > 0 && (
                    <BoxVerticalWidget palette={palette} height={"100%"}>
                      <AccordionHeader
                        title={`${t("ingredient.description")}`}
                        icon={<DescriptionIcon />}
                      ></AccordionHeader>
                      <Divider
                        ml="-0.75rem"
                        mr="-0.75rem"
                        display="flex"
                        mt="0.75rem"
                        width="100%"
                      />
                      <Typography
                        variant="h14"
                        fontWeight="300"
                        pl="0.75rem"
                        pr="0.75rem"
                        color={getTypographyColor(
                          ingredient?.short_description
                        )}
                      >
                        {getTypographyText(ingredient?.short_description)}
                      </Typography>
                    </BoxVerticalWidget>
                  )}

                {/* OTHER NAMES AND USAGE */}

                {ingredient?.other_names &&
                  ingredient?.other_names.length > 0 && (
                    <BoxVerticalWidget
                      palette={palette}
                      sx={{ height: "100%", minHeight: "102px" }}
                    >
                      <AccordionHeader
                        title={`${t("ingredient.other_names")}`}
                        icon={<TagNameIcon />}
                      ></AccordionHeader>

                      <Divider
                        display="flex"
                        mt="0.75rem"
                        width="100%"
                        style={{ background: "#EBEBF0" }}
                      />
                      <ExpandableListTextView
                        dataItems={ingredient?.other_names}
                      />
                      {/* <ListItem data={ingredient?.other_names} palette={palette} t={t}/> */}
                    </BoxVerticalWidget>
                  )}

                {ingredient?.usage && ingredient?.usage.length > 0 ? (
                  <BoxVerticalWidget
                    palette={palette}
                    sx={{ height: "100%", minHeight: "102px" }}
                  >
                    <AccordionHeader
                      title={`${t("ingredient.usage")}`}
                      icon={<AdsClickIcon />}
                    ></AccordionHeader>
                    <Divider
                      display="flex"
                      mt="0.75rem"
                      width="100%"
                      style={{ background: "#EBEBF0" }}
                    />
                    <Typography
                      variant="h14"
                      fontWeight="300"
                      pl="0.75rem"
                      pr="0.75rem"
                      color={getTypographyColor(ingredient?.usage)}
                    >
                      {getTypographyText(ingredient?.usage)}
                    </Typography>
                  </BoxVerticalWidget>
                ) : (
                  getNoDataTypography(palette, t)
                )}

                {/* POTENTIAL HEALTH RISKS */}
                {/* {ingredient?.health_impacts && ingredient?.health_impacts.length>0 && ( */}
                <BoxVerticalWidget palette={palette} height={"100%"}>
                  <AccordionHeader
                    title={`${t("ingredient.health_impact")}`}
                    icon={<HumanIcon />}
                  ></AccordionHeader>
                  <Divider
                    display="flex"
                    mt="0.75rem"
                    width="100%"
                    style={{ background: "#EBEBF0" }}
                  />
                  {ingredient?.health_impacts &&
                  ingredient?.health_impacts.length > 0 ? (
                    <FlexBetweenVertical
                      pl="0.75rem"
                      pr="0.75rem"
                      sx={{ alignItems: "flex-start" }}
                    >
                      <ProductChips
                        ingredientDetailPage={true}
                        chipsList={ingredient?.health_impacts}
                      />
                    </FlexBetweenVertical>
                  ) : (
                    getNoDataTypography(palette, t)
                  )}
                </BoxVerticalWidget>
                <BoxVerticalWidget palette={palette} height={"100%"}>
                  <AccordionHeader
                    title={`${t("ingredient.scientific_sources")}`}
                    icon={<ScienceIcon />}
                  ></AccordionHeader>
                  <Divider
                    display="flex"
                    mt="0.75rem"
                    width="100%"
                    style={{ background: "#EBEBF0" }}
                  />

                  {ingredient?.references &&
                  ingredient?.references.length > 0 ? (
                    <Typography
                      variant="h14"
                      fontWeight="300"
                      pl="0.75rem"
                      pr="0.75rem"
                      color={mainHeading}
                      sx={{
                        wordWrap: "break-word",
                        maxWidth: {
                          xs: "20rem",
                          sm: "37.5rem",
                          md: "56.25rem",
                          lg: "20rem",
                          xl: "20rem",
                        },
                      }}
                    >
                      {parse(getReferencesLinksText(ingredient?.references, t))}
                    </Typography>
                  ) : (
                    getNoDataTypography(palette, t)
                  )}
                </BoxVerticalWidget>
                {/* )} */}

                {/* POSSIBLE FOOD USE */}
                {/* {ingredient?.possible_food_use &&
                  ingredient?.possible_food_use.length > 0 && (
                    <BoxVerticalWidget palette={palette}>
                      <AccordionHeader
                        title={`${t("ingredient.possible_food_uses")}`}
                        icon={<RestaurantIcon />}
                      ></AccordionHeader>
                      <Divider display="flex" mt="0.75rem" width="100%" style={{background:"#EBEBF0"}}/>
                      <Typography>{ingredient?.possible_food_use}</Typography>
                    </BoxVerticalWidget>
                  )} */}

                {/* BANNED IN */}
                {/* {ingredient?.banned_in && ingredient?.banned_in.length > 0 && ( */}
                <BoxVerticalWidget palette={palette}>
                  <AccordionHeader
                    title={`${t("ingredient.banned_in")}`}
                    icon={<BlockIcon />}
                  ></AccordionHeader>
                  <Divider
                    display="flex"
                    mt="0.75rem"
                    width="100%"
                    style={{ background: "#EBEBF0" }}
                  />
                  {ingredient?.banned_in && ingredient?.banned_in.length > 0 ? (
                    <ExpandableListTextView dataItems={ingredient?.banned_in} />
                  ) : (
                    getNoDataTypography(palette, t)
                  )}
                  {/* <ListItem data={ingredient?.banned_in} palette={palette} t={t}/> */}
                </BoxVerticalWidget>
                {/* )} */}
              </FlexBetweenVertical>
            </Box>
            {/* <DialogWidget
              open={openHealthImpact}
              onClose={() => handleClose(nameWidget)}
              dialogContent={
                <HealthImpactsDetailsPage
                  healthImpactsDetail={valueDetailHealthImpact}
                  onClose={() => handleClose(nameWidget)}
                />
              }
            /> */}
          </Box>
        )}

        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

function AccordionHeader({ icon, title }) {
  return (
    <FlexBetween pl="0.75rem" pr="0.75rem" gap="0.75rem">
      {icon}
      <Typography variant="h15" color="#3D3C3D" fontWeight="600">
        {title}
      </Typography>
    </FlexBetween>
  );
}

function ListItem({ data, palette, t }) {
  return data && data.length > 0
    ? data?.map((value) => (
        <Typography
          variant="h14"
          fontWeight="300"
          color={palette.textColor.mainHeading}
          key={value}
          style={{ marginLeft: "1rem" }}
        >
          <CircleIcon
            style={{
              width: "5px",
              height: "5px",
              marginRight: "0.5rem",
            }}
          />
          {value}
        </Typography>
      ))
    : getNoDataTypography(palette, t);
}

export function getNoDataTypography(palette, t) {
  return (
    <Typography
      variant="h14"
      fontWeight="300"
      pl="0.75rem"
      pr="0.75rem"
      color={palette.textColor.greyLight}
    >
      {t("ingredient.no_data")}
    </Typography>
  );
}

export default IngredientDetailsPage;
