import Navbar from "scenes/bitewise/navbar";
import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
  Divider,
  IconButton,
  ButtonBase,
  Zoom,
  Fab,
} from "@mui/material";
import SearchScanWidget from "scenes/widgets/SearchScanWidget";
import HomePageProductsViewPager from "scenes/widgets/HomePageProductsViewPager";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetProfileQuery } from "app/services/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import FlexBetween from "components/FlexBetween";
import { ReactComponent as FilterIcon } from "../../../assets/slider_icon.svg";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import map from "lodash/map";
import { useEffect, useState } from "react";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import NavBarDesktop from "../navbar/NavBarDesktop";
import { Helmet } from "react-helmet";
import DiscoveryWidget from "scenes/widgets/DiscoveryWidget";
import MostAskedQuestions from "components/MostAskedQuestions";
import DeveloperModeCounter from "utils/DeveloperModeCounter";
import { debugLog } from "utils/commonutils";
import { AddCircleOutlined } from "@mui/icons-material";
import { green } from "@mui/material/colors";

const fabStyle = {
  position: "fixed",
  bottom: 20,
  right: 24,
};

const fabGreenStyle = {
  color: "common.white",
  fontSize: "36px",
  bgcolor: "#FF751E",
  "&:hover": {
    bgcolor: "#FF751E",
  },
};

const BitewiseHomePage = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const textMain = palette.textColor.main;
  const textMainHeading = palette.textColor.mainHeading;
  const { userIPLocation } = useSelector((state) => state.auth);

  const textGreySecondary = palette.textColor.greySecondary;
  const textOrangeDark = palette.textColor.orangeDark;
  const borderGrey = palette.borderColor.grey;
  const backgrountWhite = palette.background.alt;
  const backgrountYellow = palette.background.yellow;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfileReference } = useSelector((state) => state.auth);
  const isAuth = Boolean(userProfileReference);

  const { isChangeHealth } = useSelector((state) => state.bottomSheet);

  const [developerModeCount, setDeveloperModeCount] = useState(0);

  const transitionDuration = {
    enter: 1,
    exit: 0,
  };

  const fab = {
    color: "inherit",
    sx: { ...fabStyle, ...fabGreenStyle },
    icon: <AddCircleOutlined />,
    label: "Expand",
  };

  const {
    data: profile,
    isLoading: profileIsLoading,
    error,
    refetch,
  } = useGetProfileQuery({}, { skip: !isAuth });

  useEffect(() => {
    debugLog("HomaPage: isAuth: " + isAuth);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "home",
    });
  }, []);

  const gotoHealthSettingsPage = () => {
    dispatch(
      setWidget({
        name: "openHealth",
        isOpen: true,
      })
    );
  };

  const incrementDeveloperCount = (value) => {
    debugLog("developerModeCount" + developerModeCount);
    setDeveloperModeCount(
      (prevDeveloperModeCount) => prevDeveloperModeCount + value
    );
  };

  const handleDeveloperMode = () => {
    incrementDeveloperCount(1);
  };

  const openDeveloperPopup = () => {
    navigate("/developer_hidden_page_bw");
    debugLog("Opening developer mode");
  };

  const navBar = isNonMobileScreens ? <NavBarDesktop /> : <Navbar />;

  useEffect(() => {
    if (isAuth) {
      refetch();
    }
  }, [isChangeHealth]);

  return (
    <Box>
      <DeveloperModeCounter
        count={developerModeCount}
        setCount={setDeveloperModeCount}
        openDeveloperPopup={openDeveloperPopup}
      />
      <Helmet title={`Bitewise`}></Helmet>
      {navBar}
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>

        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          width={isNonMobileScreens ? "30%" : undefined}
          minHeight={isNonMobileScreens ? "100vh" : undefined}
          backgroundColor={backgrountWhite}
          sx={{
            position: "relative",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Box>
            <Box
              padding="3rem 2rem 3rem 2rem"
              backgroundColor={backgrountYellow}
            >
              <Typography
                variant="h1"
                color={textMainHeading}
                fontWeight={900}
                fontSize={"32px"}
              >
                {t("home.header_bitewise")}
              </Typography>

              <Typography variant="h5" mt="1rem" fontWeight="400">
                <font color={textMain}>{t("home.bitewise_description_1")}</font>
                <b>
                  <font color={textOrangeDark}>
                    {t("home.bitewise_description_2")}
                  </font>
                </b>
                <font color={textMain}>{t("home.bitewise_description_3")}</font>
              </Typography>

              {/* SECOND CONTENT (Search Scan Widget) */}
              <SearchScanWidget showPlaceHolder={true} showNote={true} />

              {/* THIRD CONTENT (Products list carousel) */}
              <HomePageProductsViewPager />
            </Box>

            {userIPLocation &&
              userIPLocation.ipapi &&
              userIPLocation.ipapi.countryName.toUpperCase() !== "VIETNAM" && (
                <DiscoveryWidget isHomePage={true} />
              )}

            {/* FOURTH CONTENT (Personalized filter) */}

            <Box
              display="flex"
              flexDirection="column"
              padding="3rem 2rem 3rem 2rem"
              backgroundColor="#F2F3F5"
            >
              <Typography
                variant="h28"
                fontWeight="900"
                onClick={() => handleDeveloperMode()}
              >
                <font color={textMainHeading}>
                  {t("home.personalized_header_details_1")}
                </font>
                <b>
                  <font color={textOrangeDark}>
                    {t("home.personalized_header_details_2")}
                  </font>
                </b>
                <font color={textMainHeading}>
                  {t("home.personalized_header_details_3")}
                </font>
              </Typography>
              {/* <Typography
                variant="h24Damion"
                color={textMainHeading}
                fontWeight="400"
              >
                {t("home.personalized_justforyou")}
              </Typography> */}
              <Typography variant="h6" mt="0.75rem">
                {t("home.personalized_description")}
              </Typography>
              {isAuth &&
              profile &&
              profile.health_conditions &&
              profile.health_conditions.length > 0 ? (
                <WidgetWrapper
                  sx={{
                    mt: "1.25rem",
                    gap: "1rem",
                    padding: "1rem 1.5rem 1rem 1rem",
                    borderRadius: "1rem",
                    boxShadow: 1,
                  }}
                >
                  <FlexBetween>
                    <FlexBetween gap="0.5rem">
                      <IconButton>
                        <FilterIcon style={{ color: textMainHeading }} />
                      </IconButton>
                      <Typography
                        variant="h16"
                        fontWeight="600"
                        color={textMain}
                      >
                        {t("home.healthcondition_title")}
                      </Typography>
                    </FlexBetween>
                    <ButtonBase
                      mr="0.5rem"
                      onClick={() => gotoHealthSettingsPage()}
                    >
                      <Typography
                        variant="h16"
                        fontWeight="700"
                        color={textOrangeDark}
                      >
                        {t("home.healthcondition_edit")}
                      </Typography>
                    </ButtonBase>
                  </FlexBetween>

                  <Box m="1rem -1.5rem 1rem -1rem">
                    <Divider
                      display="flex"
                      width="100%"
                      style={{ background: "#EBEBF0" }}
                    />
                  </Box>
                  <FlexBetweenVertical
                    gap="0.75rem"
                    sx={{ alignItems: "flex-start" }}
                  >
                    <Typography
                      variant="h14"
                      fontWeight="400"
                      color={textGreySecondary}
                    >
                      {t("home.all_the_product_results_are_tailored_for")}
                    </Typography>
                    {map(profile.health_conditions, (item, index) => {
                      return (
                        <Typography
                          variant="h16"
                          fontWeight="500"
                          color={textMain}
                          key={index}
                        >
                          {item}
                        </Typography>
                      );
                    })}
                  </FlexBetweenVertical>
                </WidgetWrapper>
              ) : (
                <Button
                  id="sethealthcondition_btn"
                  variant="outlined"
                  onClick={() => gotoHealthSettingsPage()}
                  sx={{
                    border: 1.5,
                    backgroundColor: backgrountWhite,
                    borderColor: borderGrey,
                    height: "3rem",
                    mt: 1.25,
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: textMain,
                    justifyContent: "center",
                    textTransform: "none",
                    borderRadius: "1rem",
                  }}
                >
                  {t("home.healthcondition_set_button")}
                </Button>
              )}
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                padding: "36px 24px 46px 24px",
              }}
            >
              <MostAskedQuestions isHomeScreen={true} />
            </Box>
          </Box>

          {/* <Fab sx={fab.sx} aria-label={fab.label} color={fab.color} onClick={() => {
              navigate("/wisegpt");
            }}>
            {"💬"}
          </Fab> */}
        </Box>
        {isNonMobileScreens && <Box flexBasis="26%"></Box>}
      </Box>
    </Box>
  );
};

export default BitewiseHomePage;
