import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg";

const Question = ({ question = "How to read nutrition fact?" ,hasQuestion=false}) => {
  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: `1px solid ${hasQuestion ? "#D8DBE0" : "#FFE3D1"}`,
        padding: "8px 8px 8px 12px",
        display: "flex",
        justifyContent : "space-between",
        alignItems: "center",
        flex: "1 0",
        backgroundColor : "white"
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "17.5px",
          color: "#191B1F",
          width : "226px"
        }}
      >
        {question}
      </Typography>
      <ArrowIcon />
    </Box>
  );
};

export default Question;
