import { useGetSuggestionProductQuery } from "app/services/auth/authService";
import React from "react";
import { Avatar, Typography } from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import FlexStart from "components/FlexStart";
import { useTheme } from "@emotion/react";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNewHistoryToLocal } from "features/search/searchSlice";
import { useTranslation } from "react-i18next";

const SearchSuggest = () => {
  const { data, isLoading, error } = useGetSuggestionProductQuery();
  const navigate = useNavigate();
  const { palette, typography } = useTheme();
  const textGrayColor = palette.textColor.greySecondary;
  const textFont12 = typography.h7;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const renderSuggestList = () => {
    return data?.map((value, index) => {
      return (
        <FlexStart
          key={index}
          sx={{ gap: "0.75rem", cursor: "pointer" }}
          onClick={() => {
            dispatch(addNewHistoryToLocal(value));
            navigate(`/product/${value.id}`);
          }}
        >
          <Avatar
            src={value.main_image}
            style={{ width: "2rem", height: "2rem" }}
          />
          <Typography>{value.name}</Typography>
        </FlexStart>
      );
    });
  };
  return (
    <FlexBetweenVertical
      sx={{ alignItems: "flex-start", gap: "1.75rem", padding: "0rem 1.5rem" }}
    >
      <FlexBetween
        sx={{
          color: textGrayColor,
          gap: "4px",
        }}
      >
        <LightbulbIcon sx={{ width: "0.75rem", heigh: "0.75rem" }} />
        <Typography
          sx={{
            textTransform: "uppercase",
            fontSize: textFont12,
            fontWeight: 600,
            letterSpacing: "1px",
          }}
        >
          {t("searchPage.search_suggestions")}
        </Typography>
      </FlexBetween>
      {renderSuggestList()}
    </FlexBetweenVertical>
  );
};

export default SearchSuggest;
