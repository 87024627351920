import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";

const ErrorWiseGPT = () => {
  return (
    <Box
      sx={{
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box>
        <ErrorIcon />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          Something went wrong.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#32363D",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          Please try again{" "}
        </Typography>
      </Box>
      <Button
        sx={{
          textTransform: "none",
          fontWeight: 600,
          fontSize: "14px",
          padding: "8px 16px 8px 16px",
          border: "1.5px solid #D8DBE0",
          borderRadius: "16px",
        }}
        onClick={() => {
          window.location.reload();
        }}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default ErrorWiseGPT;
