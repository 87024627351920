import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderBottomSheet from "./HeaderBottomSheet";
import InfoIcon from "@mui/icons-material/Info";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";

const DisclaimerWidget = (props) => {
  const { palette } = useTheme();
  const backgrountWhite = palette.background.alt;
  const { t } = useTranslation();

  const icon = <InfoIcon style={{ color: "#079449" }} />;
  const { onClose } = props;
  return (
    <Box height="100%" backgroundColor={backgrountWhite}>
      <HeaderBottomSheet
        name={t("disclaimer.title")}
        icon={icon}
        close={onClose}
      />
      <FlexBetweenVertical sx={{ gap: "0.5rem", paddingTop: "0.25rem" }}>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph1_normal")}
        </Typography>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph2_title")}
        </Typography>
        <List
          component="ol"
          sx={{
            paddingY: 0,
          }}
        >
          <ListItem
            component="li"
            style={{
              textAlign: "justify",
            }}
          >
            <ListItemText primary={"i) " + t("disclaimer.paragraph2_i")} />
          </ListItem>
          <ListItem
            component="li"
            style={{
              textAlign: "justify",
            }}
          >
            <ListItemText primary={"ii) " + t("disclaimer.paragraph2_ii")} />
          </ListItem>
          <ListItem
            component="li"
            style={{
              textAlign: "justify",
            }}
          >
            <ListItemText primary={"iii) " + t("disclaimer.paragraph2_iii")} />
          </ListItem>
        </List>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph3_normal")}
        </Typography>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph4_normal")}
        </Typography>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph5_uppercase")}
        </Typography>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph6_normal")}
        </Typography>
        <Typography textAlign={"justify"}>
          {t("disclaimer.paragraph7_uppercase")}
        </Typography>
      </FlexBetweenVertical>
    </Box>
  );
};

export default DisclaimerWidget;
