import Navbar from "scenes/bitewise/navbar";
import {
  Box,
  Button,
  useMediaQuery,
  Typography,
  useTheme,
  IconButton,
  Divider,
  ButtonBase,
  CircularProgress,
} from "@mui/material";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import SearchScanWidget from "scenes/widgets/SearchScanWidget";
import { useGetProfileQuery } from "../../../app/services/auth/authService";
import HomePageProductsViewPager from "scenes/widgets/HomePageProductsViewPager";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { ReactComponent as FilterIcon } from "../../../assets/slider_icon.svg";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { ReactComponent as NoProductsIcon } from "../../../assets/no_products.svg";
import map from "lodash/map";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";

const locales = {
  en: { title: "English" },
  vi: { title: "Vietnamese" },
};

// function setLanguageInState

const ProfilePage = () => {
  //   const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { t, i18n } = useTranslation();
  // const [historyData,setHistoryData] = useState()

  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const textMain = palette.textColor.main;
  const textMainHeading = palette.textColor.mainHeading;
  const textGreyLight = palette.textColor.greyLight;
  const textGreySecondary = palette.textColor.greySecondary;
  const textOrangeLight = palette.textColor.orangeLight;
  const textOrangeDark = palette.textColor.orangeDark;
  const borderGrey = palette.borderColor.grey;
  const dark = palette.neutral.dark;
  const backgroundWhite = palette.background.alt;
  const backgrountYellow = palette.background.yellow;
  const backgroundProfilePageHead = palette.background.profilePageHead;

  const {
    data: profile,
    isLoading: profileIsLoading,
    error,
  } = useGetProfileQuery();

  const navigate = useNavigate();

  // console.log("ArsyHeaderLanguage: " + i18n.language)

  const gotoHealthSettingsPage = () => {
    navigate(`/health_condition_setting`);
  };

  const productListItems = [{}, {}, {}];

  return (
    <Box>
      <Navbar />

      {profileIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <LoadingWidget />
        </Box>
      ) : error ? (
        <ErrorWidget error={error} />
      ) : (
        <Box
          width="100%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
          <Box
            flexBasis={isNonMobileScreens ? "42%" : undefined}
            width={isNonMobileScreens ? "42%" : undefined}
          >
            {/* (Personalized filters) */}
            <Box
              display="flex"
              flexDirection="column"
              padding="1.5rem 1.5rem 2rem 1.5rem"
              backgroundColor={backgroundProfilePageHead}
            >
              {profile.health_conditions &&
              profile.health_conditions.length > 0 ? (
                <Box ml="0.5rem">
                  <Typography
                    variant="h24"
                    fontWeight="900"
                    color={textMainHeading}
                  >
                    {"Personalize"}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h28" fontWeight="900">
                    <font color={textMainHeading}>Get</font>
                    <b>
                      <font color={textOrangeDark}> personalized</font>
                    </b>
                    <font color={textMainHeading}> results.</font>
                  </Typography>
                  <Typography
                    variant="h24Damion"
                    color={textMainHeading}
                    fontWeight="400"
                  >
                    {"just for you!"}
                  </Typography>
                  <Typography variant="h6" mt="0.75rem">
                    {
                      "If you have allergies 🥜, heart diseases 💔, on pregnancy 🤰🏻, or finding good products for your kids 👶, ... Bitewise can personalize the feedback to fit your health condition!"
                    }
                  </Typography>
                </Box>
              )}

              <WidgetWrapper
                sx={{
                  mt: "0.75rem",
                  gap: "1rem",
                  padding: "1rem 1.5rem 1rem 1rem",
                  borderRadius: "1rem",
                  boxShadow: 1,
                }}
              >
                <FlexBetween>
                  <FlexBetween gap="0.5rem">
                    <IconButton>
                      <FilterIcon style={{ color: textMainHeading }} />
                    </IconButton>
                    <Typography variant="h16" fontWeight="600" color={textMain}>
                      {"Health condition"}
                    </Typography>
                  </FlexBetween>
                  <ButtonBase
                    mr="0.5rem"
                    onClick={() => gotoHealthSettingsPage()}
                  >
                    <Typography
                      variant="h16"
                      fontWeight="700"
                      color={textOrangeDark}
                    >
                      {"Edit"}
                    </Typography>
                  </ButtonBase>
                </FlexBetween>

                <Box m="1rem -1.5rem 1rem -1rem">
                  <Divider display="flex" width="100%" />
                </Box>

                {profile.health_conditions &&
                profile.health_conditions.length > 0 ? (
                  <FlexBetweenVertical
                    gap="0.75rem"
                    sx={{ alignItems: "flex-start" }}
                  >
                    <Typography
                      variant="h14"
                      fontWeight="400"
                      color={textGreySecondary}
                    >
                      {"All the product results are tailored for:"}
                    </Typography>
                    {map(profile.health_conditions, (item, index) => {
                      return (
                        <Typography
                          variant="h16"
                          fontWeight="500"
                          color={textMain}
                          key={index}
                        >
                          {item}
                        </Typography>
                      );
                    })}
                  </FlexBetweenVertical>
                ) : (
                  <Box ml="0.5rem">
                    <Typography
                      variant="h14"
                      fontWeight="400"
                      color={textGreySecondary}
                    >
                      {"No allergen or health condition selected."}
                    </Typography>
                  </Box>
                )}

                {/* <AccordionFooter healthRiskItem={healthRiskItem} isActive={isActive} palette={palette} navigate={navigate}/> */}
                {/* <div className="accordion-item">
                    <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                        <div>{title}</div>
                        <div>{isActive ? '-' : '+'}</div>
                    </div>
                    {isActive && <div className="accordion-content">{content}</div>}
                    </div> */}
              </WidgetWrapper>
            </Box>

            {/* SEARCH HISTORY  */}
            <FlexBetweenVertical
              gap="1.5rem"
              p="1.5rem 1.5rem 1.5rem 1.5rem"
              sx={{
                backgroundColor: backgroundWhite,
                alignItems: "flex-start",
              }}
            >
              <Box ml="0.5rem">
                <Typography
                  variant="h24"
                  fontWeight="900"
                  color={textMainHeading}
                >
                  {"History"}
                </Typography>
              </Box>

              <EmptyHistoryView palette={palette} />
            </FlexBetweenVertical>
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="1.5rem 0" />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

function EmptyHistoryView({ palette }) {
  return (
    <FlexBetweenVertical
      width="100%"
      gap="0.5rem"
      p="2rem 1.5rem 2rem 1.5rem"
      sx={{
        border: 1.5,
        borderColor: palette.borderColor.grey,
        borderRadius: "1rem",
        borderStyle: "dashed",
        justifyContent: "flex-start",
      }}
    >
      <FlexBetween justifyContent="flex-start" gap="0.5rem">
        <Typography
          variant="h16"
          fontWeight="500"
          color={palette.textColor.main}
        >
          {"Nothing here"}
        </Typography>
        <NoProductsIcon style={{ width: "1rem", height: "1rem" }} />
      </FlexBetween>
      <Typography
        variant="h14"
        fontWeight="400"
        color={palette.textColor.greyLight}
      >
        {"No allergen or health condition selected."}
      </Typography>
    </FlexBetweenVertical>
  );
}

export default ProfilePage;
