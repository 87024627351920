import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useGetSearchIngredientListQuery } from "app/services/auth/authService";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import React, { useEffect, useState } from "react";
import IngredientListWidget from "./IngredientListWidget";
import { ReactComponent as NoIngredientsIcon } from "../../../assets/no_ingredients.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import { useDispatch, useSelector } from "react-redux";
import DialogWidget from "scenes/widgets/DialogWidget";
import DisclaimerWidget from "scenes/widgets/DisclaimerWidget";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";
import DisclaimerIcon from "./../../../assets/disclaimer_infor.svg";

function SearchIngredientListViewPagi({ searchValue, ingredientCount }) {
  const { palette } = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [dataShow, setDataShow] = useState();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const heightGenericNavBar = isNonMobileScreens ? "0px" : "72px";
  const heightTabAndGap = "60px";
  const totalOtherContentHeight = "80px";

  const { t } = useTranslation();

  const heightContent = `calc(100vh - ${heightGenericNavBar}`;
  const heightBox = `calc(100vh - ${heightGenericNavBar} - ${totalOtherContentHeight} - ${heightTabAndGap})`;

  const { data, isLoading, isFetching, isUninitialized, error } =
    useGetSearchIngredientListQuery(
      { searchValue, page, pageSize },
      {
        skip: searchValue === "" || ingredientCount <= 0,
      }
    );

  const handleItemContent = (index) => {
    return (
      <IngredientListWidget key={index} ingredientItem={dataShow[index]} />
    );
  };

  const [loadingMore, setLoadingMore] = useState(false);

  const loadMoreItems = () => {
    if (!loadingMore && pageSize * (page + 1) < ingredientCount) {
      setLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (data?.length > 0 && dataShow?.length > 0) {
      setDataShow((preData) => [...preData, ...data]);
    } else if (data?.length > 0) {
      setDataShow(data);
    }
    setLoadingMore(false);
  }, [data]);

  const { openDisclaimer } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );

  const [payload, setPayload] = useState({
    name: "openHealthImpact",
    isOpen: false,
  });

  const dispatch = useDispatch();

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };
  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const textColorGreySecondary = palette.textColor.greyLight;

  // const data = [1, 2, 3]
  const totalPages = Math.ceil(ingredientCount / pageSize);
  if (isLoading) {
    return (
      <Box>
        <LoadingWidget />
      </Box>
    );
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (ingredientCount === 0 && !data) {
    return <EmptyView palette={palette} searchValue={searchValue} />;
  }

  return (
    totalPages && (
      <Box
        sx={{
          height: heightContent,
        }}
      >
        <Box
          sx={{
            marginBottom: "0.3rem",
            paddingX: "1rem",
          }}
        >
          <Typography
            sx={{ marginBottom: "0.3rem" }}
            variant="h11"
            fontWeight="400"
            color={textColorGreySecondary}
          >
            {t("productDetail.healthimpacts_subtitle")}
            <IconButton
              sx={{
                display: "inline",
                marginLeft: "0.3rem",
                fontSize: "10px",
                padding: "0",
              }}
              onClick={() => {
                handleClickOpen("openDisclaimer");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                color="#7D8899"
              >
                <g clip-path="url(#clip0_3828_964)">
                  <path
                    d="M5 4.5C5.27614 4.5 5.5 4.72386 5.5 5L5.5 7C5.5 7.27614 5.27614 7.5 5 7.5C4.72386 7.5 4.5 7.27614 4.5 7L4.5 5C4.5 4.72386 4.72386 4.5 5 4.5Z"
                    fill="#7D8899"
                  />
                  <path
                    d="M5 3.75C5.34518 3.75 5.625 3.47018 5.625 3.125C5.625 2.77982 5.34518 2.5 5 2.5C4.65482 2.5 4.375 2.77982 4.375 3.125C4.375 3.47018 4.65482 3.75 5 3.75Z"
                    fill="#7D8899"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1Z"
                    fill="#7D8899"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3828_964">
                    <rect width="10" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
          </Typography>
        </Box>
        <FlexBetweenVertical gap="1rem">
          {dataShow && dataShow.length > 0 ? (
            <Box sx={{ height: "100%", width: "100%" }}>
              {dataShow && (
                <Virtuoso
                  style={{
                    height: heightBox,
                    width: "100%",
                    borderRadius: "1rem",
                  }}
                  data={dataShow}
                  itemContent={handleItemContent}
                  endReached={loadMoreItems}
                />
              )}
            </Box>
          ) : (
            <EmptyView palette={palette} searchValue={searchValue} />
          )}
        </FlexBetweenVertical>
        <DialogWidget
          open={openDisclaimer}
          onClose={() => handleClose("openDisclaimer")}
          dialogContent={
            <DisclaimerWidget onClose={() => handleClose("openDisclaimer")} />
          }
        />
      </Box>
    )
  );
}

function EmptyView({ palette, searchValue }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <FlexBetweenVertical
      height="100vh"
      gap="1.5rem"
      mt="-1.875rem"
      ml="5rem"
      mr="5rem"
      sx={{ justifyContent: "center" }}
    >
      <NoIngredientsIcon />
      <Typography
        align="center"
        variant="h20"
        color={palette.textColor.mainHeading}
        fontWeight="600"
      >
        {t("searchDetail.noingredient_title")} “{searchValue}”
      </Typography>
      <FlexBetweenVertical
        gap="1.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
      >
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noingredient_subtitle")}
        </Typography>
      </FlexBetweenVertical>

      <Button
        variant="contained"
        onClick={() => navigate(-1)} //Go one step back as previous page was search page
        sx={{
          backgroundColor: palette.background.orangeDark,
          height: "2.5rem",
          mt: 1.25,
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          justifyContent: "center",
          textTransform: "none",
          borderRadius: "1rem",
          "&:hover": {
            backgroundColor: palette.background.orangeDark,
            boxShadow: "none",
          },
        }}
      >
        {t("searchDetail.button_backtosearch")}
      </Button>
    </FlexBetweenVertical>
  );
}

export default SearchIngredientListViewPagi;
