import { Verified } from "@mui/icons-material";
import { Box, Typography, useTheme, Button } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import AlternativeProductItemRating from "components/AlternativeProductItemRating";
import { useTranslation } from "react-i18next";

const AlternativeProductWidget = ({
  productItem,
  style,
  widthEle,
  showBoxShadow = false,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainSecondary = palette.textColor.mainSecondary;
  const greyDark = palette.textColor.greyDark;
  
  const borderGrey = palette.borderColor.grey;
  const textMainHeading = palette.textColor.mainHeading;
  // console.log("AlternativeProductItem: ", productItem);

  function gotoProductDetailsPage(productId) {
    navigate(`/product/${productId}`);
  }

  return (
    <Box padding="0.5rem" >
      <WidgetWrapper
        maxWidth="11.625rem"
        mb="0.125rem"
        onClick={() => gotoProductDetailsPage(productItem.id)}
        sx={{ borderRadius: "1.5rem",
              flexBasis: style,
              width: widthEle,
              boxShadow: '2px 4px 10px 0px rgb(0 0 0 / 15%)',
              pl:"0.75rem",
              pr:"0.75rem"}}
      >
        {/* FIRST ROW */}

        <FlexBetweenVertical
          sx={{
            justifyContent: "center",
          }}
        >
          <img
            style={{ objectFit: "cover", borderRadius: "0.75rem" }}
            width="90px"
            height="90px"
            alt="Image not found"
            // onerror="this.onerror=null; this.src='http://placekitten.com/100/100';"
            src={productItem.main_image}
          />
          <Box
            mt="0.75rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="h14"
              color={mainSecondary}
              fontWeight="500"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                height : "42px"
              }}
            >
              {productItem.name}
            </Typography>
            <Typography 
              variant="h13"
              color={greyDark}
              fontWeight="400"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
              mb="0.75rem">
              {productItem.brand}
            </Typography>
            <AlternativeProductItemRating
              rating={productItem.rating}
              score={productItem.overall_score}
            />
          </Box>

          {/* <Button
            id="alternative_moredetail_btn"
            variant="outlined"
            sx={{
              border: 1.5,
              borderColor: borderGrey,
              mt: 1.25,
              fontSize: "13.5px",
              fontWeight: "600",
              color: textMainHeading,
              justifyContent: "center",
              textTransform: "none",
              borderRadius: "1rem",
              pt: "0.5rem",
              pb: "0.5rem",
              pl: "1rem",
              pr: "1rem"
            }}
          >
            {t("productDetail.alternative_moredetails")}
          </Button> */}
        </FlexBetweenVertical>
      </WidgetWrapper>
    </Box>
  );
};

export default AlternativeProductWidget;
