import React, { useState, useRef, useEffect } from "react";
import { Box, Chip, Paper, styled, Typography, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CategoryAllDiscoveryIcon } from "../../assets/categories_all_discovery.svg";
import {
  resetPageInit,
  resetProductsListBiteBoard,
  setSelectedProductSubCategory,
  setWidget,
} from "features/bottomSheet/bottomSheetSlice";
import { useTranslation } from "react-i18next";

export const StyledChip = styled(Chip)(({ theme, isActive }) => ({
  color: isActive ? "#FF751E" : "#27272A",
  fontSize: 12,
  backgroundColor: isActive ? "#FFF1E9" : "white",
  borderColor: isActive ? "#FF751E" : "#DDDDE3",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "16px",
  fontWeight: 400,

  "&:hover": {
    color: "#FF751E",
    borderColor: "#FF751E",
    backgroundColor: "white",
  },
  "&:focus": {
    color: "#FF751E",
    borderColor: "#FF751E",
  },
  "&:active": {
    color: "#FF751E",
    borderColor: "#FF751E",
  },
}));

function sendEvent(
  eventName,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    eventProps: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
    },
  });
}

const SubCategoriesListHorizontalWidget = ({
  categoriesList,
  isHomePage = false,
  isSearchPage = false,
}) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const { selectedProductSubCategory } = useSelector(
    (state) => state.bottomSheet
  );
  const subCategoryContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  //   console.log("Received CategoryList "+ categoriesList);
  categoriesList = categoriesList
    .slice()
    .sort((a, b) => b.product_count - a.product_count);

  const handleScroll = (event) => {
    setScrollLeft(event.target.scrollLeft);
  };

  const openCategorySelectionBottomSheet = () => {
    if (isHomePage)
      sendEvent("categoryList", "homePage", "openBottomSheet", "");
    else if (isSearchPage)
      sendEvent("categoryList", "searchPage", "openBottomSheet", "");
    else sendEvent("categoryList", "discoveryPage", "openBottomSheet", "");

    dispatch(
      setWidget({
        name: "openCategoryListing",
        isOpen: true,
      })
    );
  };

  const onClickSelectCategory = (categoryItem) => {
    if (selectedProductSubCategory?.id !== categoryItem.id) {
      dispatch(resetProductsListBiteBoard());
      dispatch(setSelectedProductSubCategory(categoryItem));
      dispatch(resetPageInit());
    }
    if (isHomePage)
      sendEvent("categoryList", "homePage", "clickCategory", categoryItem.name);
    else if (isSearchPage)
      sendEvent(
        "categoryList",
        "searchPage",
        "clickCategory",
        categoryItem.name
      );
    else
      sendEvent(
        "categoryList",
        "discoveryPage",
        "clickCategory",
        categoryItem.name
      );
  };

  useEffect(() => {
    const container = subCategoryContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    // Scroll to the selected category when it changes
    if (selectedProductSubCategory && container) {
      const selectedCategoryElement = container.querySelector(
        `[data-sub-category-id="${selectedProductSubCategory.id}"]`
      );

      if (selectedCategoryElement) {
        container.scrollTo({
          left: selectedCategoryElement.offsetLeft - container.offsetLeft - 5,
          behavior: "instant",
        });
      }
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedProductSubCategory]);

  useEffect(() => {
    // console.log("categoriesListarsy "+categoriesList);

    if (
      selectedProductSubCategory?.id == -1 &&
      categoriesList &&
      categoriesList.length > 0
    ) {
      dispatch(setSelectedProductSubCategory(categoriesList[0]));
    }
  }, [categoriesList]);

  return (
    // <AppBar position='static' sx={{ backgroundColor: "white" }}>
    // 	<Toolbar>
    <Box
      width="100%"
      sx={{
        display: "flex",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          marginRight: "1rem",
          marginBottom: "1.4rem",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Button
            startIcon={<CategoryAllDiscoveryIcon />}
            onClick={() => openCategorySelectionBottomSheet()}
            sx={{
              fontSize: "14px",
              color: "#27272A",
              justifyContent: "center",
              textTransform: "none",
              borderRadius: "1rem",
              width: i18n.language === "vi" ? "82px" : "64px",
            }}
          >
            {t("discovery.categories_all")}
          </Button>
        </Box> */}
      {/* <Divider orientation="vertical" display="flex" height="100%" style={{background:"#000000"}}/> */}
      {/* <Typography
          variant="h22"
          color="#D8DBE0"
          fontWeight="300"
          marginLeft="0.4rem"
        >
          {"|"}
        </Typography>
      </Box> */}
      <Paper
        sx={{
          display: "flex",
          overflowX: "auto",
          backgroundColor: "transparent",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            display: "none",
          },
          boxShadow: "none",
        }}
        ref={subCategoryContainerRef}
      >
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            backgroundColor: "transparent",
          }}
        >
          {categoriesList.map((categoryItem, index) => {
            return (
              <StyledChip
                key={index}
                label={categoryItem.name}
                onClick={() => onClickSelectCategory(categoryItem)}
                isActive={categoryItem.id === selectedProductSubCategory.id}
                data-sub-category-id={categoryItem.id} // Add data attribute for selecting the element later
              />
            );
          })}
        </Box>
      </Paper>
    </Box>
    // 	</Toolbar>
    // </AppBar>
  );
};

export default SubCategoriesListHorizontalWidget;
