import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import HeaderBottomSheet from "./HeaderBottomSheet";
import InfoIcon from "@mui/icons-material/Info";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";

const DisclaimerWiseGPT = (props) => {
  const { palette } = useTheme();
  const backgrountWhite = palette.background.alt;
  const { t } = useTranslation();

  const icon = <InfoIcon style={{ color: "#079449" }} />;
  const { onClose } = props;
  return (
    <Box height="100%" backgroundColor={backgrountWhite}>
      <HeaderBottomSheet
        name={"Disclaimer"}
        icon={icon}
        close={onClose}
      />
      <FlexBetweenVertical sx={{ gap: "1.25rem", paddingTop: "0.25rem" }}>
        <Typography>
          While our LLM-based chat model strives to provide accurate
          information, please note that the world of AI is evolving and
          responses may occasionally be incorrect or outdated. We encourage
          users to verify important information with trusted sources for
          complete assurance.
        </Typography>
      </FlexBetweenVertical>
    </Box>
  );
};

export default DisclaimerWiseGPT;
