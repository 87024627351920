import { Box, Button, Typography } from "@mui/material";
import { useGetSearchProductListQuery } from "app/services/auth/authService";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import React, { useState } from "react";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import { ReactComponent as NoProductsIcon } from "../../../assets/no_products.svg";
import { useTheme } from "@emotion/react";
import { ReactComponent as InfoIcon } from "../../../assets/info_icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingWidget from "scenes/widgets/LoadingWidget";

const SearchProductsListView = ({ searchValue, productCount }) => {
  const { palette } = useTheme();
  const [page, setPage] = useState(0);
  const [showPageNavigator, setShowPageNavigator] = useState(false);
  const textColorGreySecondary = palette.textColor.greySecondary;
  const pageSize = 20;
  const {
    data: data,
    isLoading,
    isFetching,
    isUninitialized,
    error,
  } = useGetSearchProductListQuery(
    {
      searchValue: encodeURIComponent(searchValue)
        .replace(/%E2%80%99/g, "%27")
        .replace(/%E2%80%98/g, "%27")
        .replace(/%E2%80%B2/g, "%27")
        .replace(/%20%25/g, "%27")
        .replace(/%25%20/g, "%27"),
      page,
      pageSize,
    },
    {
      skip: searchValue === "" || productCount <= 0,
    }
  );

  // const data = [1, 2, 3]
  const totalPages = Math.ceil(productCount / pageSize);
  if (isLoading) {
    return (
      <Box>
        <LoadingWidget />
      </Box>
    );
  }

  if (error) {
    // console.log(error)
    return (
      <Box>
        <h1>({error.status}) Could not load data</h1>
        <p className="error">{error.data ? error.data.title : ""}</p>
      </Box>
    );
  }

  //Dom't show if query is not initiaoized
  if (productCount === 0 && !data) {
    // return <div>No products found!</div>
    return <EmptyView palette={palette} searchValue={searchValue} />;
  }

  return (
    totalPages && (
      <Box>
        <FlexBetweenVertical gap="1rem">
          <FlexBetween
            width="100%"
            gap="0.75rem"
            mt="0.25rem"
            sx={{ justifyContent: "flex-start" }}
          >
            <InfoIcon
              fill="red"
              style={{ textColor: "red", width: "0.75rem", height: "0.75rem" }}
            />
            <Typography
              variant="h12"
              color={textColorGreySecondary}
              fontWeight="400"
            >
              {
                "The products below contain ingredients that may pose the following risks or benefits"
              }
            </Typography>
          </FlexBetween>
          {data.map((item, index) => {
            return <ProductListItemWidget productItem={item} />;
          })}
        </FlexBetweenVertical>
        <FlexBetween>
          <Button
            onClick={() => setPage((prev) => prev - 1)}
            // isLoading={isFetching}
            disabled={page === 1}
          >
            {/* <Icon as={MdArrowBack} /> */}
          </Button>
          <Box>{`${page} / ${totalPages}`}</Box>
          <Button
            onClick={() => setPage((prev) => prev + 1)}
            // isLoading={isFetching}
            disabled={page === totalPages}
          >
            {/* <Icon as={MdArrowForward} /> */}
          </Button>
        </FlexBetween>
      </Box>
    )
  );
};

function EmptyView({ palette, searchValue }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FlexBetweenVertical
      height="100vh"
      gap="1.5rem"
      mt="-1.875rem"
      ml="5rem"
      mr="5rem"
      sx={{ justifyContent: "center" }}
    >
      <NoProductsIcon />
      <Typography
        align="center"
        variant="h20"
        color={palette.textColor.mainHeading}
        fontWeight="600"
      >
        {t("searchDetail.noproduct_title")} “{searchValue}”
      </Typography>
      <FlexBetweenVertical
        gap="1.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
      >
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noproduct_subtitle_1")}
        </Typography>
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noproduct_subtitle_2")}
        </Typography>
      </FlexBetweenVertical>
      <Button
        variant="contained"
        onClick={() => navigate(-1)} //Go one step back as previous page was search page
        sx={{
          backgroundColor: palette.background.orangeDark,
          height: "2.5rem",
          mt: 1.25,
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          justifyContent: "center",
          textTransform: "none",
          borderRadius: "1rem",
          "&:hover": {
            backgroundColor: palette.background.orangeDark,
            boxShadow: "none",
          },
        }}
      >
        Back to search
      </Button>
    </FlexBetweenVertical>
  );
}

export default SearchProductsListView;
