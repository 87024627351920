import { Box, Typography } from "@mui/material";
import parse from "html-react-parser";
import LoadingGPT from "./LoadingGPT";
const AnswerGPTMarkdown = ({
  answer = [],
  isLoading = false,
  isAsking = false,
}) => {
  if (isAsking && isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
        }}
      >
        <LoadingGPT />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "8px",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "11px",
          color: "#7D8899",
          lineHeight: "1.5rem",
          letterSpacing: "10%",
          textAlign: "end",
          textTransform: "uppercase",
        }}
      >
        Wisegpt
      </Typography>
      <Box
        sx={{
          width: 300,
          backgroundColor: "#FFF1E8",
          padding: "1rem",
          borderRadius: " 12px",
          position: "relative",
        }}
      >
        {answer && answer.length && (
          <div className="wisegpt_response">
            {answer.map((item, index) => {
              return <div key={index}>{parse(item)}</div>;
            })}
          </div>
        )}
        {/* {formattedAnswer && (
            <Box
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "10px",
                zIndex: 100,
              }}
            >
              <IconButton>
                <ThumbUpIcon color={`white`} />
              </IconButton>
              <IconButton>
                <ThumbDownIcon color={`dark`} />
              </IconButton>
            </Box>
          )} */}
      </Box>
    </Box>
  );
};

export default AnswerGPTMarkdown;
