export class GTMAnalytics {
    static EventName = class {
        static Click = "click"
        static HealthConditionClick = "changeHealthCondition"
        static AddToHomeScreen = "addToHomeScreen";

        
    }

    static EventCategory = class {
        static ScanSearch = "searchScan"
        static HealthConditionClick = "healthConditionClick"
        static Engagement = "engagement";

    }

    static EventAction = class {
        static Scan = "Scan"
        static Search = "Search"
        static Change = "change"
        static Add = "Add";

    }
    static sendEvent(eventName, eventCategory, eventAction, eventLabel, eventValue, eventId){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventName,
            eventProps: {
                category: eventCategory,
                action: eventAction,
                label: eventLabel,
                value: eventValue,
                id: eventId
            }
        });
    }
}