import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openBottomSheet: {
    openMenu: false,
    openFaq: false,
    openHealth: false,
    openAddToHome: false,
    openDisclaimer: false,
    openHealthImpact: false,
    openCategoryListing: false,
    openHealthImpactBiteBoard: false,
  },
  isChangeHealth: false,
  selectedProductCategory: { id: -1 },
  selectedProductSubCategory: { id: -1 },
  productCategoryList: [],
  pageInit: 0,
  productsListBiteBoard: [],
  isRefetchHistory: false,
  isRefetchSearch: false,
  isActiveAccordionPDP: false,
  productBiteBoardClick: null,
  countProductCategory: 0,
};

const bottomSheetSlice = createSlice({
  name: "bottomSheet",
  initialState,
  reducers: {
    setWidget: (state, action) => {
      const { name, isOpen } = action.payload;
      state.openBottomSheet[name] = isOpen;
    },
    changeHealthCondition: (state) => {
      state.isChangeHealth = !state.isChangeHealth;
    },
    setSelectedProductCategory: (state, action) => {
      state.selectedProductCategory = action.payload;
    },
    setSelectedProductSubCategory: (state, action) => {
      state.selectedProductSubCategory = action.payload;
    },
    setProductCategoryList: (state, action) => {
      state.productCategoryList = action.payload.categoryList;
    },
    resetPageInit: (state) => {
      state.pageInit = 0;
    },
    changePageInit: (state) => {
      state.pageInit = state.pageInit + 1;
    },
    resetProductsListBiteBoard: (state) => {
      state.productsListBiteBoard = [];
    },
    updateProductsListBiteBoard: (state, action) => {
      if (
        action.payload?.length > 0 &&
        state.productsListBiteBoard.length > 0
      ) {
        state.productsListBiteBoard = [
          ...state.productsListBiteBoard,
          ...action.payload,
        ];
      } else if (action.payload?.length > 0) {
        state.productsListBiteBoard = action.payload;
      }
    },
    setIsRefetchHistory: (state, action) => {
      state.isRefetchHistory = action.payload;
    },
    setIsRefetchSearch: (state, action) => {
      state.isRefetchSearch = action.payload;
    },
    setIsActiveAccordionPDP: (state, action) => {
      state.isActiveAccordionPDP = action.payload;
    },
    setProductBiteBoard: (state, action) => {
      state.productBiteBoardClick = action.payload;
    },
    setCountProductBiteBoard: (state, action) => {
      state.countProductCategory = action.payload;
    },
  },
});

export const {
  setWidget,
  changeHealthCondition,
  setSelectedProductCategory,
  setProductCategoryList,
  resetPageInit,
  changePageInit,
  resetProductsListBiteBoard,
  updateProductsListBiteBoard,
  setIsRefetchHistory,
  setIsRefetchSearch,
  setIsActiveAccordionPDP,
  setSelectedProductSubCategory,
  setProductBiteBoard,
  setCountProductBiteBoard,
} = bottomSheetSlice.actions;
export default bottomSheetSlice.reducer;
