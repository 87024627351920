import WaterDropIcon from "@mui/icons-material/WaterDrop";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";
import DialogContent from "@mui/material/DialogContent";
import { Box, IconButton } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Divider from "@mui/material/Divider";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import {
  debugLog,
  getIngredientRatingText,
  getNutrientRatingColor,
  getRatingBackgroundColor,
  getRatingChipBorderColor,
  getRatingChipFontWeight,
  getRatingChipTextColor,
  getRatingImageForChip,
} from "utils/commonutils";
import { ReactComponent as AddtiveIcon } from "../../../assets/chemical.svg";
import { ReactComponent as IngredientIcon } from "../../../assets/ingredient-new.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorIcon from "@mui/icons-material/Error";
import isEmpty from "lodash/isEmpty";
import { Verified } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import MyStyledChip from "scenes/widgets/MyStyledChip";
import { isArray } from "lodash/isArray";

const HealthImpactsDetailsPage = (props) => {
  const { palette, typography } = useTheme();
  const textColorSecondary = palette.textColor.greySecondary;
  const fontSizeSecond = typography.h7.fontSize;
  const styleIcon = { color: textColorSecondary };
  const { t } = useTranslation();
  const styleIconInge = {
    width: "8px",
    height: "6px",
    borderLeft: "1.5px",
    borderBottom: "0",
    borderRight: "0",
    borderTop: "1.5px",
    borderStyle: "solid",
    borderColor: "#B1B8C2",
    transform: "rotate(-90deg)",
    borderRadius: "2px",
    marginLeft: "10px",
    pt: "8px",
  };

  const nutientIcon = <WaterDropIcon style={styleIcon} />;

  const additiveIcon = (
    <AddtiveIcon style={{ width: "18px", height: "18px" }} />
  );
  const ingredientIcon = (
    <IngredientIcon style={{ width: "18px", height: "18px" }} />
  );
  const borderGrey = palette.borderColor.grey;
  const { onClose, healthImpactsDetail } = props;

  debugLog("healthImpactsDetail: " + healthImpactsDetail);

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     "section_of_website" : "health_impacts_pdp"
  //   });
  // }, []);

  const handleClose = () => {
    onClose();
  };

  function RatingWidget({ type, rate_code, tag_name, palette }) {
    const { t } = useTranslation();
    if (type == null || rate_code == null || tag_name == null) return <></>;
    return (
      <FlexBetween gap="0.375rem" sx={{ justifyContent: "flex-start" }}>
        {type == "POSITIVE" || type == "GOOD" ? (
          <Verified
            fontSize="small"
            sx={{ color: getNutrientRatingColor(rate_code, palette) }}
          />
        ) : (
          <ErrorIcon
            fontSize="small"
            sx={{ color: getNutrientRatingColor(rate_code, palette) }}
          />
        )}
        <Typography
          variant="h12"
          color={getNutrientRatingColor(rate_code, palette)}
          fontWeight="600"
          sx={{ textTransform: "uppercase" }}
        >
          {getIngredientRatingText(tag_name.toUpperCase(), t)}
        </Typography>
      </FlexBetween>
    );
  }

  const renderNegative = (healthImpactsDetail) => {
    return healthImpactsDetail?.nutrients?.map((item, index) => {
      // console.log("value", item);
      return (
        <Box key={index}>
          <FlexBetween>
            <Typography alignItems={"start"} display={"flex"} fontWeight={600}>
              {nutientIcon}
              {item?.name}
            </Typography>
            <RatingWidget
              type={item.type}
              rate_code={item.rating}
              tag_name={item.tag_name}
              palette={palette}
            />
          </FlexBetween>
        </Box>
      );
    });
  };

  const renderIngredient = (listIngredient) => {
    return listIngredient?.map((detail, index) => {
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "flex-start",
            mt: "8px",
            width: "100%",
          }}
        >
          <Box sx={styleIconInge}></Box>
          <Box sx={{ width: "95%" }}>
            <FlexBetween
              gap={2}
              sx={{
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Typography width={"60%"}> {detail.name}</Typography>
              <RatingWidget
                type={detail?.rating}
                rate_code={detail?.rating}
                tag_name={detail?.rating}
                palette={palette}
              />
            </FlexBetween>
            {(!isEmpty(detail.code) || !isEmpty(detail.usage)) && (
              <Typography
                variant="h13"
                fontWeight="400"
                color={palette.textColor.greyLight}
              >
                {detail.code}&nbsp;&nbsp;
                <font color="#EBEBF0">|</font>
                &nbsp;&nbsp;{detail.usage}
              </Typography>
            )}
          </Box>
        </Box>
      );
    });
  };

  const renderNegativeAndIngredient = () => {
    if (healthImpactsDetail?.length) {
      return healthImpactsDetail?.map((itemImpact) => {
        const allergicIngredients = itemImpact?.ingredients?.filter(
          (detail) => detail.rating === "ALLERGIC"
        );
        const additiveIngredients = itemImpact?.ingredients?.filter(
          (detail) => detail.rating !== "ALLERGIC"
        );
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
            }}
          >
            <MyStyledChip
              label={itemImpact?.risk_item.name}
              backgroundColor={getRatingBackgroundColor(
                itemImpact?.risk_item.rating,
                palette
              )}
              borderColor={getRatingChipBorderColor(
                itemImpact?.risk_item.rating,
                palette,
                "health_condition"
              )}
              righticon={getRatingImageForChip(
                itemImpact?.risk_item.rating,
                "0.75rem",
                true,
                "health_condition"
              )}
              textColor={getRatingChipTextColor(
                itemImpact?.risk_item?.rating,
                palette,
                "health_condition"
              )}
              fontWeight={getRatingChipFontWeight(
                itemImpact?.risk_item?.rating,
                "health_condition"
              )}
              onClick={() => {}}
            />
            {renderNegative(itemImpact)}
            {additiveIngredients?.length > 0 && (
              <FlexBetweenVertical
                gap="0.5rem"
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <FlexBetween gap="0.5rem" sx={{ justifyContent: "flex-start" }}>
                  {additiveIcon}
                  <Typography fontWeight={600}>
                    {t("productDetail.additive")}
                  </Typography>
                </FlexBetween>
                {renderIngredient(additiveIngredients)}
              </FlexBetweenVertical>
            )}
            {allergicIngredients?.length > 0 && (
              <FlexBetweenVertical
                gap="0.5rem"
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <FlexBetween gap="0.5rem" sx={{ justifyContent: "flex-start" }}>
                  {ingredientIcon}
                  <Typography fontWeight={600}>
                    {t("productDetail.ingredient_title")}
                  </Typography>
                </FlexBetween>
                {renderIngredient(allergicIngredients)}
              </FlexBetweenVertical>
            )}
          </Box>
        );
      });
    }
  };

  return (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0rem 1.5rem 2rem",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={() => handleClose()}>
          <ChevronRightIcon
            style={{
              color: "#3D3C3D",
              width: "2rem",
              height: "2rem",
              transform: "rotate(90deg)",
            }}
          />
        </IconButton>
      </Box>
      {renderNegativeAndIngredient()}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          gap: "1rem",
        }}
      >
        <MyStyledChip
          label={healthImpactsDetail?.risk_item.name}
          backgroundColor={getRatingBackgroundColor(
            healthImpactsDetail?.risk_item.rating,
            palette
          )}
          borderColor={getRatingChipBorderColor(
            healthImpactsDetail?.risk_item.rating,
            palette,
            "health_condition"
          )}
          righticon={getRatingImageForChip(
            healthImpactsDetail?.risk_item.rating,
            "0.75rem",
            true,
            "health_condition"
          )}
          textColor={getRatingChipTextColor(
            healthImpactsDetail?.risk_item?.rating,
            palette,
            "health_condition"
          )}
          fontWeight={getRatingChipFontWeight(
            healthImpactsDetail?.risk_item?.rating,
            "health_condition"
          )}
          onClick={() => {}}
        />
        {renderNegative()}
        {additiveIngredients?.length > 0 && (
          <FlexBetweenVertical
            gap="0.5rem"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          >
            <FlexBetween gap="0.5rem" sx={{ justifyContent: "flex-start" }}>
              {additiveIcon}
              <Typography fontWeight={600}>
                {t("productDetail.additive")}
              </Typography>
            </FlexBetween>
            {renderIngredient(additiveIngredients)}
          </FlexBetweenVertical>
        )}
        {allergicIngredients?.length > 0 && (
          <FlexBetweenVertical
            gap="0.5rem"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
          >
            <FlexBetween gap="0.5rem" sx={{ justifyContent: "flex-start" }}>
              {ingredientIcon}
              <Typography fontWeight={600}>
                {t("productDetail.ingredient_title")}
              </Typography>
            </FlexBetween>
            {renderIngredient(allergicIngredients)}
          </FlexBetweenVertical>
        )}
      </Box> */}

      <Divider
        style={{
          width: "100%",
          height: "1.5px",
          color: textColorSecondary,
        }}
      />
      <Typography
        style={{
          fontSize: fontSizeSecond,
          color: textColorSecondary,
          fontWeight: 400,
          lineHeight: "150%",
          textAlign: "left",
        }}
      >
        {t("healthImpactPage.footer")}
      </Typography>
    </DialogContent>
  );
};

export default HealthImpactsDetailsPage;
