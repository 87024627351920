import { Box } from "@mui/material";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import LoadingGPTIconSmall from "components/CircularCustomSmall";

const LoadingGPT = () => {
  return (
    <Box
      sx={{
        width: 300,
        backgroundColor: "#FFF1E8",
        padding: "1rem",
        borderRadius: " 12px",
      }}
    >
      <LoadingGPTIconSmall/>
      <Skeleton
        sx={{ background: "linear-gradient(270deg, #FFE3D1 0%, #FFDEC9 100%)" }}
      />
      <Skeleton
        sx={{ background: "linear-gradient(270deg, #FFE3D1 0%, #FFDEC9 100%)" }}
      />
      <Skeleton
        sx={{ background: "linear-gradient(270deg, #FFE3D1 0%, #FFDEC9 100%)" }}
        width={"60%"}
      />
    </Box>
  );
};

export default LoadingGPT;
