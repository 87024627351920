import { Verified } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useNavigate } from "react-router-dom";
import ProductChips from "components/ProductChips";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { ReactComponent as IngredientIcon } from "../../../assets/chemical.svg";
import {
  getIngredientRatingText,
  getNutrientRatingColor,
  getRatingBackgroundColor,
} from "utils/commonutils";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";

const IngredientListWidget = ({
  ingredientItem = {},
  showBoxShadow = false,
  handlecClick = () => {},
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dark = palette.textColor.mainHeading;
  const greyLight = palette.textColor.greyLight;
  const shapeStyles = { bgcolor: palette.neutral.main, width: 40, height: 40 };
  return (
    <Box padding="0.5rem 0.75rem 0.5rem 0.75rem" >
      <WidgetWrapper
        width="100%"
        onClick={() => {
          handlecClick(ingredientItem);
          navigate(
            ingredientItem.id
              ? `/ingredient/${ingredientItem.id}`
              : `/ingredient/5ed7f47d-3309-4414-b12f-5e9c205bcf8a`
          );
        }}
        sx={{
          boxShadow: "2px 4px 10px 0px rgb(0 0 0 / 4%)",
          padding: "1rem 0.75rem 0.75rem 0.75rem",
        }}
      >
        {/* FIRST ROW */}

        <FlexBetween
          gap="1rem"
          pb="0.5rem"
          sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          <IngredientIcon
            style={{
              width: "15px",
              height: "16.25px",
              minWidth: "15px",
              minHeight: "16.25px",
            }}
          />
          <FlexBetweenVertical sx={{ alignItems: "flex-start" }} gap="0.5rem">
            <Typography variant="h15" color={dark} fontWeight="500">
              {ingredientItem.name ? ingredientItem.name : "Ingredient Name"}
            </Typography>
            <Typography variant="h14" fontWeight="400" color={greyLight}>
              {ingredientItem.code ? ingredientItem.code : "Ingredient Code"}
            </Typography>
            <Typography variant="h14" fontWeight="400" color={greyLight}>
              {ingredientItem.usage ? ingredientItem.usage : "Usage"}
            </Typography>
            <RatingWidget
              type={ingredientItem.rate_code}
              rate_code={ingredientItem.rate_code}
              tag_name={ingredientItem.rate_code}
              palette={palette}
              t={t}
            />

            {/* Only show top 5 health risks inside */}

            {ingredientItem.health_risks &&
              ingredientItem.health_risks.length > 0 && (
                <Box mt="1rem" ml="-1rem">
                  <ProductChips
                    isClickableChip={false}
                    chipsList={ingredientItem.health_risks.slice(
                      0,
                      Math.min(5, ingredientItem.health_risks.length)
                    )}
                  />
                </Box>
              )}
          </FlexBetweenVertical>
        </FlexBetween>
      </WidgetWrapper>
    </Box>
  );
};

function RatingWidget({ type, rate_code, tag_name, palette, t }) {
  if (type == null || rate_code == null || tag_name == null) return <></>;
  return (
    <FlexBetween
      gap="0.375rem"
      sx={{ justifyContent: "flex-start", borderRadius: "0.5rem" }}
      p="0.25rem 0.5rem 0.25rem 0.5rem"
      backgroundColor={getRatingBackgroundColor(rate_code, palette)}
    >
      {type == "POSITIVE" || type == "GOOD" || type == "NEUTRAL" ? (  
        <Verified
          fontSize="small"
          sx={{ color: getNutrientRatingColor(rate_code, palette) }}
        />
      ) : (
        <ErrorIcon
          fontSize="small"
          sx={{ color: getNutrientRatingColor(rate_code, palette) }}
        />
      )}
      <Typography
        variant="h12"
        color={getNutrientRatingColor(rate_code, palette)}
        fontWeight="600"
        sx={{textTransform: "uppercase"}}
      >
        {getIngredientRatingText(tag_name.toUpperCase(), t)}
      </Typography>
    </FlexBetween>
  );
}

export default IngredientListWidget;
