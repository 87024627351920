import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  Button,
  ButtonBase,
} from "@mui/material";
import { RadioGroup, Radio, Sheet } from "@mui/joy";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
  HelpOutline,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { styled } from "@mui/material/styles";
import DehazeIcon from "@mui/icons-material/Dehaze";
// import Logo from "components/Logo";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";
import { ReactComponent as BookOpen } from "../../../assets/book_open.svg";
import { ReactComponent as PersonCircle } from "../../../assets/person_circle.svg";
import { useTranslation } from "react-i18next";
import MenuWidget from "scenes/widgets/MenuWidget";
import FaqMenuGroupButton from "./FaqMenuGroupButton";
import DialogWidget from "scenes/widgets/DialogWidget";
import DisclaimerWidget from "scenes/widgets/DisclaimerWidget";
import { setWidget } from "features/bottomSheet/bottomSheetSlice";

const Navbar = (props) => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const { palette } = useTheme();

  const alt = palette.background.alt;
  const textMain = palette.textColor.main;
  const textMainHeading = palette.textColor.mainHeading;
  const textGreyLight = palette.textColor.greyLight;
  const textOrangeLight = palette.textColor.orangeLight;
  const textOrangeDark = palette.textColor.orangeDark;
  const borderGrey = palette.borderColor.grey;
  const yellowDark = palette.background.yellowDark;

  const LanguageButton = styled(Button)({
    boxShadow: "none",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "transparent",
    },
  });

  const nameWidget = "openDisclaimer";

  const { openDisclaimer } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );

  const [payload, setPayload] = useState({
    name: "openDisclaimer",
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };
  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  return (
    <>
      <FlexBetween padding="1rem 6%" backgroundColor={alt} >
        <ButtonBase onClick={() => navigate(`/`)}>
          <LogoIcon />
        </ButtonBase>

        <FlexBetween gap="1.5rem">
          <FlexBetween gap="0.625rem">
            {/* <RadioGroup
          aria-labelledby="storage-label"
          defaultValue="EN"
          size="lg"
          sx={{ gap: 1.5 }}
          >
            {['VIW', 'EN'].map((value) => (
              <Sheet
                key={value}
                sx={{
                  p: 2,
                  borderRadius: 'md',
                  boxShadow: 'sm',
                  bgcolor: 'background.body',
                }}
              >
                <Radio
                  label={`${value}`}
                  overlay
                  disableIcon
                  value={value}
                  slotProps={{
                    label: ({ checked }) => ({
                      sx: {
                        fontWeight: '700',
                        fontSize: '14px',
                        backgroundColor: checked ? yellowDark : '#FFFFFF',
                      },
                    }),
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          '--variant-borderWidth': '2px',
                          '&&': {
                            // && to increase the specificity to win the base :hover styles
                            backgroundColor: yellowDark,
                          },
                        }),
                      }),
                    }),
                  }}
                />
              </Sheet>
            ))}
          </RadioGroup> */}
            {/* <Button
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => {
                i18n.changeLanguage("vi");
              }}
              sx={{
                maxWidth: "41px",
                maxHeight: "26px",
                minWidth: "41px",
                minHeight: "26px",
                fontSize: "14px",
                fontWeight: "bold",
                justifyContent: "center",
                borderRadius: "0.5rem",
                color: textMainHeading,
                backgroundColor:
                  i18n.language === "vi" ? yellowDark : "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor:
                    i18n.language === "vi" ? yellowDark : "transparent",
                },
              }}
            >
              VIE
            </Button> */}
            {/* <Button
              variant="contained"
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={() => {
                i18n.changeLanguage("en");
              }}
              sx={{
                maxWidth: "34px",
                maxHeight: "26px",
                minWidth: "34px",
                minHeight: "26px",
                fontSize: "14px",
                fontWeight: "bold",
                justifyContent: "center",
                borderRadius: "0.5rem",
                color: textMainHeading,
                backgroundColor:
                  i18n.language != null && i18n.language.startsWith("en")
                    ? yellowDark
                    : "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor:
                    i18n.language != null && i18n.language.startsWith("en")
                      ? yellowDark
                      : "transparent",
                },
              }}
            >
              EN
            </Button> */}
          </FlexBetween>
          <FlexBetween gap="1rem">
            {/* <IconButton
              onClick={() => {
                handleClickOpen(nameWidget);
              }}
            >
              <BookOpen style={{ color: textMainHeading }} />
            </IconButton> */}
            <FaqMenuGroupButton
              showShareIcon={false}
              changeBottom={props.changeBottom}
            />
          </FlexBetween>
        </FlexBetween>
      </FlexBetween>
      <DialogWidget
        open={openDisclaimer}
        onClose={() => handleClose(nameWidget)}
        dialogContent={
          <DisclaimerWidget onClose={() => handleClose(nameWidget)} />
        }
      />
    </>
  );
};

export default Navbar;
