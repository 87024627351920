import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageGroupButton = () => {
  const { palette } = useTheme();
  const textMainHeading = palette.textColor.mainHeading;
  const { t, i18n } = useTranslation();
  const yellowDark = palette.background.yellowDark;

  return (
    <FlexBetween gap="0.625rem">
      <Button
        id="language_btn"
        variant="contained"
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => {
          const selectedLanguage = i18n.language;
          i18n.changeLanguage("vi");
          if (selectedLanguage === "en")
            window.location.reload();
        }}
        sx={{
          maxWidth: "41px",
          maxHeight: "26px",
          minWidth: "41px",
          minHeight: "26px",
          fontSize: "14px",
          fontWeight: "bold",
          justifyContent: "center",
          borderRadius: "0.5rem",
          color: textMainHeading,
          backgroundColor: i18n.language === "vi" ? yellowDark : "#FFFFFF",
          boxShadow: "none",
          "&:hover": {
            backgroundColor:
              i18n.language === "vi" ? yellowDark : "transparent",
          },
        }}
      >
        VIE
      </Button>
      <Button
        id="language_btn"
        variant="contained"
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => {
          const selectedLanguage = i18n.language;
          i18n.changeLanguage("en");
          if (selectedLanguage === "vi")
            window.location.reload();
        }}
        sx={{
          maxWidth: "34px",
          maxHeight: "26px",
          minWidth: "34px",
          minHeight: "26px",
          fontSize: "14px",
          fontWeight: "bold",
          justifyContent: "center",
          borderRadius: "0.5rem",
          color: textMainHeading,
          backgroundColor:
            i18n.language != null && i18n.language.startsWith("en")
              ? yellowDark
              : "#FFFFFF",
          boxShadow: "none",
          "&:hover": {
            backgroundColor:
              i18n.language != null && i18n.language.startsWith("en")
                ? yellowDark
                : "transparent",
          },
        }}
      >
        EN
      </Button>
    </FlexBetween>
  );
};

export default LanguageGroupButton;
