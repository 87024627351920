import React, { useEffect, useState } from "react";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Divider, MenuItem, MenuList, useMediaQuery } from "@mui/material";
import SearchScanWidget from "./SearchScanWidget";
import { ListItemIcon } from "@mui/material";
import {
  setIsRefetchHistory,
  setWidget,
} from "features/bottomSheet/bottomSheetSlice";

import { useDispatch, useSelector } from "react-redux";
import LanguageGroupButton from "./LanguageGroupButton";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { useTranslation } from "react-i18next";
import HeaderBottomSheet from "./HeaderBottomSheet";
import BiteboardIconImage from "../../assets/Star.svg";
import HomeIconImage from "../../assets/navbar_home.svg";
import FilterIconImage from "../../assets/slider_icon.svg";
import WisegptIconImage from "../../assets/wisegpticon.svg";
import HistoryIconImage from "../../assets/history_icon.svg";
import FAQIconImage from "../../assets/faq_icon.svg";
import LanguageIconImage from "./../../assets/language_icon.svg";
import LogoIconImage from "../../assets/bitewise_logo_navbar.svg";
import { GTMAnalytics } from "utils/analytics";

const MenuWidget = (props) => {
  const { t, i18n } = useTranslation();
  const { userIPLocation } = useSelector((state) => state.auth);
  // const [deferredPrompt, setDeferredPrompt] = useState(null);

  // useEffect(() => {
  //   const handleBeforeInstallPrompt = (e) => {
  //     e.preventDefault();
  //     setDeferredPrompt(e);
  //   };

  //   window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

  //   return () => {
  //     window.removeEventListener(
  //       "beforeinstallprompt",
  //       handleBeforeInstallPrompt
  //     );
  //   };
  // }, []);

  const handleAddToHomeScreen = () => {
    // if (deferredPrompt) {
    //   deferredPrompt.prompt();
    //   deferredPrompt.userChoice.then((choiceResult) => {
    //     if (choiceResult.outcome === "accepted") {
    GTMAnalytics.sendEvent(
      GTMAnalytics.EventName.AddToHomeScreen,
      GTMAnalytics.EventCategory.Engagement,
      GTMAnalytics.EventAction.Add,
      "Add to Home Screen",
      null,
      null
    );
  };
  //       setDeferredPrompt(null);
  //     });
  //   }
  // };

  const menuItems = [
    {
      label: t("menu.home"),
      id: "home_btn_menu",
      icon: <img src={HomeIconImage} alt="home-icon" />,
      path: "/",
      nameWidget: "",
      detail: "",
      show: true,
    },
    {
      label: "Browsing",
      id: "browsing_btn_menu",
      icon: <img src={BiteboardIconImage} alt="biteboard-icon" />,
      path: "/biteboard",
      nameWidget: "",
      detail: "",
      show:
        userIPLocation &&
        userIPLocation.ipapi &&
        userIPLocation.ipapi.countryName.toUpperCase() !== "VIETNAM"
          ? true
          : false,
    },
    {
      label: t("menu.health_codition"),
      id: "health_condition_btn_menu",
      icon: <img src={FilterIconImage} alt="filter-icon" />,
      path: "",
      nameWidget: "openHealth",
      detail: "",
      show: true,
    },
    {
      label: "Wisegpt",
      id: "wisegpt_btn_menu",
      icon: <img src={WisegptIconImage} alt="wisegpt-icon" />,
      path: "/wisegpt",
      nameWidget: "",
      detail: "",
      show: true,
    },
    {
      label: t("menu.history"),
      id: "history_btn_menu",
      icon: <img src={HistoryIconImage} alt="history-icon" />,
      path: "/history",
      nameWidget: "",
      detail: "",
      show: true,
    },
    {
      label: t("menu.faq"),
      id: "faq_btn_menu",
      icon: <img src={FAQIconImage} alt="faq-icon" />,
      path: "",
      nameWidget: "openFaq",
      detail: "",
      show: true,
    },
    //   // {
    //   //   label: t("menu.blog"),
    //   //   icon: <BlogIcon style={{ color: "#191B1F" }} />,
    //   //   path: "/blog",
    //   //   nameWidget: "",
    //   //   detail: "",
    //   //   show: true,
    //   // },
    {
      label: t("menu.add_bitewise_to_homescreen"),
      id: "add_to_home_btn_menu",
      icon: <img src={LogoIconImage} alt="bitewise-icon" />,
      path: "",
      nameWidget: "openAddToHome",
      detail: "",
      show: true,
    },
    {
      label: t("menu.language"),
      id: "language_btn_menu",
      icon: <img src={LanguageIconImage} alt="language-icon" />,
      nameWidget: "",
      detail: <LanguageGroupButton />,
      show: false,
      // i18n.language === "vi" ||
      // (userIPLocation &&
      //   userIPLocation.ipapi &&
      //   userIPLocation.ipapi.countryName.toUpperCase() === "VIETNAM"),
    },
  ];
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const { palette, typography } = useTheme();
  const fontSize16 = typography.h5.fontSize;
  const textColor = palette.textColor.mainHeading;

  const styleText = {
    fontSize: fontSize16,
    fontWeight: "600",
    color: textColor,
    lineHeight: "1.5rem",
    overflowWrap: "break-word",
  };

  const { onClose, setMenuAction, menuAction } = props;

  const handleCloseMenu = () => {
    onClose();
  };
  const dispatch = useDispatch();

  const handleMenuItemClick = (path, nameWidget) => {
    if (path) {
      if (path.includes("blog")) {
        var blog_url =
          window.location.protocol + "//" + window.location.host + "/blog";
        window.open(blog_url, "_blank", "noreferrer");
        // <Link to={path} target="_blank" rel="noopener noreferrer" />
      } else {
        navigate(path);
      }
      dispatch(setWidget({ name: "openMenu", isOpen: false }));
    } else if (nameWidget) {
      dispatch(setWidget({ name: nameWidget, isOpen: true }));
      setMenuAction(!menuAction);
    }
    handleCloseMenu();
  };

  const styleMenu = isNonMobileScreens
    ? {
        width: "100%",
        height: "85vh",
        justifyContent: "space-between",
      }
    : {
        display: "block",
      };
  const styleSearchScanBtn = isNonMobileScreens ? "9.5rem" : "0.5rem";

  return (
    <FlexBetweenVertical sx={styleMenu}>
      {!isNonMobileScreens && (
        <HeaderBottomSheet
          name={`${t("menu.title")}`}
          icon={""}
          close={onClose}
        />
      )}
      <Box>
        <MenuList sx={{ width: "100%" }} id="navmenu_btn">
          <Box gap="8px" flexDirection="column" alignItems="flex-start">
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && item.show && (
                  <Divider style={{ background: "#EBEBF0" }} />
                )}
                {item.show && (
                  <MenuItem
                    id={item.id}
                    onClick={() => {
                      if (item.path === "/history") {
                        dispatch(setIsRefetchHistory(true));
                      }
                      if (item.id === "add_to_home_btn_menu") {
                        handleAddToHomeScreen();
                      }
                      handleMenuItemClick(item.path, item.nameWidget);
                    }}
                    width="275px"
                    height="23px"
                    style={{
                      minHeight: "23px",
                      gap: "8px",
                      whiteSpace: "pre-wrap",
                      backgroundColor: "transparent",
                    }}
                    disableRipple
                  >
                    <ListItemIcon
                      style={{
                        color: textColor,
                        width: "24px",
                        height: "24px",
                        display: "block",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body1" sx={styleText}>
                        {item.label}
                      </Typography>
                      <Box>{item.detail}</Box>
                    </ListItemText>
                  </MenuItem>
                )}
              </React.Fragment>
            ))}
          </Box>
        </MenuList>
      </Box>
      <FlexBetweenVertical
        sx={{
          justifyContent: "flex-start",
          width: "100%",
          // marginTop: styleSearchScanBtn,
        }}
      >
        <Divider
          variant="middle"
          style={{
            width: "calc(100% + 2rem)",
            marginBottom: "0.3rem",
            background: "#EBEBF0",
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            dispatch(setWidget({ name: "openMenu", isOpen: false }));
          }}
        >
          <SearchScanWidget
            showPlaceHolder={false}
            showNote={false}
            isDesktop={isNonMobileScreens}
            showShadow={false}
            isNavbar={true}
          />
        </Box>
      </FlexBetweenVertical>
    </FlexBetweenVertical>
  );
};

export default MenuWidget;
